import { Component } from '@angular/core';
import { SupportEmail } from '../../models/support-email';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  public scheduleSupport = new SupportEmail(
    'FocusWorkplacePlanning@RocketCompanies.com',
    'Onsite Scheduler Inquiry'
  );

  public workspaceSupport = new SupportEmail(
    'FocusWorkplacePlanning@RocketCompanies.com',
    'Onsite Scheduler Inquiry'
  );

  public parkingSupport = new SupportEmail(
    'CommuteSolutions@RocketCompanies.com',
    'Onsite Scheduler Inquiry'
  );

  public techSupport = new SupportEmail(
    'RCDSoftwareEngineeringSupport@RocketCompanies.com',
    'Onsite Scheduler Inquiry'
  );
}
