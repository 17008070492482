import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss']
})
export class CalloutComponent implements OnInit {


  @Input() public type: undefined | string | 'default' | 'primary' | 'secondary' | 'tertiary' | 'alert' | 'error' = 'default';
  @Input() public icon: undefined | string = undefined;
  @Input() public fontStyle: undefined | string = undefined;
  @Input() public title = '';
  
  bodyTextClass = 'callout-body-italic';
  customIcon = false;

  constructor() {}

  ngOnInit(): void {

    if ( this.icon !== undefined ) {
      this.customIcon = true;
    }

    this.setFontStyle();
    this.updateIcon();
  }

  setFontStyle(): void {
    if (this.fontStyle === 'normal')
    {
      this.bodyTextClass = '';
    }
  }

  public updateIcon(): void {

    if ( !this.customIcon ) {

      switch (this.type) {

        case 'primary':
          this.icon = 'fa fa-retweet';
          break;

        case 'secondary':
          this.icon = 'fa fa-asterisk';
          break;

        case 'tertiary':
          this.icon = 'fa fa-info-circle';
          break;

        case 'alert':
          this.icon = 'fas fa-exclamation-circle';
          break;

        case 'error':
          this.icon = 'fas fa-exclamation-triangle';
          break;

        case 'default':
        default:
          this.icon = 'fas fa-bell';
          break;

      }

    }

  }

}
