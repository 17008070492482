<div class="scheduler-modal">
  <mat-dialog-content>
    <mat-toolbar fxLayoutAlign="left center"><span class="header-modal">End Schedule for {{data.name}}</span></mat-toolbar>
    <div class="dialog-close modal" [mat-dialog-close]="false"><i class="fa-regular fa-xmark white"></i></div>
    <div class="create-schedule-form">
      <div class="endDate">
        <mat-form-field class="date-picker">
          <label>On</label>
          <input matInput [(ngModel)]="formData.endDate" [matDatepicker]="picker" [min]="data.minDate" [max]="data.maxDate" (click)="picker.open()" autocomplete="off" readonly>
          <div class="field-icon" (click)="picker.open()"><i class="fas fa-calendar-alt"></i></div>
          <mat-datepicker [touchUi]="isMobile" #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="centered">
      <button mat-button class="tertiary" (click)="dialogRef.close()">Back</button>
      <button mat-button (click)="saveModal()" [disabled]="!saveEnabled" class="primary">Continue</button>
    </div>
  </mat-dialog-content>
</div>
