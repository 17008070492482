<div>
  <div class="section">
    <div class=style-guide-title>Style Guide - Onsite Scheduler</div>
    <mat-tab-group class="style-guide-tabs">
      <mat-tab label="Colors">
        <div class="section-content colors">
          <div class="cols">
            <div class="col">

              <div class="color-group">
                <div class="color-name">Rocket Red</div>
                <div class="rocket-red color-value primary">#C8102E</div>
                <div class="subcolor">
                  <span class="rocket-red mid color-value"><span>Mid</span>#9D2235</span>
                  <span class="rocket-red deep color-value"><span>Deep</span>#76232F</span>
                </div>
              </div>

              <div class="color-group">
                <div class="color-name">Orbit Purple</div>
                <div class="orbit-purple color-value primary">#603AA1</div>
                <div class="subcolor">
                  <span class="orbit-purple light color-value"><span>Light</span>#9265D3</span>
                  <span class="orbit-purple deep color-value"><span>Deep</span>#2E1166</span>
                </div>
                <div class="subcolor">
                  <span class="orbit-purple lightest color-value"><span>Lightest</span>#E7E2F2</span>
                  <span class="orbit-purple dark color-value"><span>Dark</span>#3C3174</span>
                </div>
              </div>

            </div>

            <div class="col">

              <div class="color-group">
                <div class="color-name">Black</div>
                <div class="black color-value primary">#1C1B1A</div>
                <div class="black tint-80 color-value"><span>Tint 80</span>#494948</div>
                <div class="subcolor">
                  <span class="black tint-20 color-value"><span>Tint 20</span>#D2D1D1</span>
                  <span class="black tint-10 color-value"><span>Tint 10</span>#E8E8E8</span>
                </div>
              </div>

              <div class="color-group">
                <div class="color-name">Gray</div>
                <div class="gray color-value primary">#F7F7F7</div>
              </div>

              <div class="color-group">
                <div class="color-name">White</div>
                <div class="white color-value primary">#FFFFFF</div>
              </div>

              <div class="color-group">
                <div class="color-name">Success Green</div>
                <div class="success-green color-value primary">#298540</div>
              </div>

              <div class="color-group">
                <div class="color-name">Info Blue</div>
                <div class="info-blue color-value primary">#1C6EF2</div>
              </div>

              <div class="color-group">
                <div class="color-name">Error Orange</div>
                <div class="error-orange color-value primary">#D47500</div>
              </div>
            </div>

          </div>
        </div>
      </mat-tab>
      <mat-tab label="Typography">

        <div class="section">
          <div class="section-content typography">

            <div class="typography-label">
              <p>H1</p>
              <h1>Rocket Sans/500/24px</h1>
            </div>

            <div class="typography-label">
              <p>H2</p>
              <h2>Rocket Sans/500/22px</h2>
            </div>

            <div class="typography-label">
              <p>H3</p>
              <h3>Rocket Sans/500/20px</h3>
            </div>

            <div class="typography-label">
              <p>H4</p>
              <h4>Rocket Sans/500/14px</h4>
            </div>

            <div class="typography-label">
              <p>Body</p>
              <span class="body">Rocket Sans/300/16px</span>
            </div>

            <div class="typography-label">
              <p>Link</p>
              <span class="link">Rocket Sans/500/16px</span>
            </div>

            <div class="typography-label">
              <p>Button</p>
              <span class="font-size-button">Rocket Sans/500/14px</span>
            </div>

            <div class="typography-label">
              <p>Copyright</p>
              <span class="copyright">Rocket Sans/300/14px</span>
            </div>

          </div>
        </div>

      </mat-tab>
      <mat-tab label="Buttons">

        <div class="section">
          <div class="section-content buttons">

            <div class="cols clearfix">

              <div class="button-group">
                <h3>Primary</h3>
                <div class="col">
                  <button mat-button class="primary">Button</button>
                  <button mat-button class="primary has-icon"><i class="fa-regular fa-copy"></i> Button</button>
                  <div class="style-info">
                    <p>Color: White</p>
                    <p>Background: Rocket Red</p>
                    <p>Border: 2px Rocket Red</p>
                    <p>Radius: 3px</p>
                    <p>Icon Size: 20px</p>
                  </div>
                </div>

                <div class="col">
                  <button mat-button class="primary hover">Button</button>
                  <button mat-button class="primary has-icon hover"><i class="fa-regular fa-copy"></i> Button</button>
                  <div class="style-info">
                    <p>Color: White</p>
                    <p>Background: Rocket Red (Mid)</p>
                    <p>Border: 2px Rocket Red (Mid)</p>
                  </div>
                </div>

                <div class="col">
                  <button mat-button class="primary disabled">Disabled</button>
                  <div class="style-info">
                    <p>Color: White</p>
                    <p>Background: Black(20)</p>
                    <p>Border: 2px Black(20)</p>
                  </div>
                </div>
              </div>

              <div class="button-group">
                <h3>Secondary</h3>
                <div class="col">
                  <button mat-button class="secondary">Button</button>
                  <button mat-button class="secondary has-icon"><i class="fa-regular fa-copy"></i> Button</button>
                  <button mat-button class="secondary has-icon"><i class="fas fa-chevron-right"></i></button>
                  <div class="style-info">
                    <p>Color: Orbit Purple</p>
                    <p>Background: White</p>
                    <p>Border: 2px Orbit Purple</p>
                    <p>Radius: 3px</p>
                    <p>Icon Size: 20px</p>
                  </div>
                </div>

                <div class="col">
                  <button mat-button class="secondary hover">Button</button>
                  <button mat-button class="secondary has-icon hover"><i class="fa-regular fa-copy"></i> Button</button>
                  <button mat-button class="secondary has-icon hover"><i class="fas fa-chevron-right"></i></button>
                  <div class="style-info">
                    <p>Color: Orbit Purple</p>
                    <p>Background: Orbit Purple (Lightest)</p>
                    <p>Border: 2px Orbit Purple</p>
                  </div>
                </div>

                <div class="col">
                  <button mat-button class="secondary disabled">Disabled</button>
                  <div class="style-info">
                    <p>Color: Black(20)</p>
                    <p>Background: None</p>
                    <p>Border: 2px Black(20)</p>
                  </div>
                </div>
              </div>

              <div class="button-group">
                <h3>Tertiary</h3>
                <div class="col">
                  <button mat-button class="tertiary">Button</button>
                  <button mat-button class="tertiary has-icon"><i class="fa-regular fa-copy"></i> Button</button>
                  <div class="style-info">
                    <p>Color: Orbit Purple</p>
                    <p>Background: None</p>
                    <p>Border: 2px None</p>
                    <p>Radius: 3px</p>
                    <p>Icon Size: 20px</p>
                  </div>
                </div>

                <div class="col">
                  <button mat-button class="tertiary hover">Button</button>
                  <button mat-button class="tertiary has-icon hover"><i class="fa-regular fa-copy"></i> Button</button>
                  <div class="style-info">
                    <p>Color: Orbit Purple</p>
                    <p>Background: Orbit Purple (Lightest)</p>
                    <p>Border: 2px Orbit Purple</p>
                  </div>
                </div>

                <div class="col">
                  <button mat-button class="tertiary disabled">Disabled</button>
                  <div class="style-info">
                    <p>Color: Black(20)</p>
                    <p>Background: None</p>
                    <p>Border: 2px None</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </mat-tab>

      <mat-tab label="Forms">

        <div class="section">
          <div class="section-content forms">
            <div class="cols clearfix">

              <div class="row">
                <div class="col">

                  <div><label>Radio</label></div>
                  <mat-radio-group aria-label="Select an option">
                    <div class="cols nested clearfix">
  
                      <div class="col">
                        <mat-radio-button value="1">Default</mat-radio-button>
                        <div class="style-info inline">
                          <p>Border: 2px Black(20)</p>
                          <p>Background: White</p>
                        </div>
                      </div>
  
                      <div class="col">
                        <mat-radio-button value="2" class="hover">Hover</mat-radio-button>
                        <div class="style-info inline">
                          <p>Border: 2px Orbit Purple</p>
                          <p>Background: White</p>
                        </div>
                      </div>
  
                      <div class="col">
                        <mat-radio-button [checked]="true" value="3">Selected</mat-radio-button>
                        <div class="style-info inline">
                          <p>Border: 2px Orbit Purple</p>
                          <p>Background: Orbit Purple</p>
                        </div>
                      </div>
  
                    </div>
                  </mat-radio-group>
                </div>
  
                <div class="col">
                  <div><label>Checkbox</label></div>
                  <div class="cols nested clearfix">
  
                    <div class="col">
                      <mat-checkbox class="default">Default</mat-checkbox>
                      <div class="style-info">
                        <p>Border: 2px Black(20)</p>
                        <p>Background: White</p>
                      </div>
                    </div>
  
                    <div class="col">
                      <mat-checkbox class="hover">Hover</mat-checkbox>
                      <div class="style-info">
                        <p>Border: 2px Orbit Purple</p>
                        <p>Background: White</p>
                      </div>
                    </div>
  
                    <div class="col">
                      <mat-checkbox [checked]="true">Selected</mat-checkbox>
                      <div class="style-info">
                        <p>Border: 2px Orbit Purple</p>
                        <p>Background: Orbit Purple</p>
                      </div>
                    </div>
  
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div><label>Text Area - Default</label></div>
                  <mat-form-field>
                    <input matInput #input1 maxlength="250">
                    <mat-hint align="end">Character Count: {{input1.value?.length || 0}}/250</mat-hint>
                  </mat-form-field>
                  <div class="style-info">
                    <p>Background: White</p>
                    <p>Border: 2px Black(20)</p>
                    <p>Radius: 10px</p>
                    <p>Character Count Text Size: 12px</p>
                  </div>
                </div>
  
                <div class="col">
                  <div><label>Text Area - Focused</label></div>
                  <mat-form-field>
                    <input matInput #input2 maxlength="250" class="focus">
                    <mat-hint align="end">Character Count: {{input2.value?.length || 0}}/250</mat-hint>
                  </mat-form-field>
                  <div class="style-info">
                    <p>Border: 2px Orbit Purple</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </mat-tab>

      <mat-tab label="Icons">

        <div class="section">

          <div class="section-content icons">

            <div class="cols clearfix">

              <div class="col">
                <div><i class="fa-solid fa-id-badge"></i></div>
                <span class="icon-label">id-badge</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-retweet"></i></div>
                <span class="icon-label">retweet</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-asterisk"></i></div>
                <span class="icon-label">asterisk</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-chair-office"></i></div>
                <span class="icon-label">chair-office</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-square-parking"></i></div>
                <span class="icon-label">parking</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-magnifying-glass"></i></div>
                <span class="icon-label">search</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-user"></i></div>
                <span class="icon-label">user</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-users"></i></div>
                <span class="icon-label">users</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-regular fa-xmark"></i></div>
                <span class="icon-label">times</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-chevron-left"></i></div>
                <span class="icon-label">chevron-left</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-chevron-right"></i></div>
                <span class="icon-label">chevron-right</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-circle-notch"></i></div>
                <span class="icon-label">circle-notch</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-check"></i></div>
                <span class="icon-label">check</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-plus"></i></div>
                <span class="icon-label">plus</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-minus"></i></div>
                <span class="icon-label">minus</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-toggle-on"></i></div>
                <span class="icon-label">toggle-on</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-folder-open"></i></div>
                <span class="icon-label">folder-open</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-ban"></i></div>
                <span class="icon-label">ban</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-pen-to-square"></i></div>
                <span class="icon-label">edit</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-triangle-exclamation"></i></div>
                <span class="icon-label">exclamation-triangle</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-chevron-down"></i></div>
                <span class="icon-label">chevron-down</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-caret-up"></i></div>
                <span class="icon-label">caret-up</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-caret-down"></i></div>
                <span class="icon-label">caret-down</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-hand"></i></div>
                <span class="icon-label">hand-paper</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-up-long"></i></div>
                <span class="icon-label">long-arrow-up</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-down-long"></i></div>
                <span class="icon-label">long-arrow-down</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-right-long"></i></div>
                <span class="icon-label">long-arrow-right</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-book-open"></i></div>
                <span class="icon-label">book-open</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-clipboard-check"></i></div>
                <span class="icon-label">clipboard-check</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-clipboard-list"></i></div>
                <span class="icon-label">clipboard-list</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-lock"></i></div>
                <span class="icon-label">lock</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-unlock"></i></div>
                <span class="icon-label">unlock</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-copy"></i></div>
                <span class="icon-label">copy</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-trash-can"></i></div>
                <span class="icon-label">trash-alt</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-circle-info"></i></div>
                <span class="icon-label">info-circle</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-circle-exclamation"></i></div>
                <span class="icon-label">exclamation-circle</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-user-clock"></i></div>
                <span class="icon-label">user-clock</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-calendar"></i></div>
                <span class="icon-label">calendar</span>
                <span class="icon-weight">(Solid)</span>
              </div>

              <div class="col">
                <div><i class="fa-solid fa-calendar-xmark"></i></div>
                <span class="icon-label">calendar-times</span>
                <span class="icon-weight">(Solid)</span>
              </div>

            </div>

          </div>

        </div>

      </mat-tab>

      <mat-tab label="Components">

        <div class="section">
          <div class="section-content components">
            <div id="site-container" class="site-container">
              <app-header></app-header>
            </div>
            <div class="header-components">
              <div class="header-styling-options">
                <div class="header-menu">Header Menu<div class="border-bottom-large"></div>
                </div>
                <div class="options">
                  <div>
                    <div class="header-menu">Desktop</div>
                    <div class="styles">
                      <div>Pattern Height: 80px</div>
                      <div>Content Area: 1200px</div>
                      <div>Menu Size: 16px</div>
                      <div>Menu Color: Black</div>
                      <div>Menu Weight: Bold</div>
                      <div>Menu Underline: 3px Rocket Red</div>
                    </div>
                  </div>
                  <div>
                    <div class="header-menu">Mobile</div>
                    <div class="styles">
                      <div>Pattern Height: 40px</div>
                      <div>Icon Size: 26px</div>
                      <div>Icon Weight: Regular</div>
                      <div>Icon Color: Black</div>
                      <div>Icon Color (Hover): Black</div>
                      <div>Menu Size: 16px</div>
                      <div>Menu Color: White</div>
                      <div>Menu Weight: Bold</div>
                      <div>Menu Underline: 3px Rocket Red</div>
                      <div>Menu Background Gray</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="header">
                  <mat-toolbar>
                    <div class="container">
                      <app-logo [logoType]="'header'"></app-logo>

                      <div fxFlex fxLayout="row" fxLayoutAlign="end">
                        <button (click)="toggleMenu()" class="mobile-menu-button" mat-icon-button fxFlexAlign="center">
                          <mat-icon class="mobile-menu-icon">
                            <div [innerHTML]="mobileMenuIcon"></div>
                          </mat-icon>
                        </button>
                      </div>
                      <div *ngIf="mobileMenuOpened" class="style-guide-mobile-menu">
                        <div fxLayout="row">
                          <div fxLayout="column" fxLayoutGap="2px" class="links-column navigation-items">
                            <div class="mobile-menu-link">
                              <a class="link-text" [routerLinkActive]="'active'"
                                [routerLinkActiveOptions]="{exact: true}" routerLink="/schedule-request/dashboard">My
                                Dashboard
                              </a>
                            </div>
                            <div class="mobile-menu-link">
                              <a class="link-text" [routerLinkActive]="'active'"
                                [routerLinkActiveOptions]="{exact: true}" routerLink="/">My Schedule
                              </a>
                            </div>
                            <div class="mobile-menu-link">
                              <a class="link-text" [routerLinkActive]="'active'"
                                [routerLinkActiveOptions]="{exact: true}"
                                routerLink="/request-schedule-change-complex">Request Schedule Change
                              </a>
                            </div>
                          </div>
                          <div fxLayout="column" fxLayoutAlign="start" class="badge-column">
                            <app-tm-badge [teamMember]="teamMember" [noTooltip]="true" [size]="'static-md'"
                              class="badge-icon-mobile-header">
                            </app-tm-badge>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-toolbar>
                </div>
              </div>
            </div>
            <div class="tm-avatars">
              <div class="header-menu">Team Member Avatars<div class="border-bottom-full"></div></div>
              <div class="avatar-options">
                <div>
                  <div class="header-menu">Initials</div>
                  <app-tm-badge [teamMember]="{ firstName: 'C', lastName: 'S' }" [noTooltip]="true" [size]="'md'"
                    class="badge-icon-mobile-header">
                  </app-tm-badge>
                  <app-tm-badge [teamMember]="{ firstName: 'M', lastName: 'M' }" [noTooltip]="true" [size]="'md'"
                    class="badge-icon-mobile-header">
                  </app-tm-badge>
                  <app-tm-badge [teamMember]="{ firstName: 'J', lastName: 'L' }" [noTooltip]="true" [size]="'md'"
                    class="badge-icon-mobile-header">
                  </app-tm-badge>
                  <app-tm-badge [teamMember]="{ firstName: 'A', lastName: 'G' }" [noTooltip]="true" [size]="'md'"
                    class="badge-icon-mobile-header">
                  </app-tm-badge>
                  <app-tm-badge [teamMember]="{ firstName: 'J', lastName: 'B' }" [noTooltip]="true" [size]="'md'"
                    class="badge-icon-mobile-header">
                  </app-tm-badge>
                  <app-tm-badge [teamMember]="{ firstName: 'S', lastName: 'O' }" [noTooltip]="true" [size]="'md'"
                    class="badge-icon-mobile-header">
                  </app-tm-badge>
                  <div class="description">Background: Orbit Purple</div>
                </div>
                <div>
                  <div class="header-menu">Photo</div>
                  <app-tm-badge [teamMember]="{ firstName: 'N', lastName: 'A' }" [noTooltip]="true" [size]="'md'"
                    class="badge-icon-mobile-header">
                  </app-tm-badge>
                </div>
              </div>
            </div>
            <div class="tm-search-results-and-calendar-days">
              <div class="tm-search-results">
                <div class="header-menu">Team Member Search Results<div class="border-bottom-full"></div></div>
                <div>
                  <div class="header-menu">Default</div>
                  <div class="selected-member">
                    <div class="search-option">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <app-tm-badge [size]="'static-md'" [teamMember]="{ firstName: 'M', lastName: 'M' }">
                        </app-tm-badge>
                        <div class="search-details">
                          <div><span class="title">Mike Malloy </span> <span class="job-title">(Chief Amazement
                              Officer)</span></div>
                          <div class="contact-details"><u>MikeMalloy@rocketcentral.com</u></div>
                          <div class="organization-details">Rocket Central - Rock Central Executive Team</div>
                        </div>
                        <div class="plus-icon" [matTooltip]="'Add'" matTooltipPosition="above"><i
                            class="fas fa-plus"></i></div>
                      </div>
                    </div>
                  </div>
                  <div class="styles">
                    <div>Background: White</div>
                    <div>Icon: Black(10)</div>
                    <div>Radius: 0px</div>
                  </div>
                </div>
                <div>
                  <div class="header-menu">Default (Hover)</div>
                  <div class="selected-member enabled">
                    <div class="search-option">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <app-tm-badge [size]="'static-md'" [teamMember]="{ firstName: 'M', lastName: 'M' }">
                        </app-tm-badge>
                        <div class="search-details">
                          <div><span class="title">Mike Malloy </span> <span class="job-title">(Chief Amazement
                              Officer)</span></div>
                          <div class="contact-details"><u>MikeMalloy@rocketcentral.com</u></div>
                          <div class="organization-details">Rocket Central - Rock Central Executive Team</div>
                        </div>
                        <div class="plus-icon enabled" [matTooltip]="'Add'" matTooltipPosition="above"><i
                            class="fas fa-plus"></i></div>
                      </div>
                    </div>
                  </div>
                  <div class="styles">
                    <div>Background: White</div>
                    <div>Icon: Black(10)</div>
                    <div>Radius: 0px</div>
                  </div>
                </div>
                <div>
                  <div class="header-menu">Default (Selected)</div>
                  <div class="selected-member">
                    <div class="search-option">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <app-tm-badge [size]="'static-md'" [teamMember]="{ firstName: 'M', lastName: 'M' }">
                        </app-tm-badge>
                        <div class="search-details">
                          <div><span class="title">Mike Malloy </span> <span class="job-title">(Chief Amazement
                              Officer)</span></div>
                          <div class="contact-details"><u>MikeMalloy@rocketcentral.com</u></div>
                          <div class="organization-details">Rocket Central - Rock Central Executive Team</div>
                        </div>
                        <div class="minus-icon" [matTooltip]="'Remove'" matTooltipPosition="above"><i
                            class="fas fa-minus"></i></div>
                      </div>
                    </div>
                  </div>
                  <div class="styles">
                    <div>Background: White</div>
                    <div>Icon: Black(10)</div>
                    <div>Radius: 0px</div>
                  </div>
                </div>
              </div>
              <div class="calendar-days">
                <div class="header-menu">Calendar Days<div class="border-bottom-full"></div></div>
                <div class="calendar-group">
                  <div>
                    <div class="header-menu">Active</div>
                    <div class="calendar-day-wrapper">
                      <div class="calendar-day-large"><div>0</div></div>
                      <div class="calendar-day-small"><div>0</div></div>
                    </div>
                    <div class="styles">
                      <div>Background: White</div>
                      <div>Border: 2px #DEDEE0</div>
                      <div>Font Size: 14px</div>
                      <div>Color: Black (Light)</div>
                    </div>
                  </div>
                  <div>
                    <div class="header-menu">Inactive</div>
                    <div class="calendar-day-wrapper inactive">
                      <div class="calendar-day-large"><div>0</div></div>
                      <div class="calendar-day-small"><div>0</div></div>
                    </div>
                    <div class="styles">
                      <div>Background: Secondary</div>
                      <div>Border: 2px #DEDEE0</div>
                      <div>Font Size: 14px</div>
                      <div>Color: Gray</div>
                    </div>
                  </div>
                </div>
                <div class="calendar-group">
                  <div>
                    <div class="header-menu">Today</div>
                    <div class="calendar-day-wrapper">
                      <div class="calendar-day-large"><div><span>0</span></div></div>
                      <div class="calendar-day-small"><div><span>0</span></div></div>
                    </div>
                    <div class="styles">
                      <div>Background: Inherit</div>
                      <div>Border: 2px #DEDEE0</div>
                      <div>Font Size: 14px</div>
                      <div>Color: White</div>
                    </div>
                  </div>
                  <div>
                    <div class="header-menu">Team Member</div>
                    <div class="calendar-day-wrapper">
                      <div class="calendar-day-large">
                        <div>0</div>
                        <app-tm-badge [size]="'sm'" [teamMember]="{ firstName: 'M', lastName: 'M' }">
                        </app-tm-badge>
                      </div>
                      <div class="calendar-day-small">
                        <div>0</div>
                        <app-tm-badge [size]="'sm'" [teamMember]="{ firstName: 'M', lastName: 'M' }">
                        </app-tm-badge>
                      </div>
                    </div>
                    <div class="styles">
                      <div>Background: Inherit</div>
                      <div>Border: 2px #DEDEE0</div>
                      <div>Font Size: 14px</div>
                      <div>Color: Black</div>
                    </div>
                  </div>
                </div>
                <div class="calendar-group">
                  <div>
                    <div class="header-menu">My Recurring</div>
                    <div class="calendar-day-wrapper">
                      <div class="calendar-day-large recurring">
                        <div>
                          <div><span class="recurring"><i class="fas fa-retweet"></i></span></div>
                          <div>0</div>
                        </div>
                        <app-tm-badge [size]="'sm'" [teamMember]="{ firstName: 'M', lastName: 'M' }">
                        </app-tm-badge>
                      </div>
                      <div class="calendar-day-small recurring">
                        <div>
                          <div><span class="recurring"><i class="fas fa-retweet"></i></span></div>
                          <div>0</div>
                        </div>
                        <app-tm-badge [size]="'sm'" [teamMember]="{ firstName: 'M', lastName: 'M' }">
                        </app-tm-badge>
                      </div>
                    </div>
                    <div class="styles">
                      <div>Background: Gray</div>
                      <div>Border: 2px #DEDEE0</div>
                      <div>Font Size: 14px</div>
                      <div>Color: Black</div>
                    </div>
                  </div>
                  <div>
                    <div class="header-menu">My Temporary</div>
                    <div class="calendar-day-wrapper">
                      <div class="calendar-day-large temporary">
                        <div>
                          <div><span class="temporary"><i class="fas fa-hourglass-half"></i></span></div>
                          <div>0</div>
                        </div>
                        <app-tm-badge [size]="'sm'" [teamMember]="{ firstName: 'M', lastName: 'M' }">
                        </app-tm-badge>
                      </div>
                      <div class="calendar-day-small temporary">
                        <div>
                          <div><span class="temporary"><i class="fas fa-hourglass-half"></i></span></div>
                          <div>0</div>
                        </div>
                        <app-tm-badge [size]="'sm'" [teamMember]="{ firstName: 'M', lastName: 'M' }">
                        </app-tm-badge>
                      </div>
                    </div>
                    <div class="styles">
                      <div>Background: Gray</div>
                      <div>Border: 2px #DEDEE0</div>
                      <div>Font Size: 14px</div>
                      <div>Color: Black</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="callouts">
              <div class="header-menu">Callouts<div class="border-bottom-full"></div></div>
              <app-callout type="default" title="Reminder">
                By submitting the request below, you are permanently changing a Team Member's recurring schedule.
              </app-callout>
              <app-callout type="alert" title="Alert">
                Your requested Start Date has to be at least 14 days from today.
              </app-callout>
              <app-callout type="error" title="Error">
                Your request did not process successfully. Please renter your information and resubmit.
              </app-callout>
            </div>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Emails">

        <mat-tab-group class="email-tabs">

          <mat-tab label="Request Submitted">
            <!doctype html>
            <html lang="en">

            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
              <meta name="color-scheme" content="light dark">
              <meta name="supported-color-schemes" content="light dark">
              <title>Request Submitted</title>

            </head>

            <body
              style="background-color: #f6f6f6;font-family: sans-serif;-webkit-font-smoothing: antialiased;font-size: 16px;line-height: 1.4;margin: 0;padding: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <table role="presentation" class="body"
                style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;background-color: #f6f6f6;color: #000;">
                <tr>
                  <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">&nbsp;</td>
                  <td class="container"
                    style="font-family: sans-serif;font-size: 16px;vertical-align: top;display: block;max-width: 768px;padding: 10px;width: 768px;margin: 0 auto !important;">
                    <div class="content"
                      style="box-sizing: border-box;display: block;margin: 0 auto;max-width: 768px;padding: 10px;">

                      <!-- START CENTERED WHITE CONTAINER -->
                      <table role="presentation" class="main"
                        style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;background: #ffffff;border-radius: 3px;">

                        <!-- START MAIN CONTENT AREA -->
                        <tr>
                          <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                            <table id="template-header"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td class="header-content"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <img src="../assets/img/emails/Mask Group 69@2x.png"
                                    style="max-width: 768px;width: 768px;height: 60px;border: none;-ms-interpolation-mode: bicubic;"
                                    id="header-image" alt="header">
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block logo"
                                  style="text-align: center;font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;">
                                  <img src="../assets/img/emails/Logo@2x.png"
                                    style="max-width: 212px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td class="wrapper"
                            style="font-family: sans-serif;font-size: 16px;vertical-align: top;box-sizing: border-box;padding: 20px;">
                            <table role="presentation"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Hello,</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    The following schedule change has been submitted and is pending approval:</p>
                                  <table role="presentation" class="schedule-change-info"
                                    style="background-color: #f7f7f7;padding: 10px;border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                                    <tbody>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Team Member: <span class="schedule-change-data"
                                            style="font-weight: bold;">Monica Fishman</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Team Leader: <span class="schedule-change-data"
                                            style="font-weight: bold;">Megan Land</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Requester: <span class="schedule-change-data" style="font-weight: bold;">Shawn
                                            Sweeny</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Change Type: <span class="schedule-change-data"
                                            style="font-weight: bold;">Update</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Effective Date: <span class="schedule-change-data"
                                            style="font-weight: bold;">10/4/2022</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Week 1: <span class="schedule-change-data" style="font-weight: bold;">Mon,
                                            Wed</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Week 2: <span class="schedule-change-data" style="font-weight: bold;">Mon,
                                            Wed, Fri</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Reason: <span class="schedule-change-data" style="font-weight: bold;">New
                                            Team</span></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    &nbsp;</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    [TM] does not yet have an email address. Please connect with them to make them aware
                                    of this change.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Please note that it may take up to two weeks to review this request.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Once approved or denied, you will receive an email confirmation informing you of the
                                    action taken on this submission.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    In the meantime, if you have any questions regarding this request, please email: <a
                                      href="mailto:FocusWorkplacePlanning@RocketCentral.com"
                                      style="color: #603AA1;text-decoration: underline;font-weight: bold;">FocusWorkplacePlanning@RocketCentral.com</a>
                                  </p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Thank you!</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                            <table
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <img src="../assets/img/emails/Mask Group 69@2x.png"
                                    style="max-width: 768px;width: 768px;height: 60px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr class="footer" style="clear: both;text-align: center;width: 100%;background: #ffffff;">
                          <td class="wrapper"
                            style="font-family: sans-serif;font-size: 16px;vertical-align: top;box-sizing: border-box;padding: 20px;color: #000000;text-align: center;">
                            <table role="presentation"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td class="content-block"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #000000;text-align: center;">
                                  <p
                                    style="font-style: italic;font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;color: #000000;text-align: center;">
                                    This email was sent from <a href="https://onsite.foc.zone/"
                                      style="color: #000000;text-decoration: underline;font-weight: bold;font-size: 16px;text-align: center;">https://onsite.foc.zone/</a>
                                    and intended for MonicaFischman@rockcentraldetroit.com</p>
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block logo"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #000000;text-align: center;">
                                  <img src="../assets/img/emails/Group 1769@2x.png"
                                    style="max-width: 180px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block copyright"
                                  style="font-size: 14px;font-family: sans-serif;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #000000;text-align: center;">
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;color: #000000;text-align: center;">
                                    © 2022 Rocket Central, LLC. All Rights Reserved.</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <!-- END MAIN CONTENT AREA -->
                      </table>
                      <!-- END CENTERED WHITE CONTAINER -->

                    </div>
                  </td>
                  <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">&nbsp;</td>
                </tr>
              </table>
            </body>

            </html>
          </mat-tab>

          <mat-tab label="Request Approved">
            <!doctype html>
            <html lang="en">

            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
              <meta name="color-scheme" content="light dark">
              <meta name="supported-color-schemes" content="light dark">
              <title>Request Approved</title>

            </head>

            <body
              style="background-color: #f6f6f6;font-family: sans-serif;-webkit-font-smoothing: antialiased;font-size: 16px;line-height: 1.4;margin: 0;padding: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <table role="presentation" class="body"
                style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;background-color: #f6f6f6;color: #000;">
                <tr>
                  <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">&nbsp;</td>
                  <td class="container"
                    style="font-family: sans-serif;font-size: 16px;vertical-align: top;display: block;max-width: 768px;padding: 10px;width: 768px;margin: 0 auto !important;">
                    <div class="content"
                      style="box-sizing: border-box;display: block;margin: 0 auto;max-width: 768px;padding: 10px;">

                      <!-- START CENTERED WHITE CONTAINER -->
                      <table role="presentation" class="main"
                        style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;background: #ffffff;border-radius: 3px;">

                        <!-- START MAIN CONTENT AREA -->
                        <tr>
                          <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                            <table id="template-header"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td class="header-content"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <img src="../assets/img/emails/Mask Group 69@2x.png"
                                    style="max-width: 768px;width: 768px;height: 60px;border: none;-ms-interpolation-mode: bicubic;"
                                    id="header-image" alt="header">
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block logo"
                                  style="text-align: center;font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;">
                                  <img src="../assets/img/emails/Logo@2x.png"
                                    style="max-width: 212px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td class="wrapper"
                            style="font-family: sans-serif;font-size: 16px;vertical-align: top;box-sizing: border-box;padding: 20px;">
                            <table role="presentation"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Hello,</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    The following schedule change has been approved!</p>
                                  <table role="presentation" class="schedule-change-info"
                                    style="background-color: #f7f7f7;padding: 10px;border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                                    <tbody>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Team Member: <span class="schedule-change-data"
                                            style="font-weight: bold;">Monica Fishman</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Team Leader: <span class="schedule-change-data"
                                            style="font-weight: bold;">Megan Land</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Requester: <span class="schedule-change-data" style="font-weight: bold;">Shawn
                                            Sweeny</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Change Type: <span class="schedule-change-data"
                                            style="font-weight: bold;">Update</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Effective Date: <span class="schedule-change-data"
                                            style="font-weight: bold;">10/4/2022</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Week 1: <span class="schedule-change-data" style="font-weight: bold;">Mon,
                                            Wed</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Week 2: <span class="schedule-change-data" style="font-weight: bold;">Mon,
                                            Wed, Fri</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Reason: <span class="schedule-change-data" style="font-weight: bold;">New
                                            Team</span></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    &nbsp;</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    [TM] does not yet have an email address. Please connect with them to make them aware
                                    of this change.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    This information along with <span style="font-weight: bold;">Desk</span> and <span
                                      style="font-weight: bold;">Parking</span> assignments have been added into the <a
                                      href="https://onsite.foc.zone/"
                                      style="color: #603AA1;text-decoration: underline;font-weight: bold;">Onsite
                                      Scheduler</a> for you to reference.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    If your desk has changed, please do not begin using until the effective date listed
                                    above.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Also, double-check your parking which may update separately from your desk.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    In the meantime, if you have any questions regarding this request, please email: <a
                                      href="mailto:FocusWorkplacePlanning@RocketCentral.com"
                                      style="color: #603AA1;text-decoration: underline;font-weight: bold;">FocusWorkplacePlanning@RocketCentral.com</a>
                                  </p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Thank you!</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                            <table
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <img src="../assets/img/emails/Mask Group 69@2x.png"
                                    style="max-width: 768px;width: 768px;height: 60px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr class="footer" style="clear: both;text-align: center;width: 100%;background: #ffffff;">
                          <td class="wrapper"
                            style="font-family: sans-serif;font-size: 16px;vertical-align: top;box-sizing: border-box;padding: 20px;color: #000000;text-align: center;">
                            <table role="presentation"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td class="content-block"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #000000;text-align: center;">
                                  <p
                                    style="font-style: italic;font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;color: #000000;text-align: center;">
                                    This email was sent from <a href="https://onsite.foc.zone/"
                                      style="color: #000000;text-decoration: underline;font-weight: bold;font-size: 16px;text-align: center;">https://onsite.foc.zone/</a>
                                    and intended for MonicaFischman@rockcentraldetroit.com</p>
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block logo"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #000000;text-align: center;">
                                  <img src="../assets/img/emails/Group 1769@2x.png"
                                    style="max-width: 180px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block copyright"
                                  style="font-size: 14px;font-family: sans-serif;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #000000;text-align: center;">
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;color: #000000;text-align: center;">
                                    © 2022 Rocket Central, LLC. All Rights Reserved.</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <!-- END MAIN CONTENT AREA -->
                      </table>
                      <!-- END CENTERED WHITE CONTAINER -->

                    </div>
                  </td>
                  <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">&nbsp;</td>
                </tr>
              </table>
            </body>

            </html>
          </mat-tab>

          <mat-tab label="Request Denied">
            <!doctype html>
            <html lang="en">

            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
              <meta name="color-scheme" content="light dark">
              <meta name="supported-color-schemes" content="light dark">
              <title>Request Denied</title>

            </head>

            <body
              style="background-color: #f6f6f6;font-family: sans-serif;-webkit-font-smoothing: antialiased;font-size: 16px;line-height: 1.4;margin: 0;padding: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <table role="presentation" class="body"
                style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;background-color: #f6f6f6;color: #000;">
                <tr>
                  <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">&nbsp;</td>
                  <td class="container"
                    style="font-family: sans-serif;font-size: 16px;vertical-align: top;display: block;max-width: 768px;padding: 10px;width: 768px;margin: 0 auto !important;">
                    <div class="content"
                      style="box-sizing: border-box;display: block;margin: 0 auto;max-width: 768px;padding: 10px;">

                      <!-- START CENTERED WHITE CONTAINER -->
                      <table role="presentation" class="main"
                        style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;background: #ffffff;border-radius: 3px;">

                        <!-- START MAIN CONTENT AREA -->
                        <tr>
                          <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                            <table id="template-header"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td class="header-content"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <img src="../assets/img/emails/Mask Group 69@2x.png"
                                    style="max-width: 768px;width: 768px;height: 60px;border: none;-ms-interpolation-mode: bicubic;"
                                    id="header-image" alt="header">
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block logo"
                                  style="text-align: center;font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;">
                                  <img src="../assets/img/emails/Logo@2x.png"
                                    style="max-width: 212px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td class="wrapper"
                            style="font-family: sans-serif;font-size: 16px;vertical-align: top;box-sizing: border-box;padding: 20px;">
                            <table role="presentation"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Hello,</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    The following schedule change cannot be approved at this time:</p>
                                  <table role="presentation" class="schedule-change-info"
                                    style="background-color: #f7f7f7;padding: 10px;border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                                    <tbody>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Team Member: <span class="schedule-change-data"
                                            style="font-weight: bold;">Monica Fishman</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Team Leader: <span class="schedule-change-data"
                                            style="font-weight: bold;">Megan Land</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Requester: <span class="schedule-change-data" style="font-weight: bold;">Shawn
                                            Sweeny</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Change Type: <span class="schedule-change-data"
                                            style="font-weight: bold;">Update</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Effective Date: <span class="schedule-change-data"
                                            style="font-weight: bold;">10/4/2022</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Week 1: <span class="schedule-change-data" style="font-weight: bold;">Mon,
                                            Wed</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Week 2: <span class="schedule-change-data" style="font-weight: bold;">Mon,
                                            Wed, Fri</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Reason: <span class="schedule-change-data" style="font-weight: bold;">New
                                            Team</span></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    &nbsp;</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    [TM] does not yet have an email address. Please connect with them to make
                                    them aware of this change.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    If you believe this to be in error, or if you have any questions regarding
                                    this request, please email: <a
                                      href="mailto:FocusWorkplacePlanning@RocketCentral.com"
                                      style="color: #603AA1;text-decoration: underline;font-weight: bold;">FocusWorkplacePlanning@RocketCentral.com</a>
                                  </p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    A new request can be submitted by a Team Leader at any time via the <a
                                      href="https://onsite.foc.zone/"
                                      style="color: #603AA1;text-decoration: underline;font-weight: bold;">Onsite
                                      Scheduler</a>.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Thank you!</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                            <table
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <img src="../assets/img/emails/Mask Group 69@2x.png"
                                    style="max-width: 768px;width: 768px;height: 60px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr class="footer" style="clear: both;text-align: center;width: 100%;background: #ffffff;">
                          <td class="wrapper"
                            style="font-family: sans-serif;font-size: 16px;vertical-align: top;box-sizing: border-box;padding: 20px;color: #000000;text-align: center;">
                            <table role="presentation"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td class="content-block"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #000000;text-align: center;">
                                  <p
                                    style="font-style: italic;font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;color: #000000;text-align: center;">
                                    This email was sent from <a href="https://onsite.foc.zone/"
                                      style="color: #000000;text-decoration: underline;font-weight: bold;font-size: 16px;text-align: center;">https://onsite.foc.zone/</a>
                                    and
                                    intended for MonicaFischman@rockcentraldetroit.com</p>
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block logo"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #000000;text-align: center;">
                                  <img src="../assets/img/emails/Group 1769@2x.png"
                                    style="max-width: 180px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block copyright"
                                  style="font-size: 14px;font-family: sans-serif;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #000000;text-align: center;">
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;color: #000000;text-align: center;">
                                    © 2022 Rocket Central, LLC. All Rights Reserved.</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <!-- END MAIN CONTENT AREA -->
                      </table>
                      <!-- END CENTERED WHITE CONTAINER -->

                    </div>
                  </td>
                  <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">&nbsp;</td>
                </tr>

              </table>
            </body>

            </html>
          </mat-tab>

          <mat-tab label="Dark Mode">
            <!doctype html>
            <html lang="en">

            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
              <meta name="color-scheme" content="light dark">
              <meta name="supported-color-schemes" content="light dark">
              <title>Dark Mode - Request Submitted</title>

            </head>

            <body
              style="background-color: #f6f6f6;font-family: sans-serif;-webkit-font-smoothing: antialiased;font-size: 16px;line-height: 1.4;margin: 0;padding: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
              <table role="presentation" class="body"
                style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;background-color: #f6f6f6;color: #FFF;">
                <tr>
                  <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">&nbsp;</td>
                  <td class="container"
                    style="font-family: sans-serif;font-size: 16px;vertical-align: top;display: block;max-width: 768px;padding: 10px;width: 768px;margin: 0 auto !important;">
                    <div class="content"
                      style="box-sizing: border-box;display: block;margin: 0 auto;max-width: 768px;padding: 10px;">

                      <!-- START CENTERED WHITE CONTAINER -->
                      <table role="presentation" class="main"
                        style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;background: #1E1E1E;border-radius: 3px;">

                        <!-- START MAIN CONTENT AREA -->
                        <tr>
                          <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                            <table id="template-header"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td class="header-content"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <img src="./assets/img/emails/Mask Group 69@2x.png"
                                    style="max-width: 768px;width: 768px;height: 60px;border: none;-ms-interpolation-mode: bicubic;"
                                    id="header-image" alt="header">
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block logo"
                                  style="text-align: center;font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;">
                                  <img src="./assets/img/emails/Logo@2x.png"
                                    style="max-width: 212px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td class="wrapper"
                            style="font-family: sans-serif;font-size: 16px;vertical-align: top;box-sizing: border-box;padding: 20px;">
                            <table role="presentation"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Hello,</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    The following schedule change has been submitted and is pending approval:</p>
                                  <table role="presentation" class="schedule-change-info"
                                    style="background-color: #1E1E1E;padding: 10px;border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                                    <tbody>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Team Member: <span class="schedule-change-data"
                                            style="font-weight: bold;">Monica Fishman</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Team Leader: <span class="schedule-change-data"
                                            style="font-weight: bold;">Megan Land</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Requester: <span class="schedule-change-data" style="font-weight: bold;">Shawn
                                            Sweeny</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Change Type: <span class="schedule-change-data"
                                            style="font-weight: bold;">Update</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Effective Date: <span class="schedule-change-data"
                                            style="font-weight: bold;">10/4/2022</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Week 1: <span class="schedule-change-data" style="font-weight: bold;">Mon,
                                            Wed</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Week 2: <span class="schedule-change-data" style="font-weight: bold;">Mon,
                                            Wed, Fri</span></td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="font-family: sans-serif;font-size: 16px;vertical-align: top;font-weight: normal;">
                                          Reason: <span class="schedule-change-data" style="font-weight: bold;">New
                                            Team</span></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    &nbsp;</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    [TM] does not yet have an email address. Please connect with them to make them aware
                                    of this change.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Please note that it may take up to two weeks to review this request.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Once approved or denied, you will receive an email confirmation informing you of the
                                    action taken on this submission.</p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    In the meantime, if you have any questions regarding this request, please email: <a
                                      href="mailto:FocusWorkplacePlanning@RocketCentral.com"
                                      style="color: #603AA1;text-decoration: underline;font-weight: bold;">FocusWorkplacePlanning@RocketCentral.com</a>
                                  </p>
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;">
                                    Thank you!</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                            <table
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">
                                  <img src="./assets/img/emails/Mask Group 69@2x.png"
                                    style="max-width: 768px;width: 768px;height: 60px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr class="footer" style="clear: both;text-align: center;width: 100%;background: #1E1E1E;">
                          <td class="wrapper"
                            style="font-family: sans-serif;font-size: 16px;vertical-align: top;box-sizing: border-box;padding: 20px;color: #FFF;text-align: center;">
                            <table role="presentation"
                              style="border-collapse: separate;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;">
                              <tr>
                                <td class="content-block"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #FFF;text-align: center;">
                                  <p
                                    style="font-style: italic;font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;color: #FFF;text-align: center;">
                                    This email was sent from <a href="https://onsite.foc.zone/"
                                      style="color: #FFF;text-decoration: underline;font-weight: bold;font-size: 16px;text-align: center;">https://onsite.foc.zone/</a>
                                    and intended for MonicaFischman@rockcentraldetroit.com</p>
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block logo"
                                  style="font-family: sans-serif;font-size: 16px;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #FFF;text-align: center;">
                                  <img src="./assets/img/emails/Group 1769@2x.png"
                                    style="max-width: 180px;border: none;-ms-interpolation-mode: bicubic;" alt="header">
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block copyright"
                                  style="font-size: 14px;font-family: sans-serif;vertical-align: top;padding-bottom: 10px;padding-top: 10px;color: #FFF;text-align: center;">
                                  <p
                                    style="font-family: sans-serif;font-size: 16px;font-weight: normal;margin: 0;margin-bottom: 15px;color: #FFF;text-align: center;">
                                    © 2022 Rocket Central, LLC. All Rights Reserved.</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <!-- END MAIN CONTENT AREA -->
                      </table>
                      <!-- END CENTERED WHITE CONTAINER -->

                    </div>
                  </td>
                  <td style="font-family: sans-serif;font-size: 16px;vertical-align: top;">&nbsp;</td>
                </tr>
              </table>
            </body>

            </html>
          </mat-tab>

        </mat-tab-group>

      </mat-tab>

    </mat-tab-group>

  </div>
</div>
