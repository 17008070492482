import { Inject } from '@angular/core';
import { OnsiteUser } from './onsite-user';

export class SingleDateModel {
  public date: string;
  public currentUser: OnsiteUser;
  public reloadCalendar!: any;
  public scheduledDays: string[] = [];

  constructor(@Inject('EVENT_DATE') date: string, @Inject('CURRENT_USER') currentUser: OnsiteUser) {
    this.date = date;
    this.currentUser = currentUser;
  }

  public isUserTeamMember(commonId: string): boolean {
    return this.currentUser.commonId === commonId;
  }
}
