import { Component } from '@angular/core';
import { LabelEnums } from 'src/app/utils/enums';
import { environment } from '../../../environments/environment';
import { SupportEmail } from '../../models/support-email';

@Component({
  selector: 'app-request-schedule-change-nonadmin',
  templateUrl: './request-schedule-change-nonadmin.component.html',
  styleUrls: ['./request-schedule-change-nonadmin.component.scss']
})
export class RequestScheduleChangeNonAdminComponent {
  public teamLeaderFeatureFlag = false;

  public workspaceSupport = new SupportEmail(
    'FocusWorkplacePlanning@RockCentralDetroit.com',
    'Onsite Scheduler Inquiry'
  );

  constructor() {
    this.teamLeaderFeatureFlag = JSON.parse(environment.teamLeaderFeatureFlag);
  }

  public get nonIOfficeCompanies(): string{
    return LabelEnums.NonIOfficeCompanies;
  }

}
