import { Component, EventEmitter, Inject, OnInit, Optional, Output, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { MatDialog } from '@angular/material/dialog';
import { EditEndScheduleComponent } from './edit-end-schedule/edit-end-schedule.component';
import { ISchedule, IScheduleCard, IScheduleRequestRecurrencePattern } from 'src/app/models/scheduleRequest';
import { GeneralUtilities } from 'src/app/utils/general-utilities';
import * as _ from 'lodash';
import { LabelEnums, ScreenSize } from 'src/app/utils/enums';
import { DeviceSettings } from 'src/app/utils/device-settings';
import { IEditScheduleEndDates } from 'src/app/models/edit-schedule-end-dates';
import { ConfirmationModalService } from 'src/app/services/confirmation/confirmation-modal.service';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-request-schedule-card',
  templateUrl: './request-schedule-card.component.html',
  styleUrls: ['./request-schedule-card.component.scss']
})
export class RequestScheduleCardComponent implements OnInit {

  private minDate = DateTime.now().plus({ days: 14 }).toJSDate();
  private currentUser!: OnsiteUser;
  private earliestDateAllowed!: Date;

  public screenSize: ScreenSize;
  public schedules: Array<IScheduleCard> = [];
  public maxDate: Date | undefined = undefined;
  public originalEndDate = '';
  public hideEditEndDate = false;

  @Input() readOnly = false;
  @Input() forceMobileLayout = false;
  @Input() defaultScheduleData: ISchedule[] = [];
  @Input()
  set newScheduleData(data: IScheduleRequestRecurrencePattern[]) {
    if (data[0] && data[0].scheduleStartDate) {
      if (this.schedules[0]) {
        this.maxDate = new Date(data[0].scheduleStartDate);
        if (this.maxDate > new Date()) {
          this.maxDate.setUTCDate(this.maxDate.getUTCDate() - 1);
        } else {
          this.hideEditEndDate = true;
        }
        this.schedules[0].scheduleEndDate = this.maxDate.toISOString()?.split('T')[0];
      }
    } else {
      this.maxDate = undefined;
      if (this.schedules[0]) {
        this.schedules[0].scheduleEndDate = this.originalEndDate;
      }
      this.hideEditEndDate = false;
    }
  }

  @Input() public endScheduleData: IEditScheduleEndDates = { deleteScheduleEndDate: '', editScheduleEndDate: '' };
  @Output() public eventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public removeSchedule: EventEmitter<any> = new EventEmitter();

  @Input() public tmScheduleData: Array<ISchedule> = [];

  constructor(
    @Inject('SchedulerService') public schedulerService: SchedulerService, private router: Router,
    @Optional() @Inject('MatDialog') public dialog: MatDialog,
    @Inject('ConfirmationModalService') private confirmationService: ConfirmationModalService,
    @Inject('OnsiteAuthService') private authService: IOnsiteAuthService
  ) {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
    this.authService.getUser$().subscribe(user => this.currentUser = user);
  }

  async ngOnInit(): Promise<void> {
    if (this.defaultScheduleData.length) {
      this.schedules = this.mapSchedules(this.defaultScheduleData);
    } else {
      const tmScheduleData = await this.schedulerService.getTmScheduleData(this.teamMemberCommonId);

      this.schedules = this.mapSchedules(tmScheduleData);
    }

    this.originalEndDate = this.schedules[0]?.scheduleEndDate || '';
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  public deleteSchedule(index: number): void {
    const confirmation = 'Are you sure you want to remove this schedule?';
    const header = 'Remove Upcoming Schedule for ' + this.teamMemberName;
    this.confirmationService.open(confirmation, header)
      .afterClosed().subscribe(deleted => {
        if (deleted) {
          this.schedules.splice(index, 1);

          this.endScheduleData.editScheduleEndDate = '';
          if (this.schedules.length > 0) {
            this.endScheduleData.deleteScheduleEndDate = this.schedules[this.schedules.length - 1].scheduleEndDate ?? '';
          } else {
            this.endScheduleData.deleteScheduleEndDate = new Date().toISOString().split('T')[0];
          }
          this.eventEmitter.emit();
          this.removeSchedule.emit();
        }
      });
  }

  public mapSchedules(tmScheduleData: Array<ISchedule>): Array<IScheduleCard> {
    const scheduleList: Array<IScheduleCard> = [];

    if (tmScheduleData.length > 0) {
      const groupedSchedules = Object.values(_.groupBy(tmScheduleData, 'sourceId'));

      for (const schedule of groupedSchedules) {
        const sched = _.sortBy(schedule, 'scheduleStartDate') as ISchedule[];
        const scheduleObj: IScheduleCard = {
          scheduleStartDate: sched[0].scheduleStartDate,
          scheduleEndDate: sched[0].scheduleEndDate,
          weekDays: []
        };
        for (const week of sched) {
          scheduleObj.weekDays?.push(week.weekDays);
        }
        scheduleList.push(scheduleObj);
      }
    }

    return _.sortBy(scheduleList, 'scheduleStartDate');
  }

  public isDaySelected(weekday: string, weekdays?: string): boolean {
    return weekdays?.includes(weekday) ?? false;
  }

  public get teamMemberName(): string {
    const teamMember = this.schedulerService.selectedTeamMember?.teamMember;
    return `${teamMember?.firstName} ${teamMember?.lastName}`;
  }

  public get teamMemberCommonId(): string {
    const teamMember = this.schedulerService.selectedTeamMember?.teamMember;
    return `${teamMember?.commonId}`;
  }

  public get daysOfWeek(): Array<string> {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }

  public get showMobileLayout(): boolean {
    return (this.screenSize <= ScreenSize.small) || this.forceMobileLayout;
  }

  public editSchedule(index: number): void {
    this.setEarliestDateAllowed(index);
    this.dialog.open(EditEndScheduleComponent, {
      width: '850px',
      maxWidth: 850,
      autoFocus: false,
      panelClass: 'end-dialogue-container',
      data: {
        name: this.teamMemberName,
        endDate: new Date(`${this.schedules[index].scheduleEndDate}T00:00:00`),
        minDate: this.earliestDateAllowed,
        maxDate: this.maxDate
      }
    })
      .afterClosed().subscribe(result => {
        if (result) {
          this.schedules[index].scheduleEndDate = result;
          this.endScheduleData.editScheduleEndDate = result;
          this.eventEmitter.emit();
        }
      });
  }

  public formattedDate(date: string | undefined): string {
    return date ? GeneralUtilities.formatDateMMDDYY(new Date(date)) : '—';
  }

  public isFutureSchedule(date: string | undefined): boolean {
    const currentDate = new Date().toISOString()?.split('T')[0];
    return date ? date > currentDate : false;
  }

  public get removeFutureScheduleTooltip(): string {
    return LabelEnums.RemoveFutureScheduleTooltip;
  }

  private setEarliestDateAllowed(index: number): void {
    this.earliestDateAllowed = new Date(`${this.schedules[index].scheduleStartDate}T00:00:00`);
    if (this.currentUser.isEffectivelyLeader) {
      this.earliestDateAllowed = this.minDate > this.earliestDateAllowed ? this.minDate : this.earliestDateAllowed;
    }
    else {
      this.earliestDateAllowed = new Date() > this.earliestDateAllowed ? new Date() : this.earliestDateAllowed;
    }
  }
}
