import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'fileSize'})
export class FileSizePipe implements PipeTransform {
  public transform(file: File | null): string {
    const fileSize = (file?.size || 0);

    if (fileSize === 0) {
      return '0 MB';
    }

    // the index calculated by the multiples of the bytes i.e 0 = 'B'
    const i: number = Math.floor(Math.log(fileSize) / Math.log(1024));

    return (fileSize / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
  }
}
