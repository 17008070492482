import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Booking } from 'src/app/models/booking';
import { IEventTeamMember } from 'src/app/models/calendar-interfaces';
import { OnsiteTeamMember } from 'src/app/models/onsite-team-member';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { ISchedule, IScheduleRequest } from 'src/app/models/scheduleRequest';
import { IScheduleRequestResponse } from 'src/app/models/scheduleRequestResponse';
import { TeamMemberSearchOption } from 'src/app/models/team-member';
import {
    ParkingAssignment,
    ServiceResult,
    TeamMemberOnsiteDataResponse,
    UserPermanentSpace,
    UserSpace
} from 'src/app/models/team-member-onsite-data-response';
import { environment } from 'src/environments/environment';
import * as RemoveBookingActions from '../../constants/remove-booking-actions';
import { CalendarEvents } from '../../models/calendar-events';
import {
    IRemediationSteps,
    Parking,
    ParkingAndSeating,
    RootParkingAndSeating,
    Seating
} from '../../models/scheduler-api-interfaces';
import { SingleEventModel } from '../../models/single-event';
import { ScheduleUtilities } from '../schedule-utilities';

const MinDate = '1970-01-01';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

  public selectedTeamMember: TeamMemberSearchOption | undefined;
  private bookingsFeatureFlag = false;

  constructor(
    @Inject('HttpClient') private httpClient: HttpClient,
    @Inject('CalendarEvents') public calendarEvents: CalendarEvents,
    @Inject('BookingCalendarEvents') public bookingCalendarEvents: CalendarEvents,
    @Inject('DatePipe') public datePipe: DatePipe,
    @Inject('ScheduleUtilities') public scheduleUtilities: ScheduleUtilities
  ) {
    this.bookingsFeatureFlag = JSON.parse(environment.bookingsFeatureFlag);
  }

  public getParkingAndWorkplace(commonId: string): Observable<ParkingAndSeating> {
    if (!commonId) {
      throw new Error('Invalid commonId provided!');
    }

    return this.httpClient.post<RootParkingAndSeating[]>(
      `${environment.api.supportApi.url}/v1.0/ReadinessCheck`,
      { commonId, checkFilters: ['WORKSPACEASSIGNMENT', 'PARKINGASSIGNMENT'] }
    )
      .pipe(map(response => {
        const data: RootParkingAndSeating[] = response;

        const seatingAssignment: Seating[] = data[0].supplementalData || new Array<Seating>();
        const seatingRemediation: IRemediationSteps[] = data[0].remediationSteps || new Array<IRemediationSteps>();

        const parkingAssignment: Parking[] = data[1].supplementalData || new Array<Parking>();
        const parkingRemediation: IRemediationSteps[] = data[1].remediationSteps || new Array<IRemediationSteps>();

        const parkingAndSeating: ParkingAndSeating = {
          SeatingCheckPassed: data[0].checkPassed,
          SeatingAssignment: seatingAssignment,
          SeatingRemediation: seatingRemediation,
          ParkingCheckPassed: data[1].checkPassed,
          ParkingAssignment: parkingAssignment,
          ParkingRemediation: parkingRemediation
        };

        return parkingAndSeating;
      }));
  }

  public getTmScheduleData(commonId: string): Promise<ISchedule[]> {
    const usersTimeZone = this.scheduleUtilities.determineTimeZone();

    return this.httpClient.post<ISchedule[]>(
      `${environment.api.scheduleApi.url}/v1/teamMember/schedules`,
      {
        commonId,
        usersTimeZone
      }
    ).pipe(map((res: ISchedule[]) => {
      res.forEach((schedule: ISchedule) => {
        schedule.commonId = schedule.commonId.toString();
      });
      return res;
    })).toPromise();
  }

  public getBaseDate(date: string): Date {
    return new Date(date.split('T')[0] + 'T00:00:00.000Z');
  }

  public determineParkingAssignment(
    initSet: Array<Parking>,
    remediationSet: Array<IRemediationSteps>,
    checkPassed: boolean,
    eventDate: string,
  ): any {

    const set = Object.assign([], initSet);
    const date = this.getBaseDate(eventDate);

    const response = {
      isAssigned: false,
      label: `No assigned parking.`,
      url: '',
    };

    if (!checkPassed) {
      if (remediationSet.length) {
        response.label = remediationSet[0].title;
      }

      return response;
    }

    const matches: Array<object> = [];

    set.forEach((value: Parking) => {
      const dateAssigned = this.getBaseDate(value.effectiveDate);

      if (dateAssigned <= date) {
        matches.push({
          isAssigned: true,
          label: `${value.garageFriendlyName}`,
          url: '',
        });

      }
    });

    if (matches.length) {
      return matches[matches.length - 1];
    }

    return response;
  }

  public determineSeatingAssignment(
    initSet: Array<Seating>,
    remediationSet: Array<IRemediationSteps>,
    checkPassed: boolean,
    eventDate: string
  ): any {
    const set = Object.assign([], initSet);
    const date = this.getBaseDate(eventDate);

    const response = {
      isAssigned: false,
      label: `No assigned seating.`,
      url: '',
    };

    if (!checkPassed) {
      if (remediationSet.length) {
        response.label = remediationSet[0].title;
      }

      return response;
    }

    const matches: Array<object> = [];

    set.forEach((value: Seating) => {
      let dateAssigned;

      if (value.dateAssigned == null) {
        dateAssigned = this.getBaseDate(MinDate);
      } else {
        dateAssigned = this.getBaseDate(value.dateAssigned);
      }

      if (dateAssigned <= date) {
        matches.push({
          isAssigned: true,
          label: `${value.building.name} - ${value.room.name}`,
          url: `${value.pingLink ?? ''}`,
        });

      }
    });

    if (matches.length) {
      return matches[matches.length - 1];
    }

    return response;
  }

  public getTeamMemberData(
    commonId: string,
    startDate: string,
    endDate: string,
    includeParkingData: boolean
  ): Observable<TeamMemberOnsiteDataResponse> {
    if (!commonId) {
      throw new Error('Invalid commonId provided!');
    }

    const dataPoints = ['teamMemberSchedule', 'userSpace'];

    if (this.bookingsFeatureFlag) {
      dataPoints.push('bookings');
    }

    if (includeParkingData) {
      dataPoints.push('parkingAssignment');
    }

    const postObj = {
      commonId,
      startDate,
      endDate,
      dataPoints
    };

    return this.httpClient.post<TeamMemberOnsiteDataResponse>(
      `${environment.api.supportApi.url}/v1.0/Onsite/TeamMemberOnsiteData`, postObj
    );
  }

  public getTeamMemberScheduleDataOnly(commonId: string, startDate: string, endDate: string): Observable<TeamMemberOnsiteDataResponse> {
    if (!commonId) {
      throw new Error('Invalid commonId provided!');
    }

    const dataPoints = ['teamMemberSchedule'];

    if (this.bookingsFeatureFlag) {
      dataPoints.push('bookings');
    }

    const postObj = {
      commonId,
      startDate,
      endDate,
      dataPoints
    };

    return this.httpClient.post<TeamMemberOnsiteDataResponse>(
      `${environment.api.supportApi.url}/v1.0/Onsite/TeamMemberOnsiteData`, postObj
    );
  }

  public buildTeamMemberScheduleDataOnly(teamMemberItem: OnsiteTeamMember, currentUser: OnsiteUser): any {
    const days = teamMemberItem.tmResponse.teamMemberSchedule.data;
    const bookings = teamMemberItem.tmResponse.bookings?.data || [];
    const teamMember = teamMemberItem.tmData;
    const parkingAssignments = teamMemberItem.tmResponse.parkingAssignment;
    const deskAssignments = teamMemberItem.tmResponse.userSpace;

    days.forEach(day => {
      const eventTeamMember: IEventTeamMember = {
        commonId: teamMember.commonId,
        firstName: teamMember.firstName,
        lastName: teamMember.lastName,
        email: teamMember.email,
        team: teamMember.team,
        companyName: teamMember.companyName,
        bgColorIndex: teamMember.bgColorIndex,
        jobTitle: teamMember.jobTitle,
        parking: this.getActiveParking(parkingAssignments, day),
        seating: this.getActiveSeating(deskAssignments, day),
        reservedSeating: this.getReservedSeating(deskAssignments, day),
        scheduleType: 'recurring',
        spaceType: 'other'
      };

      this.bookingCalendarEvents.addTeamMemberToEvent(day, eventTeamMember, currentUser);
    });

    const filteredBookings = bookings.filter(
      (booking) => !days.some((day) => this.getBaseDateString(day) === this.getBaseDateString(booking.startDate))
    );

    filteredBookings.forEach(booking => {
      const eventTeamMember: IEventTeamMember = {
        commonId: teamMember.commonId,
        firstName: teamMember.firstName,
        lastName: teamMember.lastName,
        email: teamMember.email,
        team: teamMember.team,
        companyName: teamMember.companyName,
        bgColorIndex: teamMember.bgColorIndex,
        jobTitle: teamMember.jobTitle,
        parking: this.getActiveParking(parkingAssignments, booking.startDate),
        seating: this.getActiveSeating(deskAssignments, booking.startDate),
        reservedSeating: this.getReservedSeating(deskAssignments, booking.startDate),
        scheduleType: 'temporary',
        spaceType: booking.spaceType,
        booking
      };

      this.bookingCalendarEvents.addTeamMemberToEvent(this.getBaseDateString(booking.startDate), eventTeamMember, currentUser);
    });

    const events: Array<any> = [];

    this.bookingCalendarEvents
      .getAllEvents()
      .forEach((event: SingleEventModel) => {
        events.push({
          id: 'ev-' + event.date,
          date: event.date,
          extendedProps: event
        });
      });

    return events;
  }

  public removeBookingData(booking: Booking): Observable<any> {
    const removedBooking = {
      booking,
      action: RemoveBookingActions.DELETE
    };

    return this.httpClient.patch(
      `${environment.api.supportApi.url}/v1.0/Booking`, removedBooking);
  }

  public buildTeamMemberSchedule(teamMemberItem: OnsiteTeamMember, currentUser: OnsiteUser): any {
    const days = teamMemberItem.tmResponse.teamMemberSchedule.data;
    const bookings = teamMemberItem.tmResponse.bookings?.data || [];
    const teamMember = teamMemberItem.tmData;
    const parkingAssignments = teamMemberItem.tmResponse.parkingAssignment;
    const deskAssignments = teamMemberItem.tmResponse.userSpace;

    days.forEach(day => {
      const eventTeamMember: IEventTeamMember = {
        commonId: teamMember.commonId,
        firstName: teamMember.firstName,
        lastName: teamMember.lastName,
        email: teamMember.email,
        team: teamMember.team,
        companyName: teamMember.companyName,
        bgColorIndex: teamMember.bgColorIndex,
        jobTitle: teamMember.jobTitle,
        parking: this.getActiveParking(parkingAssignments, day),
        seating: this.getActiveSeating(deskAssignments, day),
        reservedSeating: this.getReservedSeating(deskAssignments, day),
        scheduleType: 'recurring',
        spaceType: 'other'
      };

      this.calendarEvents.addTeamMemberToEvent(day, eventTeamMember, currentUser);
    });

    const filteredBookings = bookings.filter(
      (booking) => !days.some((day) => this.getBaseDateString(day) === this.getBaseDateString(booking.startDate))
    );

    filteredBookings.forEach(booking => {
      const eventTeamMember: IEventTeamMember = {
        commonId: teamMember.commonId,
        firstName: teamMember.firstName,
        lastName: teamMember.lastName,
        email: teamMember.email,
        team: teamMember.team,
        companyName: teamMember.companyName,
        bgColorIndex: teamMember.bgColorIndex,
        jobTitle: teamMember.jobTitle,
        parking: this.getActiveParking(parkingAssignments, booking.startDate),
        seating: this.getActiveSeating(deskAssignments, booking.startDate),
        reservedSeating: this.getReservedSeating(deskAssignments, booking.startDate),
        scheduleType: 'temporary',
        spaceType: booking.spaceType,
        booking
      };

      this.calendarEvents.addTeamMemberToEvent(this.getBaseDateString(booking.startDate), eventTeamMember, currentUser);
    });

    const events: Array<any> = [];

    this.calendarEvents
      .getAllEvents()
      .forEach((event: SingleEventModel) => {
        events.push({
          id: 'ev-' + event.date,
          date: event.date,
          extendedProps: event
        });
      });

    return events;
  }

  public getActiveParking(
    parking: ServiceResult<ParkingAssignment[] | null>,
    eventDate: string,
  ): any {
    const defaultResponse = {
      isAssigned: false,
      label: 'No assigned parking.',
      url: '',
    };

    if (parking.hasError || !parking.data) {
      return defaultResponse;
    }

    const assignments = _.orderBy(parking.data, item => item.effectiveDate ?? '', ['desc']);
    const today = this.getBaseDate(eventDate);
    const matches: Array<object> = [];

    assignments.forEach((value: ParkingAssignment) => {
      let effectiveDate;

      if (!value.effectiveDate) {
        effectiveDate = this.getBaseDate(MinDate);
      } else {
        effectiveDate = this.getBaseDate(value.effectiveDate);
      }

      if (effectiveDate <= today) {
        matches.push({
          isAssigned: true,
          label: value.garageFriendlyName ?? '',
          url: '',
        });
      }
    });

    if (matches.length) {
      return matches[0];
    }

    return defaultResponse;
  }

  public getActiveSeating(
    seating: ServiceResult<UserSpace | null>,
    eventDate: string
  ): any {
    const defaultResponse = {
      isAssigned: false,
      label: 'No assigned seating.',
      url: '',
    };

    if (seating.hasError || !seating.data) {
      return defaultResponse;
    }
    const assignments = _.orderBy(seating.data.userPermanentSpace, item => item.dateAssigned ?? '', ['desc']);
    const today = this.getBaseDate(eventDate);
    const matches: Array<object> = [];

    assignments.forEach((value: UserPermanentSpace) => {
      let dateAssigned;

      if (!value.dateAssigned) {
        dateAssigned = this.getBaseDate(MinDate);
      } else {
        dateAssigned = this.getBaseDate(value.dateAssigned);
      }

      if (dateAssigned <= today) {
        matches.push({
          isAssigned: true,
          label: `${value.building.name} - ${value.room.name}`,
          url: value.pingLink ?? '',
        });
      }
    });

    if (matches.length) {
      return matches[0];
    }

    return defaultResponse;
  }

  public getReservedSeating(
    seating: ServiceResult<UserSpace | null>,
    eventDate: string
  ): any {
    const defaultResponse = {
      isAssigned: false,
      label: 'No assigned seating.',
      url: '',
    };

    if (seating.hasError || !seating.data) {
      return defaultResponse;
    }

    const today = this.getBaseDate(eventDate);
    const matches = seating.data.userReservationSpace?.filter((space) => {
      if (!space.startDate || !space.endDate) {
        console.warn('Invalid reservation provided. Skipping.');
        return false;
      }

      const startDate = this.getBaseDate(space.startDate);
      const endDate = this.getBaseDate(space.endDate);

      return today >= startDate && today <= endDate;
    }) || [];

    const sortedMatches = _.orderBy(matches, item => item.dateUpdated ?? item.dateCreated, ['desc']);

    if (sortedMatches.length) {
      const reservation = sortedMatches[0];
      return {
        isAssigned: true,
        label: `${reservation.building.name} - ${reservation.room.name}`,
        url: reservation.pingLink ?? '',
      };
    }

    return defaultResponse;
  }

  public changeOrEndSchedule(request: IScheduleRequest): Promise<IScheduleRequestResponse> {
    request.metadata.timeZone = this.scheduleUtilities.determineTimeZone();

    return this.httpClient.post<IScheduleRequestResponse>(environment.api.scheduleApi.url + '/v1/requests', request).toPromise();
  }

  public selectTeamMember(teamMemberSearchOption: TeamMemberSearchOption): void {
    this.selectedTeamMember = teamMemberSearchOption;
  }

  public unselectTeamMember(): void {
    this.selectedTeamMember = undefined;
  }

  private getBaseDateString(date: string): string {
    return date.split('T')[0];
  }
}
