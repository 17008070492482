<h2>{{title}}</h2>

<div *ngIf="!isTicketAssignedToMe && !isTicketAssignedToSomeoneElse" class="claim-request-card container">
    <div class="claim-request-icon"><i class="fas fa-user-clock"></i></div>
    <div class="claim-request-label"><span>This request needs to be effective by
            <strong>{{formattedEffectiveDate}}</strong>.</span></div>
    <button mat-button class="assign-to-me-button primary" [disabled]="isAssignToMeButtonDisabled" (click)="assignTicketToMe()">
        <span class="icon"><i class="fas fa-clipboard-list assign-to-me-button__icon"></i> Assign To Me</span>
    </button>
</div>

<div *ngIf="!isTicketAssignedToMe && isTicketAssignedToSomeoneElse" class="claim-request-card container">
    <div class="request-claimed-icon"><i class="fas fa-hand-paper"></i></div>
    <div class="request-claimed-label"><span>This request has already been assigned to
            <strong>{{assignedTo?.firstName}} {{assignedTo?.lastName}}</strong></span></div>
    <button mat-button class="assign-to-me-instead-button" [disabled]="isAssignToMeInsteadButtonDisabled" (click)="assignTicketToMeInstead()">
        <span class="icon"><i class="fas fa-clipboard-list assign-to-me-instead-button__icon"></i> Assign To Me Instead</span>
    </button>
</div>

<div *ngIf="isTicketAssignedToMe" class="claim-request-card container" flexContainer>
    <span>
        After reviewing the request details, please add a comment to explain the action you are taking before
        approving or denying the request. If approving, please include details about any workspace or parking
        assignment changes.
    </span>
    <div class="badge-text-area-row" fxLayout="row wrap" fxFlex="auto">
        <div *ngIf="teamMemberBadge" class="tm-badge-icon col-2" fxLayoutAlign="start start">
            <app-tm-badge [teamMember]="teamMemberBadge" [noTooltip]="true" [size]="'md'"></app-tm-badge>
        </div>
        <div fxFlex>
            <div fxLayout=column>
                <mat-form-field appearance="outline" class="claim-request__text-area" hideRequiredMarker>
                    <mat-label class="text-label"
                        [ngClass]="{'validation-message-label' : validatedAdminComment?.invalid && (validatedAdminComment?.dirty || validatedAdminComment?.touched)}">
                        Reason for decision
                    </mat-label>
                    <textarea class="form-control" id="adminComment" name="adminComment" matInput required
                        [maxLength]="500" placeholder="Ex. This request was approved / denied because..."
                        [(ngModel)]="adminComment" #validatedAdminComment="ngModel">
                    </textarea>
                </mat-form-field>
                <div fxLayout="row" fxLayout.xs="column" class="validation-message-character-count-row container">
                    <div *ngIf="validatedAdminComment?.invalid && (validatedAdminComment?.dirty || validatedAdminComment?.touched)"
                        class="admin-comment-validation-message child-2" fxLayoutAlign="start center" fxFlex>
                        <div *ngIf="validatedAdminComment?.errors?.required">
                            Reason for decision is required!
                        </div>
                    </div>
                    <div class="admin-comment-character-count child-1" fxLayoutAlign="end center" fxFlex>Character
                        Count:
                        {{adminComment.length}} / 500
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="approve-deny-button-row approve-deny-button-column" fxLayout="row" fxLayout.xs="column">
        <button mat-button *ngIf="validatedAdminComment?.invalid || isApproveDenyButtonsDisabled; else denyButton"
            class="secondary" disabled="disabled">
            <span class="icon"><i class="fas fa-ban deny-ticket-button__icon"></i>Deny</span>
        </button>
        <ng-template #denyButton>
            <button mat-button class="secondary" (click)="denyTicket()">
                <span class="icon"><i class="fas fa-ban deny-ticket-button__icon"></i>Deny</span>
            </button>
        </ng-template>
        <button mat-button *ngIf="validatedAdminComment?.invalid || isApproveDenyButtonsDisabled; else approveButton"
            class="primary" disabled="disabled">
            <span class="icon"><i class="fas fa-clipboard-check approve-ticket-button__icon"></i>Approve</span>
            
        </button>
        <ng-template #approveButton>
            <button mat-button class="primary" (click)="approveTicket()">
                <span class="icon"><i class="fas fa-clipboard-check approve-ticket-button__icon"></i>Approve</span>
            </button>
        </ng-template>
    </div>
</div>
