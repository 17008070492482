export enum ScreenSize {
    small = 1,
    medium = 2,
    large = 3,
    xl = 4
}

export enum ScheduleStatusRequest {
    '120:PENDINGWORKSPACE' = 'Pending Workspace',
    '130:PENDINGPARKING' = 'Pending Parking',
    '900:COMPLETED' = 'Completed',
    '910:DENIED' = 'Denied',
    '920:SEPARATED' = 'Separated'
}

export enum RequestStatus {
    'Completed' = 'completed',
    'Pending Parking' = 'pending-parking',
    'Pending Workspace' = 'pending-workspace',
    'Denied' = 'denied',
    'Separated' = 'separated'
}

export enum LabelEnums {
    ComplexReasonToolTip = `Please provide details on why this team member's schedule is changing and if there are workspace or parking considerations that the support teams should be aware of as they fulfill the change request.`,
    StartDateToolTip = `Please note that the earliest schedule start date possible is one week from today`,
    WeekdaysToolTip = `Please select the days of the week this team member will be required to work onsite. You are able to make up to a two week schedule, where the team member can come in on different days each week (ex. M, W on first week and M, W, F on second week).`,
    RemoveNewScheduleToolTip = 'Remove New Schedule',
    RemoveFutureScheduleTooltip = 'Remove Upcoming Schedule',
    RemoveWeekToolTip = 'Remove Week',
    NonIOfficeCompanies = `Core Digital Media, Detroit Labs LLC, Dictionary.com, Edison Financial ULC, Lendesk Technologies ULC, RHI Opportunities, Rock Holdings Inc., Cavaliers Operating Company LLC, Gilbert Family Foundation, Grand Circus Detroit LLC, Pophouse LLC, ProSites, Rapid Financial Services LLC, Rock Executive Security LLC, Rockbridge Growth Equity Management LP, Rocket Giving Fund, Shinola Detroit, Xenith LLC, ZUP Household Servies LLC`
}
