import { Component, EventEmitter, HostListener, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleChangeRequestTicket } from 'src/app/models/schedule-change-request-ticket';
import { ScheduleChangeRequestTicketDetails } from 'src/app/models/schedule-change-request-ticket-details';
import { RequestStatus, ScheduleStatusRequest, ScreenSize } from 'src/app/utils/enums';
import { ChangeRequestTeamMember } from 'src/app/models/change-request-team-member';
import { TeamMemberBadge } from 'src/app/models/team-member-badge';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import { catchError } from 'rxjs/operators';
import { ScheduleChangeRequestTicketClaim } from 'src/app/models/schedule-change-request-ticket-claim';
import { AdminDashboardService } from 'src/app/services/admin-dashboard/admin-dashboard.service';
import { DateTime } from 'luxon';
import { KeyValuePair } from 'src/app/models/key-value-pair';
import { ScheduleChangeRequestTicketAction } from 'src/app/models/schedule-change-request-ticket-action';
import { DeviceSettings } from 'src/app/utils/device-settings';
import { ScheduleChangeRequestHistory } from 'src/app/models/schedule-change-request-history';
import { RequestMetadata } from 'src/app/models/request-metadata';
import { ITeamLeader } from 'src/app/models/team-leader';
import { RequestedScheduleData } from 'src/app/models/requested-schedule-data';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { combineLatest, of } from 'rxjs';

@Component({
  selector: 'app-schedule-request-ticket',
  templateUrl: './schedule-request-ticket.component.html',
  styleUrls: ['./schedule-request-ticket.component.scss']
})
export class ScheduleRequestTicketComponent {
  public onAssignTicket = new EventEmitter<KeyValuePair>();
  public onApproveOrDeny = new EventEmitter();

  public request!: ScheduleChangeRequestTicket;
  public requestTicketDetails!: ScheduleChangeRequestTicketDetails;
  public requestMetadata!: any;
  public screenSize: ScreenSize;
  public isRequesterTeamLeader = false;
  public isApiError = false;

  private scheduleHolder: ChangeRequestTeamMember;
  private teamLeader: ITeamLeader | undefined;
  private requester: ChangeRequestTeamMember;
  private isParkingAdmin!: boolean;
  private isWorkspaceAdmin!: boolean;
  private currentUser!: OnsiteUser;

  private readonly initialScheduleSaveType = 'NoAction';
  private readonly failedApiResponseMessage = 'FAILED_RESPONSE';

  constructor(
    @Optional() @Inject(MatDialogRef) public dialogRef: MatDialogRef<ScheduleRequestTicketComponent>,
    @Inject(MAT_DIALOG_DATA) public ticket: ScheduleChangeRequestTicket,
    @Inject('OnsiteAuthService') private onsiteAuthService: IOnsiteAuthService,
    private adminDashboardService: AdminDashboardService
  ) {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);

    this.scheduleHolder = this.ticket.metadata.scheduleHolder;
    this.requester = this.ticket.metadata.requester;

    if (this.ticket.metadata.scheduleHolder.teamMemberJobs.length) {
      this.teamLeader = this.ticket.metadata.scheduleHolder.teamMemberJobs[0].teamLeader;

      if (this.teamLeader && this.requester) {
        this.isRequesterTeamLeader = this.teamLeader.commonId === this.requester.commonId;
      }
    }

    this.setCurrentUserData();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  public get requestedSchedule(): RequestedScheduleData {
    return {
      schedules: this.ticket.schedules
        ?.filter((s) => (s.saveType && s.saveType !== this.initialScheduleSaveType) || !s.saveType) || [],
      initialSchedule: this.ticket.schedules
        ?.filter((s) => (s.saveType && s.saveType === this.initialScheduleSaveType)) || []
    };
  }

  public get details(): ScheduleChangeRequestTicketDetails {
    return {
      teamMember: this.scheduleHolder,
      teamLeader: this.teamLeader,
      requester: this.requester,
      teamMemberBadge: this.getTeamMemberBadge(this.scheduleHolder.firstName, this.scheduleHolder.lastName),
      teamLeaderBadge: this.getTeamMemberBadge(this.teamLeader?.firstName ?? '', this.teamLeader?.lastName ?? ''),
      requesterBadge: this.getTeamMemberBadge(this.requester?.firstName ?? '', this.requester?.lastName ?? ''),
      currentUserId: this.currentUser.commonId
    };
  }

  public get claimRequest(): ScheduleChangeRequestTicketClaim {
    return {
      effectiveDate: this.ticket.metadata.effectiveDate,
      assignedTo: this.ticket.metadata.assignedTo,
      requestStatus: this.ticket.metadata.requestStatus
    };
  }

  public get history(): ScheduleChangeRequestHistory {
    return {
      actions: this.ticket.actions,
      requestDate: this.ticket.metadata.createDate,
      requestReason: this.ticket.metadata.reason,
      requester: this.ticket.metadata.requester,
      assignedToId: this.ticket.metadata.assignedToId
    };
  }

  public assignTicket(teamMember: OnsiteUser): void {
    this.isApiError = false;

    this.adminDashboardService.assignTicket(this.ticket.metadata.requestId, teamMember.commonId)
      .pipe(catchError(() => this.failedApiResponseMessage))
      .subscribe((res) => {
        if (res === this.failedApiResponseMessage) {
          this.isApiError = true;
        } else {
          this.ticket.metadata.assignedToId = teamMember.commonId;
          this.ticket.metadata.assignedTo = {
            commonId: teamMember.commonId,
            firstName: teamMember.firstName,
            lastName: teamMember.lastName,
            rockHumanId: '',
            teamMemberJobs: [],
            parking: '',
            workSpace: ''
          };
          this.updateAssignTicketOnTableComponent(this.ticket.metadata.requestId, teamMember);
          this.isApiError = false;
        }
      });
  }

  public approveTicket(kvp: KeyValuePair): void {
    this.isApiError = false;

    const teamMember: OnsiteUser = JSON.parse(kvp.key);
    const adminComment = kvp.value;

    this.adminDashboardService.approveTicket(this.ticket.metadata, adminComment)
      .pipe(catchError(() => of(this.failedApiResponseMessage)))
      .subscribe((res) => {
        if (res === this.failedApiResponseMessage) {
          this.isApiError = true;
        } else {
          this.isApiError = false;
          this.ticket.metadata.assignedTo = undefined;
          this.ticket.metadata.assignedToId = undefined;

          this.updateApproveOrDenyOnTableComponent();
          this.createTicketAction(this.ticket.metadata, teamMember, 'Approved', adminComment, this.ticket.metadata.requestStatus);
          this.updateTicketStatusForApproval();
        }
      });
  }

  public denyTicket(kvp: KeyValuePair): void {
    this.isApiError = false;

    const teamMember: OnsiteUser = JSON.parse(kvp.key);
    const adminComment = kvp.value;

    this.adminDashboardService.denyTicket(this.ticket.metadata, adminComment)
      .pipe(catchError(() => of(this.failedApiResponseMessage)))
      .subscribe((res) => {
        if (res === this.failedApiResponseMessage) {
          this.isApiError = true;
        } else {
          this.isApiError = false;
          this.ticket.metadata.requestStatus = ScheduleStatusRequest['910:DENIED'];
          this.ticket.metadata.assignedTo = undefined;
          this.ticket.metadata.assignedToId = undefined;

          this.updateApproveOrDenyOnTableComponent();
          this.createTicketAction(this.ticket.metadata, teamMember, 'Denied', adminComment, this.ticket.metadata.requestStatus);
        }
      });
  }

  public canShowClaimRequest(): boolean {
    switch (this.ticket.metadata.requestStatus) {
      case ScheduleStatusRequest['120:PENDINGWORKSPACE']: {
        return this.isWorkspaceAdmin;
      } case ScheduleStatusRequest['130:PENDINGPARKING']: {
        return this.isParkingAdmin;
      } default: {
        return false;
      }
    }
  }

  public get ticketTitle(): string {
    if (this.ticket.metadata.assignedTo?.commonId) {
      return `${this.ticket.metadata.requestStatus} with ${this.ticket.metadata.assignedTo.firstName} ${this.ticket.metadata.assignedTo.lastName}`;
    } else {
      return this.ticket.metadata.requestStatus;
    }
  }

  public get showTwoColumns(): boolean {
    return this.screenSize > ScreenSize.small;
  }

  public get showOneColumn(): boolean {
    return this.screenSize <= ScreenSize.small;
  }

  private setCurrentUserData(): void {
    combineLatest([
      this.onsiteAuthService.hasParkingAdminAccess$(),
      this.onsiteAuthService.hasWorkspaceAdminAccess$(),
      this.onsiteAuthService.getUser$()
    ])
      .subscribe(([isParkingAdmin, isWorkspaceAdmin, currentUser]) => {
        this.isParkingAdmin = isParkingAdmin;
        this.isWorkspaceAdmin = isWorkspaceAdmin;
        this.currentUser = currentUser;
      });
  }

  private getEnumKey(requestStatus: string): keyof typeof RequestStatus {
    return requestStatus as keyof typeof RequestStatus;
  }

  private getTeamMemberBadge(firstName: string, lastName: string): TeamMemberBadge {
    return { firstName, lastName };
  }

  private updateAssignTicketOnTableComponent(requestId: string, teamMember: OnsiteUser): void {
    this.onAssignTicket.emit({ key: requestId, value: JSON.stringify(teamMember) });
  }

  private updateApproveOrDenyOnTableComponent(): void {
    this.onApproveOrDeny.emit();
  }

  private createTicketAction(
    metadata: RequestMetadata,
    teamMember: OnsiteUser,
    actionType: string,
    reason: string,
    currentStatus: string): void {
    const requestStatusEnumKeys = Object.keys(ScheduleStatusRequest);
    const requestStatusEnumValues: string[] = Object.values(ScheduleStatusRequest);

    const requestStatusEnum = requestStatusEnumKeys[requestStatusEnumValues.indexOf(currentStatus)];

    const action: ScheduleChangeRequestTicketAction = {
      createdBy: metadata.createdBy,
      createDate: DateTime.now().toUTC().toString(),
      lastUpdatedBy: metadata.lastUpdatedBy ?? '',
      lastUpdateDate: metadata.lastUpdateDate,
      isActive: metadata.isActive,
      actionId: metadata.requestId + DateTime.now().toUTC().toString(),
      actionType,
      reason,
      requestStatusAtTimeOfAction: requestStatusEnum,
      actor: {
        commonId: teamMember.commonId,
        firstName: teamMember.firstName,
        lastName: teamMember.lastName,
        rockHumanId: '',
        status: metadata.requestStatus,
        statusSubtype: '',
        teamMemberJobs: []
      },
      actionDate: DateTime.now().toString()
    };

    this.ticket.actions.unshift(action);
  }

  private updateTicketStatusForApproval(): void {
    if (this.ticket.metadata.requestStatus === ScheduleStatusRequest['910:DENIED']
      || this.ticket.metadata.requestStatus === ScheduleStatusRequest['900:COMPLETED']
      || this.ticket.metadata.requestStatus === ScheduleStatusRequest['920:SEPARATED']) {
        // todo consider showing this as an error to the user
      console.error('Cannot approve a ticket that is already completed or denied');
    } else if (this.ticket.metadata.requestStatus === ScheduleStatusRequest['120:PENDINGWORKSPACE']) {
      this.ticket.metadata.requestStatus = ScheduleStatusRequest['130:PENDINGPARKING'];
    } else if (this.ticket.metadata.requestStatus === ScheduleStatusRequest['130:PENDINGPARKING']) {
      this.ticket.metadata.requestStatus = ScheduleStatusRequest['900:COMPLETED'];
    }
  }
}
