<div class="schedule-request-ticket">
    <mat-header>
        <div class="title-container">
            <div class="title-date h1">{{ticketTitle}}</div>
            <div class="dialog-close" [mat-dialog-close]="true"><i class="fa-regular fa-xmark"></i></div>
        </div>
    </mat-header>
    <div class="admin-dashboard-error" *ngIf="isApiError">
        <app-callout type="error" title="Error">
            Service is temporarily unavailable. Please try again later.
        </app-callout>
    </div>
    <mat-dialog-content class="dialog-content-override">
        <div *ngIf="showTwoColumns" class="ticket-components">
            <div class="ticket-components__column">
                <div class="padding-bottom">
                    <app-requested-schedule [scheduleData]="requestedSchedule"
                        [teamMemberId]="ticket.metadata.scheduleHolder.commonId"></app-requested-schedule>
                </div>
                <div class="padding-top">
                    <app-request-details [requestTicketDetails]="details"></app-request-details>
                </div>
            </div>
            <div class="ticket-components__column">
                <div class="padding-bottom" *ngIf="canShowClaimRequest()">
                    <app-claim-request [data]="claimRequest" (assignedToTeamMemberId)="assignTicket($event)"
                        (deniedReason)="denyTicket($event)" (approvedReason)="approveTicket($event)">
                    </app-claim-request>
                </div>
                <div [ngClass]="canShowClaimRequest() ? 'padding-top' : ''">
                    <app-request-history [historyData]="history"></app-request-history>
                </div>
            </div>
        </div>
        <div *ngIf="showOneColumn" class="ticket-components">
            <div class="ticket-components__column">
                <div class="padding-bottom">
                    <app-requested-schedule [scheduleData]="requestedSchedule"
                        [teamMemberId]="ticket.metadata.scheduleHolder.commonId"></app-requested-schedule>
                </div>
                <div class="padding-top padding-bottom">
                    <app-request-details [requestTicketDetails]="details"></app-request-details>
                </div>
                <div *ngIf="canShowClaimRequest()" class="padding-top padding-bottom">
                    <app-claim-request [data]="claimRequest" (assignedToTeamMemberId)="assignTicket($event)"
                        (deniedReason)="denyTicket($event)" (approvedReason)="approveTicket($event)">
                    </app-claim-request>
                </div>
                <div class="padding-top">
                    <app-request-history [historyData]="history"></app-request-history>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
