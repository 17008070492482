import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ScheduleChangeRequestTicketDetails } from 'src/app/models/schedule-change-request-ticket-details';
import { MatTooltip } from '@angular/material/tooltip';
import { DeviceSettings } from 'src/app/utils/device-settings';
import { ScreenSize } from 'src/app/utils/enums';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss'],
})
export class RequestDetailsComponent implements OnInit {
  @Input() requestTicketDetails!: ScheduleChangeRequestTicketDetails;

  public showTmCommonId = false;
  public isRequesterTeamLeader = false;

  public screenSize: ScreenSize;
  private noWorkspaceAssignmentForSelfMessage = 'You do not currently have a workspace assignment.';
  private noParkingAssignmentForSelfMessage = 'You do not currently have a parking assignment.';
  private noTeamMemberWorkspaceAssignmentMessage = 'Team Member does not currently have a workspace assignment.';
  private noTeamMemberParkingAssignmentMessage = 'Team Member does not currently have a parking assignment.';
  private noTeamLeaderWorkspaceAssignmentMessage = 'Team Leader does not currently have a workspace assignment.';
  private noRequesterWorkspaceAssignmentMessage = 'Requester does not currently have a workspace assignment.';

  constructor() {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  ngOnInit(): void {
    if (this.requestTicketDetails.teamLeader && this.requestTicketDetails.requester) {
      this.isRequesterTeamLeader = this.requestTicketDetails.teamLeader.commonId === this.requestTicketDetails.requester.commonId;
    }
  }

  public get showMobileLayout(): boolean {
    return this.screenSize <= ScreenSize.medium;
  }

  public getTeamMemberWorkspace(): string {
    if (this.requestTicketDetails.teamMember.commonId !== this.requestTicketDetails.currentUserId
      && this.requestTicketDetails.teamMember.workSpace === this.noWorkspaceAssignmentForSelfMessage) {
      return this.noTeamMemberWorkspaceAssignmentMessage;
    }
    return this.requestTicketDetails.teamMember.workSpace;
  }


  public getTeamMemberParking(): string | undefined {
    if (this.requestTicketDetails.teamMember.commonId !== this.requestTicketDetails.currentUserId
      && this.requestTicketDetails.teamMember.parking === this.noParkingAssignmentForSelfMessage) {
      return this.noTeamMemberParkingAssignmentMessage;
    }
    return this.requestTicketDetails.teamMember.parking;
  }

  public getTeamLeadersWorkspace(): string | undefined {
    if (this.requestTicketDetails.teamLeader?.commonId !== this.requestTicketDetails.currentUserId
      && this.requestTicketDetails.teamLeader?.workSpace === this.noWorkspaceAssignmentForSelfMessage) {
      return this.noTeamLeaderWorkspaceAssignmentMessage;
    }
    return this.requestTicketDetails.teamLeader?.workSpace;
  }

  public getRequestersWorkspace(): string | undefined {
    if (this.requestTicketDetails.requester?.commonId !== this.requestTicketDetails.currentUserId
      && this.requestTicketDetails.requester?.workSpace === this.noWorkspaceAssignmentForSelfMessage) {
      return this.noRequesterWorkspaceAssignmentMessage;
    }
    return this.requestTicketDetails.requester?.workSpace;
  }

  public get hasTeamLeader(): boolean {
    return this.requestTicketDetails.teamLeader !== null && this.requestTicketDetails.teamLeader !== undefined;
  }

  public get teamLeadersCompany(): string {
    return this.requestTicketDetails.teamLeader && this.requestTicketDetails.teamLeader.teamMemberJobs.length
      ? this.requestTicketDetails.teamLeader.teamMemberJobs[0].company.displayName
      : '';
  }

  public get teamLeadersBizArea(): string {
    return this.requestTicketDetails.teamLeader && this.requestTicketDetails.teamLeader.teamMemberJobs.length
      ? this.requestTicketDetails.teamLeader.teamMemberJobs[0].businessArea
      : '';
  }

  public get requestersCompany(): string {
    return this.requestTicketDetails.requester && this.requestTicketDetails.requester.teamMemberJobs.length
      ? this.requestTicketDetails.requester.teamMemberJobs[0].company.displayName
      : '';
  }

  public get requestersBizArea(): string {
    return this.requestTicketDetails.requester && this.requestTicketDetails.requester.teamMemberJobs.length
      ? this.requestTicketDetails.requester.teamMemberJobs[0].businessArea
      : '';
  }

  public get teamLeaderEmail(): string {
    return this.requestTicketDetails.teamLeader?.teamMemberJobs && this.requestTicketDetails.teamLeader?.teamMemberJobs.length ?
      this.requestTicketDetails.teamLeader?.teamMemberJobs[0].email : '';
  }

  public get requestersEmail(): string {
    return this.requestTicketDetails.requester?.teamMemberJobs && this.requestTicketDetails.requester?.teamMemberJobs.length ?
      this.requestTicketDetails.requester.teamMemberJobs[0].email :
      '';
  }

  public toggleCommonIdShow(mouseEvent: MouseEvent): void {
    this.showTmCommonId = !this.showTmCommonId;
    mouseEvent.stopPropagation();
  }

  public getRequesterIsLeaderClass(): string {
    return this.isRequesterTeamLeader ? 'is-leader' : 'not-leader';
  }

  public showCopiedToolTip(tooltip: MatTooltip): void {
    tooltip.message = 'Copied!';
    tooltip.show();
  }
}
