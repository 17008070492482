<div class="temp-booking-modal">
  <div class="title-container">
    <div class="title-date h1">Schedule Temporary Onsite Visit</div>
    <div class="dialog-close" [mat-dialog-close]="true"><i class="fa-regular fa-xmark"></i></div>
  </div>

  <mat-dialog-content>
    <div class="team-member-row">
      <div class="badge-col temporary">
        <div *ngFor="let selectedDate of selectedDates">
          <app-date-preview [selectedDate]="selectedDate"></app-date-preview>
        </div>
      </div>
      <div class="booking-calendar-wrapper">
        <div class="title-label h2" id="select-up-to-five-days">Select up to 5 Days </div>
        <div class="calendar-col">
          <div *ngIf="apiError">
            <app-callout type="error" title="Error">
              Service is temporarily unavailable. Please try again later.
            </app-callout>
          </div>
          <div class="booking-calendar">
            <div class="calendar-title"></div>
            <app-loading [showLoader]="isCalendarLoading"></app-loading>
            <full-calendar #bookingCalendar [options]="calendarOptions"></full-calendar>
          </div>
        </div>
        <div class="continue">
          <button class="button" id="submit-booking-button" [disabled]="selectedDates.length === 0" (click)="next()"
            mat-button>{{buttonText}}</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
