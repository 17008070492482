import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamMemberSearchOption } from 'src/app/models/team-member';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { environment } from 'src/environments/environment';
import { searchConfig } from '../search/search.config';
import { HttpClient } from '@angular/common/http';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import { combineLatest } from 'rxjs';
import { ScheduleChangeRequestApiResponse } from 'src/app/models/schedule-change-request-api-response';

@Component({
  selector: 'app-request-schedule-change-search',
  templateUrl: './request-schedule-change-search.component.html',
  styleUrls: ['./request-schedule-change-search.component.scss']
})
export class RequestScheduleChangeSearchComponent implements OnInit {
  public searchToolTip = searchConfig.toolTip;
  public hasReceivedData = false;
  public hasBeenSelected = false;
  public requestInProcess = true;
  public isSameUser = false;
  public currentUserCommonId = '';
  public hasAccess = false;

  constructor(
    @Inject('SchedulerService') private schedulerService: SchedulerService,
    @Inject('HttpClient') private httpClient: HttpClient,
    @Inject('OnsiteAuthService') private onsiteAuthService: IOnsiteAuthService,
    private router: Router) {
    this.onsiteAuthService.getUser$().subscribe((user) => {
      if (user) {
        this.currentUserCommonId = user.commonId;
      }
    });
  }

  ngOnInit(): void {
    this.unselectTeamMember();

    combineLatest([
      this.onsiteAuthService.hasScheduleAdminAccess$(),
      this.onsiteAuthService.getUser$()
    ]).subscribe(
      ([scheduleAdminAccess, user]) => {
        this.hasAccess = scheduleAdminAccess || JSON.parse(environment.teamLeaderFeatureFlag) && user.isEffectivelyLeader;
      });
  }

  public nextClick(): void {
    if (this.complexFeatureFlag) {
      this.router.navigateByUrl('/request-schedule-change-complex', { skipLocationChange: true });
    } else {
      this.router.navigateByUrl('/request-schedule-change', { skipLocationChange: false });
    }
  }

  public async onTeamMemberSelected(selectedTeamMember: TeamMemberSearchOption): Promise<void> {
    this.schedulerService.selectTeamMember(selectedTeamMember);
    this.hasBeenSelected = true;
    const commonId = this.schedulerService.selectedTeamMember?.teamMember.commonId;
    if (commonId === this.currentUserCommonId) {
      this.isSameUser = true;
    }

    this.requestInProcess = await this.isScheduleRequestInProcess(commonId);
    this.hasReceivedData = true;
  }

  public unselectTeamMember(): void {
    this.schedulerService.unselectTeamMember();
    this.requestInProcess = true;
    this.hasReceivedData = false;
    this.hasBeenSelected = false;
    this.isSameUser = false;
  }

  public get selectedTeamMember(): TeamMemberSearchOption | undefined {
    return this.schedulerService.selectedTeamMember;
  }

  public get isNextButtonDisabled(): boolean {
    return this.complexFeatureFlag ? (!this.selectedTeamMember || this.requestInProcess || this.isSameUser) : !this.selectedTeamMember;
  }

  public isLoading(): boolean {
    return this.hasBeenSelected && !this.hasReceivedData;
  }

  public get isTeamMemberSearchVisible(): boolean {
    return !this.selectedTeamMember;
  }

  public get complexFeatureFlag(): boolean {
    return environment.complexFeatureFlag === 'true';
  }

  public async isScheduleRequestInProcess(commonId: string | undefined): Promise<boolean> {
    let inProcess = false;

    if (commonId === undefined) {
      inProcess = true;
      return inProcess;
    }

    try {
      const schedules = await this.getTmRequestData(commonId);
      for (const scheduleRequest of schedules.metadata) {
        if (scheduleRequest.requestStatus !== 'Completed'
          && scheduleRequest.requestStatus !== 'Denied'
          && scheduleRequest.requestStatus !== 'Separated') {
          inProcess = true;
        }
      }
    }
    catch {
      inProcess = true;
    }
    return inProcess;
  }

  private async getTmRequestData(commonId: string): Promise<ScheduleChangeRequestApiResponse> {
    return this.httpClient.get<ScheduleChangeRequestApiResponse>(
      `${environment.api.scheduleApi.url}/v1/requests/metadata?sort=effectiveDate:desc&filter=scheduleHolderId:${commonId}&limit=200`
    ).toPromise();
  }
}
