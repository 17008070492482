import { Inject, Injectable } from '@angular/core';
import { ISchedule, IScheduleRequest, IScheduleRequestRecurrencePattern, ScheduleRequestType, ScheduleSaveType } from 'src/app/models/scheduleRequest';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { SchedulerService } from '../scheduler/scheduler.service';
import { indexOf } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class RequestScheduleChangeService {
  constructor(
    @Inject('SchedulerService') private schedulerService: SchedulerService
  ) { }

  public createScheduleRequest(
    scheduleData: IScheduleRequestRecurrencePattern[],
    scheduleForm: FormGroup,
    initialSchedule: Array<ISchedule>
  ): IScheduleRequest {

    scheduleData = scheduleData.concat(this.mapInitialScheduleData(initialSchedule));

    const scheduleRequest: IScheduleRequest = {
      metadata: {
        requestType: this.determineScheduleRequestType(scheduleData),
        effectiveDate: this.determineEffectiveDate(scheduleData),
        scheduleHolderId:
          this.schedulerService.selectedTeamMember?.commonId,
        reason: scheduleForm.get('message')?.value ?? ''
      },
      schedules: scheduleData,
    };

    return scheduleRequest;
  }

  public determineScheduleRequestType(
    scheduleData: IScheduleRequestRecurrencePattern[]
  ): ScheduleRequestType {
    const newScheduleChangePresent =
      _.filter(scheduleData, (schedule): boolean =>
        schedule.saveType === ScheduleSaveType.NewSchedule
      ).length > 0;
    const endDateChangePresent =
      _.filter(scheduleData, (schedule): boolean =>
        schedule.saveType === ScheduleSaveType.EndSchedule ||
        schedule.saveType === ScheduleSaveType.DeleteSchedule
      ).length > 0;

    return !newScheduleChangePresent && endDateChangePresent
      ? ScheduleRequestType.End
      : ScheduleRequestType.Update;
  }

  public determineEffectiveDate(scheduleData: IScheduleRequestRecurrencePattern[]): string {
    // effective date is the first to occur date value of your collection of start and end schedule requests
    // Note: schedule dates are assumed to be in ISO date format at this point
    const dates = [];
    for (const schedule of scheduleData) {
      const isAnInitialSchedule = schedule.saveType === ScheduleSaveType.NoAction;
      if (!isAnInitialSchedule) {
        if (schedule.scheduleStartDate) {
          dates.push(schedule.scheduleStartDate);
        }
        if (schedule.scheduleEndDate) {
          dates.push(schedule.scheduleEndDate);
        }
      }
    }

    dates.sort();

    return dates.length > 0 ? dates[0] : '';
  }

  public mapInitialScheduleData(initialSchedule: Array<ISchedule>): IScheduleRequestRecurrencePattern[] {
    const initialScheduleAsRecurrencePattern: IScheduleRequestRecurrencePattern[] = [];
    for (const schedule of initialSchedule) {

      const membersOfSameSchedule = _.filter(initialSchedule, (s) => {
        return schedule.sourceId === s.sourceId;
      });

      const scheduleOrderdByStartDate = _.orderBy(membersOfSameSchedule, ['scheduleStartDate'], ['asc']);

      initialScheduleAsRecurrencePattern.push({
        scheduleStartDate: schedule.scheduleStartDate,
        scheduleEndDate: schedule.scheduleEndDate,
        frequency: schedule.frequency,
        interval: schedule.interval,
        weekDays: schedule.weekDays,
        saveType: ScheduleSaveType.NoAction,
        isActive: true,
        sourceId: schedule.sourceId,
        order: scheduleOrderdByStartDate.indexOf(schedule) + 1
      });
    }

    return initialScheduleAsRecurrencePattern;
  }
}
