import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { IEventTeamMember } from '../../models/calendar-interfaces';
import { ITeamMember } from '../../models/team-member';
import { TeamMemberBadge } from '../../models/team-member-badge';

@Component({
  selector: 'app-tm-badge',
  templateUrl: './tm-badge.component.html',
  styleUrls: ['./tm-badge.component.scss']
})
export class TmBadgeComponent implements OnInit, OnChanges {

  public initials = '';
  public fullName = '';

  @Input() public teamMember: TeamMemberBadge|ITeamMember|IEventTeamMember|OnsiteUser = {
    firstName: 'Team',
    lastName: 'Member',
  };

  @Input() public size: 'sm' | 'md' | 'lg' | 'static-md' = 'sm';
  @Input() public bg: '1' | '2' | '3' | '4' | '5' | '6' = '1';
  @Input() public tooltipPosition: TooltipPosition = 'above';
  @Input() public noTooltip = false;
  @Input() public disabled = false;

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(): void {
    this.update();
  }

  private update(): void {
    this.initials = this.teamMember.firstName.charAt(0) + this.teamMember.lastName.charAt(0);
    this.fullName = this.teamMember.firstName + ' ' + this.teamMember.lastName;
  }

}
