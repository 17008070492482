import { Component, Inject, OnInit, AfterViewInit, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TeamMemberSearchOption } from 'src/app/models/team-member';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { ScheduleStatusRequest } from 'src/app/utils/enums';
import { ScheduleRequestTableComponent } from './schedule-request-table/schedule-request-table.component';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit, AfterViewInit {
  @Output() selectedTeamMemberSearch = new EventEmitter<TeamMemberSearchOption | undefined>();

  @ViewChild(ScheduleRequestTableComponent) scheduleRequestTable!: ScheduleRequestTableComponent;

  readonly titleLabelTeamMemberCardCss = 'admin-dashboard__title-with-teammember-card';
  readonly titleLabelSearchCss = 'admin-dashboard__title-with-search';

  public searchToolTip = 'Begin typing a name to search for their request(s)';
  public title = '';
  public titleLabel = this.titleLabelSearchCss;
  public searchLabel = '<i class="fas fa-user"></i><span class="tm-label">Find Team Member</span>';

  private status = '';

  constructor(@Inject('SchedulerService') private schedulerService: SchedulerService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.schedulerService.unselectTeamMember();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  public onTeamMemberSelected(selectedTeamMember: TeamMemberSearchOption): void {
    this.changeDetectorRef.detectChanges();

    this.title = `All Requests for ${selectedTeamMember.teamMember.firstName} ${selectedTeamMember.teamMember.lastName}`;
    this.titleLabel = this.titleLabelTeamMemberCardCss;

    this.schedulerService.selectTeamMember(selectedTeamMember);
    this.selectedTeamMemberSearch.emit(selectedTeamMember);

    this.changeDetectorRef.detectChanges();
  }

  public unselectTeamMember(): void {
    this.changeDetectorRef.detectChanges();

    this.title = this.getTitle();
    this.titleLabel = this.titleLabelSearchCss;

    this.schedulerService.unselectTeamMember();
    this.selectedTeamMemberSearch.emit(undefined);

    this.changeDetectorRef.detectChanges();
  }

  public changeStatus(selectedStatus: string): void {
    this.status = selectedStatus;
    this.title = this.getTitle();
  }

  public get isApiError(): Observable<boolean> {
    if (this.scheduleRequestTable === undefined) {
      return of(false);
    } else if (this.scheduleRequestTable.requestTicketDetailHasError || this.scheduleRequestTable.parkingAndWorkspaceHasError) {
      return of(true);
    }

    return this.scheduleRequestTable.dataSource.hasError$;
  }

  public get isLoading(): Observable<boolean> {
    if (this.scheduleRequestTable === undefined) {
      return of(false);
    } else if (this.scheduleRequestTable.isLoading) {
      return of(true);
    }

    return this.scheduleRequestTable.dataSource.loading$;
  }

  public get isTeamMemberSearchVisible(): boolean {
    return !this.selectedTeamMember;
  }

  public get selectedTeamMember(): TeamMemberSearchOption | undefined {
    return this.schedulerService.selectedTeamMember;
  }

  private getTitle(): string {
    let suffix = '';
    if (this.status === ScheduleStatusRequest['120:PENDINGWORKSPACE']
      || this.status === ScheduleStatusRequest['130:PENDINGPARKING']) {
      suffix = ' to Review';
    }

    return `All ${this.status} Requests${suffix}`;
  }
}
