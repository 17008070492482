import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Booking } from 'src/app/models/booking';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(@Inject('HttpClient') private httpClient: HttpClient) { }

  public saveBooking(booking: Booking): Observable<unknown> {
    return this.httpClient
      .post(`${environment.api.supportApi.url}/v1/Booking`, booking)
      .pipe(
        catchError((error: Response) => this.handleError(error, booking)),
        map(() => of())
      );
  }

  private handleError(error: Response, booking: Booking): Observable<any> {
    const message = `Error status code ${error.status} for ${booking.startDate}`;
    return throwError(message);
  }
}
