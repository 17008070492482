import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import { ITeamMember } from '../../models/team-member';
import { TeamMemberBadge } from '../../models/team-member-badge';

@Component({
  selector: 'app-style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss']
})
export class StyleGuideComponent implements OnInit {
  teamMember!: TeamMemberBadge;
  public mobileMenuOpened = false;

  public teamMember1: ITeamMember = {
    commonId: '1234',
    firstName: 'Mike',
    lastName: 'Malloy',
    email: 'jgwenworth@rockcentraldetroit.com',
    team: 'RC Wunderkind',
    companyName: '',
    jobTitle: ''
  };

  public teamMember2: TeamMemberBadge = {
    firstName: 'Testy',
    lastName: 'McTesterson',
  };

  public teamMember3: TeamMemberBadge = {
    firstName: 'Albert',
    lastName: 'Einstein',
  };

  public selectedDate = '2022-07-28T12:00:00Z';

  public get mobileMenuIcon(): string {
    return !this.mobileMenuOpened ? '<i class="fa-solid fa-bars"></i>' : '<i class="fa-regular fa-xmark"></i>';
  }

  public toggleMenu(): void {
    this.mobileMenuOpened = !this.mobileMenuOpened;
  }

  constructor(@Inject('OnsiteAuthService') private onsiteAuthService: IOnsiteAuthService) {
    this.teamMember = {
      firstName: '',
      lastName: '',
    };
  }

  ngOnInit(): void {
    this.onsiteAuthService.getUser$().subscribe((changes: any) => {
      this.teamMember = {
        firstName: changes.firstName,
        lastName: changes.lastName
      };
    });
  }
}
