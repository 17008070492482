import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TeamMemberSearchOption } from 'src/app/models/team-member';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';

@Component({
  selector: 'app-request-schedule-change',
  templateUrl: './request-schedule-change.component.html',
  styleUrls: ['./request-schedule-change.component.scss']
})
export class RequestScheduleChangeComponent {
  public nextButtonRouterLink = '';
  private readonly unselectedType = 'tertiary';
  private readonly selectedType = 'primary';
  public updateCalloutType = this.unselectedType;
  public endCalloutType = this.unselectedType;

  constructor(@Inject('SchedulerService') private schedulerService: SchedulerService, private router: Router) { }

  public get selectedTeamMember(): TeamMemberSearchOption | undefined {
    return this.schedulerService.selectedTeamMember;
  }

  public onChangeRadioButton(event: any): void {
    this.nextButtonRouterLink = `/request-schedule-change-${event.value}`;


    this.endCalloutType = event.value === 'end' ? this.selectedType : this.unselectedType ;
    this.updateCalloutType = event.value === 'update' ? this.selectedType : this.unselectedType;
  }

  nextClick(): void{
    this.router.navigateByUrl(this.nextButtonRouterLink, { skipLocationChange: true});
  }

  public get isNextButtonDisabled(): boolean {
    return !this.nextButtonRouterLink;
  }
}
