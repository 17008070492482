import { EventEmitter, Inject, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { combineLatest } from 'rxjs';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import { ChangeRequestTeamMember } from 'src/app/models/change-request-team-member';
import { KeyValuePair } from 'src/app/models/key-value-pair';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { ScheduleChangeRequestTicketClaim } from 'src/app/models/schedule-change-request-ticket-claim';
import { TeamMemberBadge } from 'src/app/models/team-member-badge';
import { ScheduleStatusRequest } from 'src/app/utils/enums';

@Component({
  selector: 'app-claim-request',
  templateUrl: './claim-request.component.html',
  styleUrls: ['./claim-request.component.scss']
})
export class ClaimRequestComponent implements OnInit, OnChanges {
  @Input() public data!: ScheduleChangeRequestTicketClaim;

  @Output() isTicketApproved: EventEmitter<boolean> = new EventEmitter();
  @Output() assignedToTeamMemberId: EventEmitter<any> = new EventEmitter();
  @Output() approvedReason: EventEmitter<KeyValuePair> = new EventEmitter();
  @Output() deniedReason: EventEmitter<KeyValuePair> = new EventEmitter();

  public isTicketAssignedToMe = false;
  public isTicketAssignedToSomeoneElse = false;
  public teamMemberBadge!: TeamMemberBadge;
  public assignedTo!: ChangeRequestTeamMember | undefined;
  public requestStatus!: string;
  public adminComment = '';
  public approvalForm!: FormGroup;
  public isAssignToMeButtonDisabled = false;
  public isAssignToMeInsteadButtonDisabled = false;
  public isApproveDenyButtonsDisabled = false;

  private isParkingAdmin!: boolean;
  private isWorkspaceAdmin!: boolean;
  private usersTeamMemberId!: string;
  private currentUser!: OnsiteUser;

  constructor(@Inject('OnsiteAuthService') private onsiteAuthService: IOnsiteAuthService) { }

  ngOnInit(): void {
    this.setCurrentUserData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataChanges: SimpleChange = changes.data;

    if (!dataChanges.firstChange) {
      this.updateAssignedToData(dataChanges);
      this.updateApprovalAndDenialData(dataChanges);
    }
  }

  public get title(): string {
    if (this.isTicketAssignedToSomeoneElse && !this.isTicketAssignedToMe) {
      return 'Request Claimed';
    } else if (this.isTicketAssignedToMe && !this.isTicketAssignedToSomeoneElse) {
      return 'Review Request';
    }
    return 'Claim Request';
  }

  public get formattedEffectiveDate(): string {
    const date = DateTime.fromISO(this.data.effectiveDate);
    return date.toLocaleString(DateTime.DATE_FULL);
  }

  public assignTicketToMe(): void {
    if (this.canTakeActionOnTicket()) {
      this.isAssignToMeButtonDisabled = true;
      this.assignedToTeamMemberId.emit(this.currentUser);
    }
  }

  public assignTicketToMeInstead(): void {
    if (this.canTakeActionOnTicket()) {
      this.isAssignToMeInsteadButtonDisabled = true;
      this.assignedToTeamMemberId.emit(this.currentUser);
    }
  }

  public approveTicket(): void {
    if (this.canTakeActionOnTicket() && this.adminComment.length) {
      this.isApproveDenyButtonsDisabled = true;
      this.approvedReason.emit({ key: JSON.stringify(this.currentUser), value: this.adminComment });
    }
  }

  public denyTicket(): void {
    if (this.canTakeActionOnTicket() && this.adminComment.length) {
      this.isApproveDenyButtonsDisabled = true;
      this.deniedReason.emit({ key: JSON.stringify(this.currentUser), value: this.adminComment });
    }
  }

  private setCurrentUserData(): void {
    combineLatest([
      this.onsiteAuthService.getUser$(),
      this.onsiteAuthService.hasParkingAdminAccess$(),
      this.onsiteAuthService.hasWorkspaceAdminAccess$()
    ]).subscribe(([user, hasParkingAdminAccess, hasWorkspaceAdminAccess]) => {
      this.teamMemberBadge = { firstName: user.firstName, lastName: user.lastName };
      this.usersTeamMemberId = user.commonId;
      this.currentUser = user;

      this.assignedTo = this.data.assignedTo;
      this.isTicketAssignedToSomeoneElse = this.assignedTo ? this.assignedTo.commonId !== this.usersTeamMemberId : false;
      this.isTicketAssignedToMe = this.assignedTo ? this.assignedTo.commonId === this.usersTeamMemberId : false;

      this.isParkingAdmin = hasParkingAdminAccess;
      this.isWorkspaceAdmin = hasWorkspaceAdminAccess;
      this.requestStatus = this.data.requestStatus;
    });
  }

  private updateAssignedToData(dataChanges: SimpleChange): void {
    const currentAssignedToTeamMember: any | undefined = dataChanges.currentValue.assignedTo;
    const previousAssignedToTeamMember: any | undefined = dataChanges.previousValue.assignedTo;

    if (currentAssignedToTeamMember) {
      this.assignedTo = currentAssignedToTeamMember;
      this.isTicketAssignedToSomeoneElse = this.assignedTo ? this.assignedTo.commonId !== this.usersTeamMemberId : false;
      this.isTicketAssignedToMe = this.assignedTo ? this.assignedTo.commonId === this.usersTeamMemberId : false;

      this.isAssignToMeButtonDisabled = false;
    } else if (this.ticketIsBeingUnassigned(previousAssignedToTeamMember, currentAssignedToTeamMember)) {
      this.assignedTo = undefined;
      this.isTicketAssignedToSomeoneElse = false;
      this.isTicketAssignedToMe = false;

      this.isAssignToMeButtonDisabled = false;
    }
  }

  private updateApprovalAndDenialData(dataChanges: SimpleChange): void {
    if (this.approvalOrDenialChanged(dataChanges)) {
      this.isApproveDenyButtonsDisabled = false;
      this.adminComment = '';
    }
  }

  private canTakeActionOnTicket(): boolean {
    switch (this.requestStatus) {
      case ScheduleStatusRequest['120:PENDINGWORKSPACE']: {
        return this.isWorkspaceAdmin;
      } case ScheduleStatusRequest['130:PENDINGPARKING']: {
        return this.isParkingAdmin;
      } default: {
        return false;
      }
    }
  }

  private ticketIsBeingUnassigned(previousAssignedToTeamMember: any, currentAssignedToTeamMember: any): boolean {
    return previousAssignedToTeamMember && !currentAssignedToTeamMember;
  }

  private approvalOrDenialChanged(dataChanges: SimpleChange): boolean {
    return dataChanges.previousValue && dataChanges.currentValue &&
      dataChanges.previousValue.requestStatus !== dataChanges.currentValue.requestStatus;
  }
}
