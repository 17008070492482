<span
  *ngIf="!noTooltip"
  class="tm-badge {{ size }} bg{{ bg }}"
  matTooltip="{{ fullName }}"
  [matTooltipDisabled]="disabled"
  [matTooltipPosition]="tooltipPosition"
>
  <span class="initials">{{ initials }}</span>
</span>
<span
  *ngIf="noTooltip"
  class="tm-badge {{ size }} bg{{ bg }}"
>
  <span class="initials">{{ initials }}</span>
</span>
