import { Component, EventEmitter, Output } from '@angular/core';
import { Booking } from 'src/app/models/booking';
import { CreateBookingMetadata } from 'src/app/models/create-booking-metadata';
import IDialogCreateModal from '../dialog-create-modal';

@Component({
  selector: 'app-dialog-booking-confirmation',
  templateUrl: './dialog-booking-confirmation.component.html',
})
export class DialogBookingConfirmationComponent implements IDialogCreateModal {
  @Output() backClick: EventEmitter<undefined> = new EventEmitter();
  @Output() nextClick: EventEmitter<unknown> = new EventEmitter();

  public data!: CreateBookingMetadata;

  public next(): void {
    this.nextClick.emit({});
  }

  public back(): void {
    this.backClick.emit();
  }

  public getDates(): string[] {
    const dates: string[] = [];

    return this.data && this.data.bookings && this.data.bookings.length
      ? this.data.bookings.map((booking: Booking) =>
          this.getBaseDate(booking.startDate)
        )
      : dates;
  }

  private getBaseDate(date: string): string {
    return date.split('T')[0];
  }
}
