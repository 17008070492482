import { Inject, Injectable, Optional } from '@angular/core';
import { DatePipe } from '@angular/common';
import { IEventTeamMember } from './calendar-interfaces';
import { SingleEventModel } from './single-event';
import { OnsiteUser } from './onsite-user';

@Injectable({
  providedIn: 'root'
})
export class CalendarEvents {

  public events: Map<string, SingleEventModel> = new Map(); // key is a date in YYYY-MM-DD

  constructor(@Optional() @Inject('DatePipe') public datePipe: DatePipe) { }

  public addTeamMemberToEvent(date: string, teamMember: IEventTeamMember, currentUser: OnsiteUser): void {
    if (this.events.has(date)) {
      const event = this.events.get(date) || null;
      if (event !== null) {
        event.removeTeamMember(teamMember.commonId);
        event.addTeamMember(teamMember);
      }
    } else {
      const event = new SingleEventModel(date, currentUser);
      event.addTeamMember(teamMember);
      this.events.set(date, event);
    }
  }

  public removeTeamMemberFromEvents(commonId: string): void {
    this.events.forEach((event: SingleEventModel) => {
      event.removeTeamMember(commonId);
    });
  }

  public clearEvents(): void {
    this.events.clear();
  }

  public getAllEvents(): Map<string, SingleEventModel> {
    return this.events;
  }

}
