import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { ScheduleUtilities } from '../../services/schedule-utilities';
import { IScheduleRequest, ScheduleRequestType, ScheduleSaveType } from 'src/app/models/scheduleRequest';

@Component({
  selector: 'app-request-schedule-change-end',
  templateUrl: './request-schedule-change-end.component.html',
  styleUrls: ['./request-schedule-change-end.component.scss']
})
export class RequestScheduleChangeEndComponent implements OnInit {

  public minDate: Date = new Date();
  public bannerErrorMessage = ''; // This will be removed once error handling component created at project level
  public scheduleForm: FormGroup = new FormGroup({
    endDate: new FormControl(null, [Validators.required]),
    confirmationCheckBox: new FormControl(false, [Validators.required, Validators.requiredTrue]),
  });
  constructor(
    @Inject('SchedulerService') private schedulerService: SchedulerService, private router: Router,
    @Inject('ScheduleUtilities') private scheduleUtilities: ScheduleUtilities
  ) { }

  ngOnInit(): void {
    if (!this.schedulerService.selectedTeamMember) {
      this.router.navigate(['/request-schedule-change-search']);
    }
  }

  public get teamMemberName(): string {
    return this.schedulerService.selectedTeamMember?.title || '';
  }

  public onSubmit(): void {
    this.bannerErrorMessage = '';
    const endDate = this.scheduleForm?.get('endDate')?.value?.toISOString()?.split('T')[0];
    const endScheduleRequest: IScheduleRequest = {
      metadata: {
        requestType: ScheduleRequestType.End,
        effectiveDate: endDate,
        scheduleHolderId: this.schedulerService.selectedTeamMember?.commonId,
        reason: '',
      }, schedules: [{
        isActive: true,
        scheduleEndDate: endDate,
        frequency: 'WEEKLY',
        interval: 1,
        order: 1,
        saveType: ScheduleSaveType.EndSchedule
      }]
    };
    try {
      this.schedulerService.changeOrEndSchedule(endScheduleRequest).then(() => {
        this.router.navigate(['/request-schedule-change-submit-success']);
      }).catch((err) => {
        this.bannerErrorMessage = 'Your request did not process successfully. Please, re-enter your information and resubmit.';
      });
    }
    catch (error: any) {
      this.bannerErrorMessage = error.message;
    }
  }
}
