export enum ScheduleRequestType {
    Update = 'Update',
    End = 'End'
}

export enum ScheduleSaveType {
    NewSchedule = 'New',
    EndSchedule = 'End',
    DeleteSchedule = 'Delete',
    NoAction = 'NoAction'
}

export interface IScheduleRequestMetadata {
    requestType: ScheduleRequestType;
    effectiveDate: string;
    scheduleHolderId?: string;
    reason?: string;
    timeZone?: string;
}

export interface IScheduleRequestRecurrencePattern {
    isActive: boolean;
    scheduleStartDate?: string;
    scheduleEndDate?: string;
    frequency?: string;
    interval?: number;
    order?: number;
    weekDays?: string;
    saveType?: ScheduleSaveType;
    sourceId?: string;
}

export interface IScheduleRequest {
    metadata: IScheduleRequestMetadata;
    schedules: IScheduleRequestRecurrencePattern[];
}

export interface ISchedule {
    scheduleId: string;
    commonId: string;
    rockHumanId: string;
    scheduleStartDate: string;
    scheduleEndDate?: string;
    weekDays: string;
    createdBy?: string;
    lastUpdatedBy?: string;
    createDate?: string;
    lastUpdateDate?: string;
    deltaChangeDate?: string;
    message?: string;
    isActive?: boolean;
    frequency?: string;
    interval?: number;
    sourceId?: string;
    order?: number;
}

export interface IScheduleCard {
    scheduleStartDate: string;
    scheduleEndDate?: string;
    weekDays: string[];
}
