import { Inject, Injectable } from '@angular/core';
import { IOnsiteAuthService } from './onsite-auth-service.interface';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    @Inject('OnsiteAuthService') private authService: IOnsiteAuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    {
      return this.authService.isLoggedIn$().pipe(map(isLoggedIn => {    
        if (!isLoggedIn)
        {
            this.authService.login(state.url);
        }
        return isLoggedIn;
      }));          
    }
  }
}
