<div class="admin-dashboard-component">
    <div class="admin-dashboard-error" *ngIf="isApiError | async">
        <app-callout type="error" title="Error">
            Service is temporarily unavailable. Please try again later.
        </app-callout>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" class="admin-dashboard__title">
            <h1>
                <span>{{title}}</span>
            </h1>
        </div>
        <div class="calendar-search admin-dashboard-search">
            <app-search *ngIf="isTeamMemberSearchVisible" (teamMemberSelected)="onTeamMemberSelected($event)"
                [searchToolTip]="searchToolTip" [label]="searchLabel" [disabled]="(isLoading | async)">
            </app-search>
            <div *ngIf="selectedTeamMember" class="selected-member">
                <div class="search-option">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <app-tm-badge [size]="'static-md'" [teamMember]="selectedTeamMember.teamMember"></app-tm-badge>
                        <div class="search-details">
                            <div><span class="title">{{selectedTeamMember.title}} </span> <span
                                    class="job-title">({{selectedTeamMember.jobTitle}})</span></div>
                            <div class="contact-details"><u>{{selectedTeamMember.contactDetails}}</u></div>
                            <div class="organization-details">{{selectedTeamMember.organizationDetails}}</div>
                        </div>
                        <div class="minus-icon" [matTooltip]="'Remove'" matTooltipPosition="above"
                            (click)="unselectTeamMember()"><i class="fa-regular fa-xmark"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-schedule-request-table (statusChanged)="changeStatus($event)" [selectedTeamMember]="selectedTeamMember">
    </app-schedule-request-table>
</div>
