import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { IOnsiteAuthService } from '../../../auth/onsite-auth-service.interface';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  constructor(@Inject('OnsiteAuthService') private onsiteAuthService: IOnsiteAuthService,
              private router: Router){}
  clientBrowserLogout(): void {
    this.onsiteAuthService.clientBrowserLogout();
    this.router.navigate(['/']);
  }
}
