import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-select-month-dropdown',
  templateUrl: './select-month-dropdown.component.html'
})
export class SelectMonthDropdownComponent {
  @Output() monthChange: EventEmitter<string> = new EventEmitter();

  public title = '';
  public monthYears: string[] = [];
  public isOverlayVisible = false;
  public isDateDropdownVisible = false;

  constructor(@Inject('initialMonthYear') initialMonthYear: string) {
    this.title = initialMonthYear;
    this.setDateDropdownResults(this.title);
  }

  public onSubmit(month: string): void {
    this.onBlur();
    this.title = month;
    this.monthChange.emit(this.title);
  }

  public onFocus(): void {
    if (!this.isDateDropdownVisible) {
      this.showOverlay();
      this.showDateDropDown();
      document.querySelector<HTMLElement>('.booking-calendar .date-dropdown')?.focus();
      document.querySelector('.booking-calendar .date-dropdown')?.classList.add('scale-in-top');
    }
  }

  public onBlur(): void {
    this.hideOverlay();
    this.hideDateDropdown();
    document.querySelector('.booking-calendar .date-dropdown')?.classList.remove('scale-in-top');
    document.querySelector<HTMLElement>('.booking-calendar .date-dropdown')?.blur();
  }

  public showOverlay(): void {
    this.isOverlayVisible = true;
  }

  public hideOverlay(): void {
    this.isOverlayVisible = false;
  }

  public showDateDropDown(): void {
    this.isDateDropdownVisible = true;
  }

  public hideDateDropdown(): void {
    this.isDateDropdownVisible = false;
  }

  public setDateDropdownResults(title: string): void {
    for (let i = 0; i <= 11; i++) {
      this.monthYears.push(DateTime.fromFormat(title, 'MMMM yyyy').plus({ months: i }).toFormat('MMMM yyyy'));
    }
  }
}
