<div *ngIf="apiError">
  <app-callout type="error" title="Error">
    Service is temporarily unavailable. Please try again later.
  </app-callout>
</div>
<div class="calendar-container container" [ngClass]="customCalendarClasses">
  <app-loading [showLoader]="isCalendarLoading"></app-loading>
  <div style="margin-bottom: 21px;">
    <app-callout title="For Your Awareness" type="primary" icon="fas fa-bell">
      <p *ngIf="bookingsFeatureFlag">
        You can schedule a "Temporary Onsite Visit" by clicking on any open future calendar day.
        When coming onsite, please sit at any open space unless directed by your leader.
        Learn more on
        <a href="https://rockworld.foc.zone/rockworld?id=kb_article&sysparm_article=KB0013068"
          title="Rockworld Onsite Scheduler page"
          target="_blank"
          rel="noopener">
          Rockworld's Onsite Scheduler page
        </a>.
        Out of office, holidays and shift swaps are not reflected below.
      </p>
      <p *ngIf="!bookingsFeatureFlag">
        Only your recurring onsite schedule is reflected below. Out of office, holidays, and shift swaps are not included.
      </p>
    </app-callout>
  </div>
  <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center"
    class="calendar-header-toolbar">
    <div fxLayout="row wrap-reverse" fxLayoutAlign="start center" class="nav-buttons-and-date">
      <button mat-button class="icon-button" (click)="showPreviousMonth()"><i class="fas fa-chevron-left"></i></button>
      <button class="today-button" (click)="showThisMonth()">Today</button>
      <button mat-button class="icon-button" (click)="showNextMonth()"><i class="fas fa-chevron-right"></i></button>
      <h1>
        <span fxHide fxShow.gt-xs>{{currentMonth}} {{currentYear}}</span>
        <span fxHide fxShow.xs>{{currentMonthMobile}} {{currentYear}}</span>
      </h1>
    </div>
    <div class="calendar-search">
      <app-search [excludeTmList]="selectedTeamMembers"
      [tmBadgeIconBgColorIndex]="getTeamMemberBgColorIndex(this.selectedTeamMembers.length)"
      (teamMemberSelected)="onTeamMemberSelected($event)" [searchToolTip]="searchToolTip" [label]="label">
      </app-search>
    </div>
  </div>
  <app-tm-search-carousal (teamMemberRemoved)="onUnselectTeamMember($event)" [selectedTeamMembers]="selectedTeamMembers"></app-tm-search-carousal>
  <full-calendar #calendar [options]="calendarOptions"></full-calendar>
</div>
<div class="legend clearfix" fxLayout="row" fxLayoutAlign="center" fxLayout.xs="row" fxLayoutGap.gt-xs="60px"
    fxLayoutGap.xs="0.5em">
  <div class="legend-item recurring">
    <span class="icon"><i class="fas fa-retweet"></i></span>
    <span class="text">My Recurring Days</span>
  </div>
  <div *ngIf="bookingsFeatureFlag" class="legend-item temporary">
    <span class="icon"><i class="fas fa-hourglass-half"></i></span>
    <span class="text">My Temporary Days</span>
  </div>
</div>
