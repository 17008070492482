import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { UploadResponse } from 'src/app/models/upload-response';
import { UploadStatistics } from 'src/app/models/upload-statistics';
import { isFileCSV } from 'src/app/shared/file.utilities';
import { IScheduleUploadService } from '../../interfaces';
import {
    generateNotificationMessage,
    getRecordCommonIds,
    getTeamMemberDuplicateCommonIds
} from './utilities';

@Component({
    selector: 'app-schedule-upload',
    templateUrl: './schedule-upload.component.html',
    styleUrls: ['./schedule-upload.component.scss']
})
export class ScheduleUploadComponent {
    @ViewChild('fileUploadField') fileUploadField!: ElementRef;
    // Notification handling properties
    notificationMessage = '';
    notificationIsError: boolean | undefined;
    notificationIsWarning: boolean | undefined;
    showNotification = false;

    showThankYou = false;
    errors: Array<string> | undefined;

    // File Upload properties
    file: File | null = null;
    fileUploadResponse: UploadResponse | null = null;
    isEndDateUpload = false;
    isSubmitting = false;

    // 5 MB max AWS Lambda's limit is currently 6 MB.
    readonly maxFileSizeBytes: number = 5120000;

    constructor(@Inject('ScheduleUploadService') private scheduleUploadService: IScheduleUploadService) {
    }

    fileUploadClick(): void {
        this.fileUploadField.nativeElement.click();
    }

    uploadMoreSchedules(): void {
        this.showThankYou = false;
        this.errors = undefined;
        this.file = null;
        this.fileUploadResponse = null;
        this.showNotification = false;
        this.notificationMessage = '';
        this.notificationIsError = undefined;
        this.notificationIsWarning = undefined;
        this.isEndDateUpload = false;
    }

    setIsEndDateUpload(val: boolean): void {
        this.isEndDateUpload = val;
    }

    submitFile(): void {

        if (this.file && this.file.size < this.maxFileSizeBytes) {

            this.isSubmitting = true;

            this.scheduleUploadService
                .uploadFile(this.file, this.isEndDateUpload)
                .then((response) => {
                    this.fileUploadResponse = response;
                    const hasErrors = this.showErrorCommonIds || this.hasUploadErrors;
                    const notificationMessage = generateNotificationMessage(200, hasErrors);

                    console.log('File Upload: ' + JSON.stringify(response));

                    if (hasErrors) {
                        this.errors = response.uploadStatistics?.errors || [];
                        this.notificationIsWarning = true;
                    }

                    this.triggerNotification(notificationMessage, false, hasErrors);
                    this.showThankYou = true;
                    this.isSubmitting = false;
                })
                .catch((e) => {
                    const errorMessage = generateNotificationMessage(parseInt(e.status, undefined), true);
                    console.log(e.error);

                    this.errors = e.error;
                    this.showThankYou = true;
                    this.triggerNotification(errorMessage, true);

                    setTimeout(() => {
                        this.isSubmitting = false;
                    }, 1000);
                });

        } else if (!this.file) {

            this.triggerNotification('File not detected. Please try re-uploading file.', true);

        } else if (!(this.file.size < this.maxFileSizeBytes)) {

            this.triggerNotification('File too large to submit. Please submit a smaller file (under 5MB).', true);

        }
    }

    fileUploaded($event: any): void {
        const uploadedFile = $event.target.files[0];
        // used 1024 to do byte calculations rather than 1000 to stay consistent with humanSize() function.
        if (uploadedFile.size < this.maxFileSizeBytes) {
            // file is less than 5 MB. Check type of file using name to get extension.
            if (isFileCSV(uploadedFile)) {
                // only valid type currently is csv file.
                this.file = uploadedFile;
                // hideNotification because valid file was selected.
                this.hideNotification();
            } else {
                this.triggerNotification('File selected is an invalid file type. Please input a .csv file.', true);
            }
        } else {
            // file size exceeds 5 MB show error
            this.triggerNotification('File selected exceeds 5 MB in size. Please separate schedule entries into smaller batches', true);
        }
    }

    triggerNotification(message: string, isError: boolean = false, isWarning: boolean = false): void {
        this.notificationMessage = message;
        this.notificationIsError = isError;
        this.notificationIsWarning = isWarning;
        this.showNotification = true;
        this.isSubmitting = false;
    }

    hideNotification(): void {
        this.notificationMessage = '';
        this.showNotification = false;
    }

    get fileName(): string | null {
        if (this.file) {
            return this.file.name;
        } else {
            return null;
        }
    }

    get uploadStatistics(): UploadStatistics | null | undefined {
        return this.fileUploadResponse?.uploadStatistics;
    }

    get duplicateRecordCommonIds(): string | null {
        return getTeamMemberDuplicateCommonIds(this.uploadStatistics?.duplicateRecords);
    }

    get recordsNotFoundCommonIds(): string | null {
        return getRecordCommonIds(this.uploadStatistics?.recordsNotFound?.value);
    }

    get pendingScheduleCommonIds(): string | null {
        return getRecordCommonIds(this.uploadStatistics?.recordPending?.value);
    }

    get terminatedMatchCommonIds(): string | null {
        return getRecordCommonIds(this.uploadStatistics?.terminatedMatches?.value);
    }

    get complexOutOfSequenceCommonIds(): string | null {
        return getRecordCommonIds(this.uploadStatistics?.detailedScheduleStatistics?.complexRecordsOutOfSequence.value);
    }

    get complexExceedingMaxCommonIds(): string | null {
        return getRecordCommonIds(this.uploadStatistics?.detailedScheduleStatistics?.complexRecordsExceedingMax.value);
    }

    get complexBadStartDateCommonIds(): string | null {
        return getRecordCommonIds(this.uploadStatistics?.detailedScheduleStatistics?.complexRecordsBadStartDates.value);
    }

    get teamMemberDuplicateCommonIdsFound(): number | null | undefined {
        return this.uploadStatistics?.duplicateRecords?.length;
    }

    get teamMemberCommonIdsNotFound(): number | null | undefined {
        return this.uploadStatistics?.recordsNotFound?.count;
    }

    get teamMemberPendingSchedule(): number | null | undefined {
        return this.uploadStatistics?.recordPending?.count;
    }

    get teamMemberCommonIdsSeparated(): number | null | undefined {
        return this.uploadStatistics?.terminatedMatches?.count;
    }

    get recordsWithMultipleRecurrencePatterns(): number | null | undefined {
        return this.uploadStatistics?.detailedScheduleStatistics?.complexRecordsProcessed;
    }

    get hasErrors(): boolean {
        return !!this.errors &&
            this.errors?.length > 0;
    }

    get hasUploadErrors(): boolean {
        return !!this.uploadStatistics &&
            !!this.uploadStatistics.errors &&
            this.uploadStatistics.errors.length > 0;
    }

    get showErrorCommonIds(): boolean {
        return !!this.duplicateRecordCommonIds ||
            !!this.recordsNotFoundCommonIds ||
            !!this.terminatedMatchCommonIds ||
            !!this.complexBadStartDateCommonIds ||
            !!this.complexExceedingMaxCommonIds ||
            !!this.complexOutOfSequenceCommonIds;
    }

    get notificationClass(): string {
        // 1. if error add error class
        // 2. !error, check if warning, if warning add warning class
        // 3. !error, check if warning, !warning add affirmative class
        return this.notificationIsError ? 'error' :
            this.notificationIsWarning ? 'alert' : 'primary';
    }
}
