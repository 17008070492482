import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly titleParam = 'title';
  constructor(private titleService: Title, private router: Router, private activatedRoute: ActivatedRoute) { }

  @HostListener('window:resize')
  onResize(): void {
    this.updateVerticalHeightCssVar();
  }

  ngOnInit(): void {
    this.setBrowserTitle();
    this.updateVerticalHeightCssVar();
  }

  updateVerticalHeightCssVar(): void {
    const doc = document.documentElement;
    doc.style.setProperty('--vh', (window.innerHeight * .01) + 'px');
  }

  setBrowserTitle(): void {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child?.firstChild) {
            child = child.firstChild;
          }
          if (child != null && child.snapshot.data[this.titleParam]) {
            return child.snapshot.data[this.titleParam];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }
}
