<div class="temp-booking-modal">

  <div class="title-container">
    <div class="title-date h1">Schedule Temporary Onsite Visit</div>
    <div class="dialog-close" [mat-dialog-close]="true"><i class="fa-regular fa-xmark"></i></div>
  </div>

  <mat-dialog-content>
    <div class="team-member-row current-user">
      <div class="badge-col temporary">
        <div *ngFor="let selectedDate of getDates()">
          <app-date-preview [selectedDate]="selectedDate"></app-date-preview>
        </div>
      </div>
      <div class="links-col links-col-pad2 links-col-pad lc-unset-width" style="align-self: center;">
        <p fxLayout="column" class="centered mt-2" fxLayoutAlign="center">
          Please click "Save Visit" to submit. Thank you!
        </p>
        <div class="centered button-container-top">
          <button mat-button id="return-button" (click)="back()" class="bottom-button tertiary">Back</button>
          <button mat-button id="submit-booking-button" (click)="next()" type="submit" class="primary submit-button">
            Save Visit
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>

</div>
