import { Component } from '@angular/core';

@Component({
  selector: 'app-request-schedule-change-submit-success',
  templateUrl: './request-schedule-change-submit-success.component.html',
  styleUrls: ['./request-schedule-change-submit-success.component.scss']

})
export class RequestScheduleChangeSubmitSuccessComponent {

}
