import { Component, Inject, Optional, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OnsiteAuthService } from 'src/app/auth/onsite-auth-service';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { SingleDateModel } from 'src/app/models/single-date';
import { DialogCreateBookingComponent } from '../dialog-create-booking/dialog-create-booking.component';

@Component({
  selector: 'app-dialog-single-date',
  templateUrl: './dialog-single-date.component.html',
  styleUrls: ['./dialog-single-date.component.scss']
})
export class DialogSingleDateComponent {
  date!: string;
  currentUser!: OnsiteUser;
  isCurrentUserAParkingAdmin!: boolean;

  @Input() buttonData = {};

  constructor(
    @Optional() @Inject(MatDialogRef) public dialogRef: MatDialogRef<DialogSingleDateComponent>,
    @Optional() @Inject('MatDialog') public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: SingleDateModel,
    @Inject('OnsiteAuthService') authService: OnsiteAuthService) {
    authService.hasParkingAdminAccess$().subscribe((isCurrentUserAParkingAdmin) => {
      this.isCurrentUserAParkingAdmin = isCurrentUserAParkingAdmin;
    });

    this.currentUser = this.data.currentUser;
    this.date = this.data.date;
  }

  public scheduleTemporaryOnsiteVisit(): void {
    this.dialogRef.close();

    this.dialog.open(DialogCreateBookingComponent, {
      width: '775px',
      maxWidth: 775,
      data: {
        currentUser: this.currentUser,
        reloadCalendar: this.data.reloadCalendar,
        selectedDate: this.data.date,
        scheduledDays: this.data.scheduledDays
      },
      autoFocus: false
    });
  }
}
