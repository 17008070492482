
export class SupportEmail {

  email!: string;
  subject!: string;
  body!: string;

  constructor( email: string, subject: string = '', body: string = '') {
    this.email = email;
    this.subject = subject;
    this.body = body;
  }

  public get mailto(): string {

    let o = 'mailto:' + this.email;

    if ( this.subject.length ) {
      o +=  '?subject=' + encodeURIComponent(this.subject);
    }

    if ( this.body.length ) {

      if ( this.subject.length ) {
        o += '&';
      } else {
        o += '?';
      }

      o += encodeURIComponent(this.body);

    }

    return o;

  }

  public get breakableEmail(): string {
    const parts = this.email.split('@');
    if (parts.length === 2) {
      return `${parts[0]}<wbr>@${parts[1]}`;
    } else {
      return this.email;
    }
  }

}
