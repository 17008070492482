import { Component, Inject, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TeamMemberSearchOption } from 'src/app/models/team-member';
import { ISearchService } from 'src/app/services/search/search.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { searchConfig } from './search.config';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  private keyUpSubject = new Subject<string>();

  public searchResults: TeamMemberSearchOption[] = [];
  public isSearchResultsVisible = false;
  public isUserSearching = false;
  public isApiSearching = false;
  public searchInput = '';
  public isOverlayVisible = false;

  @Output() public teamMemberSelected = new EventEmitter<TeamMemberSearchOption>();
  @Input() public searchToolTip = '';
  @Input() public tmBadgeIconBgColorIndex: '1' | '2' | '3' | '4' | '5' | '6' = '1';
  @Input() public label = '&nbsp;';
  @Input() public excludeTmList: TeamMemberSearchOption[] = [];
  @Input() public placeHolder = searchConfig.placeholder;
  @Input() public disabled: boolean | null = false;

  constructor(@Inject('SearchService') private searchService: ISearchService) {
  }

  ngOnInit(): void {
    this.keyUpSubject
      .pipe(debounceTime(searchConfig.debounceTime))
      .subscribe(searchText => this.search(searchText));
  }

  ngOnDestroy(): void {
    this.keyUpSubject.unsubscribe();
  }

  private async search(searchInput: string): Promise<void> {
    if (searchInput.length < searchConfig.minimumSearchLength) {
      this.isUserSearching = false;
      this.isApiSearching = false;
      return;
    }

    this.isApiSearching = true;
    this.isSearchResultsVisible = true;
    const resultFromDatabase = await this.searchService.search(searchInput).toPromise();
    if (this.excludeTmList.length > 0) {
      this.searchResults = [];
      resultFromDatabase.forEach((i) => {
        if (!this.excludeTmList.find((eTm) => eTm.commonId === i.commonId)) {
          this.searchResults.push(i);
        }
      });
    } else {
      this.searchResults = resultFromDatabase;
    }
    this.isApiSearching = false;
    this.isUserSearching = false;
  }

  public searchInputOnKeyup(): void {
    this.keyUpSubject.next(this.searchInput);
  }

  public searchInputOnKeydown(): void {
    this.isUserSearching = true;
  }

  public get isSearchIconVisible(): boolean {
    return !this.isApiSearching;
  }

  public get isLoadingIconVisible(): boolean {
    return this.isApiSearching;
  }

  public get noResultsFound(): boolean {
    return !this.isUserSearching && this.searchInput.length >= searchConfig.minimumSearchLength && !this.searchResults.length;
  }

  public onTeamMemberSearchFocus(): void {
    this.showSearchResults();
    this.showOverlay();
  }

  public onTeamMemberSearchBlur(): void {
    this.hideSearchResults();
    this.hideOverlay();
  }

  public showSearchResults(): void {
    this.isSearchResultsVisible = true;
  }

  public hideSearchResults(): void {
    this.isSearchResultsVisible = false;
  }

  public showOverlay(): void {
    this.isOverlayVisible = true;
  }

  public hideOverlay(): void {
    this.isOverlayVisible = false;
  }

  public selectTeamMember(selectedOption: TeamMemberSearchOption): void {
    this.teamMemberSelected.emit(selectedOption);
    this.hideSearchResults();
    this.searchResults = [];
    this.searchInput = '';
  }
}
