import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IScheduleRequest, ScheduleRequestType, ScheduleSaveType } from 'src/app/models/scheduleRequest';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { ScheduleUtilities } from '../../services/schedule-utilities';

@Component({
  selector: 'app-request-schedule-change-update',
  templateUrl: './request-schedule-change-update.component.html',
  styleUrls: ['./request-schedule-change-update.component.scss']
})
export class RequestScheduleChangeUpdateComponent implements OnInit {

  public scheduleForm: FormGroup = {} as FormGroup;
  public minDate: Date = new Date();
  public bannerErrorMessage = '';

  constructor(
    @Inject('SchedulerService') private schedulerService: SchedulerService, private router: Router,
    @Inject('ScheduleUtilities') private scheduleUtilities: ScheduleUtilities
  ) {
  }

  ngOnInit(): void {
    if (!this.schedulerService.selectedTeamMember) {
      this.router.navigate(['/request-schedule-change-search']);
    }

    this.initForm();
  }

  public get teamMemberName(): string {
    return this.schedulerService.selectedTeamMember?.title || '';
  }

  initForm(): void {
    this.scheduleForm = new FormGroup({
      recurrenceOn: new FormGroup({
        isSunday: new FormControl(false),
        isMonday: new FormControl(false),
        isTuesday: new FormControl(false),
        isWednesday: new FormControl(false),
        isThursday: new FormControl(false),
        isFriday: new FormControl(false),
        isSaturday: new FormControl(false)
      }, this.validRecurrenceOn()),
      startDate: new FormControl(null, [Validators.required]),
      message: new FormControl(null, [Validators.required]),
    });
  }
  onSubmit(): void {
    this.bannerErrorMessage = '';

    try {
      const scheduleStartDate = this.scheduleUtilities.getAdjustedDate(this.scheduleForm?.get('startDate')?.value);
      const updateScheduleRequest: IScheduleRequest = {
        metadata: {
          requestType: ScheduleRequestType.Update,
          effectiveDate: scheduleStartDate,
          scheduleHolderId: this.schedulerService.selectedTeamMember?.commonId,
          reason: this.scheduleForm.get('message')?.value
        }, schedules: [{
          isActive: true,
          scheduleStartDate,
          weekDays: this.getWeekdayString(),
          frequency: 'WEEKLY',
          interval: 1,
          order: 1,
          saveType: ScheduleSaveType.NewSchedule
        }]
      };
      this.schedulerService.changeOrEndSchedule(updateScheduleRequest).then(() => {
        this.router.navigate(['/request-schedule-change-submit-success']);
      }).catch((err) => {
        this.bannerErrorMessage = 'Your request did not process successfully. Please, re-enter your information and resubmit.';
      });
    }
    catch (error: any) {
      this.bannerErrorMessage = error.message;
    }
  }

  validRecurrenceOn(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const group = control as FormGroup;
      const controls = Object.values(group.controls);
      return controls.some(x => x.value) ? null : { atLeastOneRequired: true };
    };
  }
  getWeekdayString(): string {
    const recurrenceOn = this.scheduleForm.get('recurrenceOn')?.value;
    const dayArr = [];
    if (recurrenceOn.isSunday) {
      dayArr.push('Sun');
    }

    if (recurrenceOn.isMonday) {
      dayArr.push('Mon');
    }

    if (recurrenceOn.isTuesday) {
      dayArr.push('Tue');
    }

    if (recurrenceOn.isWednesday) {
      dayArr.push('Wed');
    }

    if (recurrenceOn.isThursday) {
      dayArr.push('Thu');
    }

    if (recurrenceOn.isFriday) {
      dayArr.push('Fri');
    }

    if (recurrenceOn.isSaturday) {
      dayArr.push('Sat');
    }

    return dayArr.join(',');
  }
}
