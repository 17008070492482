import { Component, OnInit, Inject } from '@angular/core';
import { combineLatest } from 'rxjs';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import { RoutingService } from 'src/app/services/routing/routing.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year: number = new Date().getFullYear();

  public isViewingChangeComplexSchedulePage = false;
  public isScheduleAdminVisible = false;
  public isDashboardAdminVisible = false;
  public isOnsiteApprovalAdminVisible = false;

  constructor(@Inject('OnsiteAuthService') private onsiteAuthService: IOnsiteAuthService,
              @Inject('RoutingService') private routingService: RoutingService) { }

  ngOnInit(): void {
    this.onsiteAuthService.hasScheduleAdminAccess$().subscribe((isAdmin: boolean) => this.isScheduleAdminVisible = isAdmin);
    this.onsiteAuthService.hasOnsiteApprovalAdminAccess$().subscribe((isAdmin: boolean) => this.isOnsiteApprovalAdminVisible = isAdmin);

    this.routingService.isViewingChangeComplexSchedulePage$
      .subscribe(isViewing => this.isViewingChangeComplexSchedulePage = isViewing);

    combineLatest([
      this.onsiteAuthService.hasScheduleAdminAccess$(),
      this.onsiteAuthService.hasParkingAdminAccess$(),
      this.onsiteAuthService.hasWorkspaceAdminAccess$()
    ]).subscribe(([scheduleAdminAccess, parkingAdminAccess, workspaceAdminAccess]) => {
      this.isDashboardAdminVisible = scheduleAdminAccess || parkingAdminAccess || workspaceAdminAccess;
    });
  }
}
