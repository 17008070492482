<div class="selected-members tm-search-carousal" *ngIf="canShowSelectedTeamMembers" style="height: 90px" fxLayout="row"
  fxLayoutAlign="end center">
  <div *ngIf="displayArrayIcons">
    <span *ngIf="enableLeftArrayIcon" matRipple class="icon-button" style="line-height: 72px; margin-right: 0.5em;"
      (click)="moveViewLeft()"><i class="fas fa-chevron-left"></i></span>
    <span *ngIf="!enableLeftArrayIcon" class="icon-button" style="line-height: 72px; margin-right: 0.5em;"
    disabled (click)="moveViewLeft()"><i class="fas fa-chevron-left"></i></span>
  </div>

  <div class="search-option" *ngFor="let searchOption of displayTeamMembersList">
    <div fxLayout="row" fxLayoutAlign="start center">
      <app-tm-badge [bg]="searchOption.teamMember.bgColorIndex ? searchOption.teamMember.bgColorIndex : '1'"
        [size]="'static-md'" [teamMember]="searchOption.teamMember"></app-tm-badge>
      <!-- clean this up later -->
      <div class="search-details">
        <div><span class="title">{{searchOption.title}} </span> <span
            class="job-title">({{searchOption.jobTitle}})</span></div>
        <div class="contact-details"><u>{{searchOption.contactDetails}}</u></div>
        <div class="organization-details">{{searchOption.organizationDetails}}</div>
      </div>
      <div style="cursor: pointer;" (click)="onUnselectTeamMember(searchOption)" class="minus-icon"
        [matTooltip]="'Remove'" matTooltipPosition="above">
        <i class="fas fa-minus"></i>
      </div>
    </div>
  </div>

  <div *ngIf="displayArrayIcons">
    <span *ngIf="enableRightArrayIcon" matRipple class="icon-button" style="line-height: 72px; margin-left: 0.5em;" 
      (click)="moveViewRight()"><i class="fas fa-chevron-right"></i></span>
    <span *ngIf="!enableRightArrayIcon" class="icon-button" style="line-height: 72px; margin-left: 0.5em;" (click)="moveViewRight()"
      disabled><i class="fas fa-chevron-right"></i></span>
  </div>
</div>
