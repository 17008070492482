<div class="temp-booking-modal">
    <div class="title-container">
        <div class="title-date h1">Schedule Temporary Onsite Visit</div>
        <div class="dialog-close" [mat-dialog-close]="true"><i class="fa-regular fa-xmark"></i></div>
    </div>

    <mat-dialog-content>
        <div class="team-member-row current-user">
            <div class="badge-col temporary">
                <div *ngFor="let selectedDate of getDates()">
                    <app-date-preview [selectedDate]="selectedDate"></app-date-preview>
                </div>
            </div>
            <div class="links-col links-col-pad">
                <div class="modal-title">
                    <div class="title-name h2">Success! You have scheduled an onsite visit.</div>
                </div>
                <div class="booking-success-description">
                    The following days have been added to your calendar:
                    <strong>{{ getDateMessage() }}</strong>.
                </div>
                <div class="centered button-container-top">
                    <button mat-button id="submit-button" (click)="next()" type="submit" class="secondary submit-button">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
