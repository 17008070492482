import { Component, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { IScheduleRequestRecurrencePattern } from 'src/app/models/scheduleRequest';
import { ConfirmationModalService } from 'src/app/services/confirmation/confirmation-modal.service';
import { DeviceSettings } from 'src/app/utils/device-settings';
import { LabelEnums, ScreenSize } from 'src/app/utils/enums';
import { GeneralUtilities } from 'src/app/utils/general-utilities';

@Component({
  selector: 'app-new-schedule-card',
  templateUrl: './new-schedule-card.component.html',
  styleUrls: ['./new-schedule-card.component.scss']
})
export class NewScheduleCardComponent {

  public screenSize: ScreenSize;

  @Input() readOnly = false;
  @Input() forceMobileLayout = false;
  @Input() public newScheduleData: IScheduleRequestRecurrencePattern[] = [];
  @Input() public teamMemberName = '';
  @Output() public resetEmitter: EventEmitter<any> = new EventEmitter();

  constructor(@Inject('ConfirmationModalService') private confirmationService: ConfirmationModalService) {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  public isDaySelected(weekday: string, weekdays?: string): boolean {
    return weekdays?.includes(weekday) ?? false;
  }

  public deleteSchedule(): void {
    const message = 'Are you sure you want to remove this schedule?';
    const header = 'Remove New Schedule for ' + this.teamMemberName;
    this.confirmationService.open(message, header)
      .afterClosed().subscribe(deleted => {
        if (deleted) {
          this.resetEmitter.emit();
        }
      });
  }

  public formattedDate(date: string | undefined): string {
    return date ? GeneralUtilities.formatDateMMDDYY(new Date(date)) : '—';
  }

  public get startDate(): string {
    if (!this.newScheduleData || this.newScheduleData.length < 1) {
      return '—';
    }
    return this.formattedDate(this.newScheduleData[0].scheduleStartDate);
  }

  public get showMobileLayout(): boolean {
    return (this.screenSize <= ScreenSize.small) || this.forceMobileLayout;
  }

  public get removeScheduleTooltip(): string {
    return LabelEnums.RemoveNewScheduleToolTip;
  }
}
