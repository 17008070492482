import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITeamMember, TeamMemberSearchOption } from 'src/app/models/team-member';
import { environment } from 'src/environments/environment';

type Nullable<T> = T | undefined | null;

export interface ISearchService {
  search(searchText: string): Observable<TeamMemberSearchOption[]>;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService implements ISearchService {
  private SelectedTeamMember: Nullable<TeamMemberSearchOption> = null;

  constructor(@Inject('HttpClient') private httpClient: HttpClient) { }

  public search(searchText: string): Observable<TeamMemberSearchOption[]> {
    return this.httpClient.get<ITeamMember[]>(`${environment.api.supportApi.url}/v1.0/TeamMember/search`, { params: { searchText } })
      .pipe(
        map(results => {
          results?.forEach((tm) => {
            if (tm) {
              tm.commonId = tm.commonId?.toString() ?? '';
            }
          });

          return results?.map(tm => new TeamMemberSearchOption(tm)) || [];
        })
      );
  }

  public set(TeamMember: Nullable<TeamMemberSearchOption>): void {
    this.SelectedTeamMember = TeamMember;
  }

  public get(): Nullable<TeamMemberSearchOption> {
    return this.SelectedTeamMember;
  }
}
