<div class="scheduler-modal">
  <div class="title-container">
    <div class="title-date h1">{{ this.date | date:'fullDate' }}</div>
    <div class="dialog-close" [mat-dialog-close]="true"><i class="fa-regular fa-xmark"></i></div>
  </div>
  <mat-dialog-content>
    <div class="team-member-list">
      <div *ngIf="currentUserEvent" [ngClass]="badgeColumnClass(currentUserEvent)">
        <div class="row-0 team-member-row">
          <div class="badge-col">
            <app-tm-badge [bg]="'1'" [teamMember]="currentUserEvent" [size]="'lg'"></app-tm-badge>
            <div *ngIf="isTemporaryBooking(currentUserEvent)" class="modal-title mobile">
              <div class="title-name h2">{{currentUserEvent.firstName}} {{currentUserEvent.lastName}}</div>
              <div class="title-label h4">
                <span><i class="fas fa-hourglass-half"></i>Temporary Onsite</span>
              </div>
            </div>
            <div *ngIf="isRecurring(currentUserEvent)" class="modal-title mobile">
              <div class="title-name h2">{{currentUserEvent.firstName}} {{currentUserEvent.lastName}}</div>
              <div class="title-label h4">
                <span><i class="fas fa-retweet"></i>Recurring Onsite</span>
              </div>
            </div>
          </div>
          <div class="links-col">
            <div class="current-user__temporary" *ngIf="isTemporaryBooking(currentUserEvent)">
              <div class="modal-title desktop">
                <div class="title-name h2">{{currentUserEvent.firstName}} {{currentUserEvent.lastName}}</div>
                <div class="title-label h4">
                  <span><i class="fas fa-hourglass-half"></i>Temporary Onsite</span>
                </div>
              </div>
              <div *ngIf="currentUserEvent.reservedSeating.isAssigned">
                <div class="link">
                  <a href="{{ reservedSeatingUrl(currentUserEvent.reservedSeating.url) }}" mat-button class="seating" target="_blank"
                    rel="noopener" matTooltip="View Details in iOffice" matTooltipPosition="above">
                    <span class="button-content-wrapper">
                      <span class="type"><i class="fa-solid fa-chair-office"></i></span>
                      <span class="text">{{ currentUserEvent.reservedSeating.label }}</span>
                      <span class="arrow"><i class="fas fa-chevron-right"></i></span>
                    </span>
                  </a>
                </div>
              </div>
              <div *ngIf="!currentUserEvent.reservedSeating.isAssigned">
                <app-callout title="Need a desk?">
                  <div>
                    Hotel desks are no longer required. When coming onsite, please sit at any open desk. If specific seating is required, please speak with your leader.
                  </div>
                </app-callout>
              </div>
            </div>
            <div class="current-user__recurring" *ngIf="isRecurring(currentUserEvent)">
              <div class="modal-title desktop">
                <div class="title-name h2">{{currentUserEvent.firstName}} {{currentUserEvent.lastName}}</div>
                <div class="title-label h4">
                  <span><i class="fas fa-retweet"></i>Recurring Onsite</span>
                </div>
              </div>
              <div *ngIf="currentUserEvent.seating.isAssigned">
                <div class="link">
                  <a href="{{ seatingUrl(currentUserEvent.seating.url) }}" mat-button class="seating" target="_blank"
                    rel="noopener" matTooltip="View Details in iOffice" matTooltipPosition="above">
                    <span class="button-content-wrapper">
                      <span class="type"><i class="fa-solid fa-chair-office"></i></span>
                      <span class="text">{{ currentUserEvent.seating.label }}</span>
                      <span class="arrow"><i class="fas fa-chevron-right"></i></span>
                    </span>
                  </a>
                </div>
              </div>
              <div *ngIf="!currentUserEvent.seating.isAssigned">
                <app-callout title="No Assigned Desk">
                </app-callout>
              </div>
            </div>
            <div *ngIf="currentUserEvent.parking.isAssigned">
              <div class="link">
                <a href="https://rockworld.foc.zone/rockworld?id=kb_article&table=kb_knowledge&sysparm_article=KB0013045" mat-button class="seating" target="_blank"
                  rel="noopener" matTooltip="View Details in MyCommute" matTooltipPosition="above">
                  <span class="button-content-wrapper">
                    <span class="type"><i class="fas fa-parking"></i></span>
                    <span class="text">{{ currentUserEvent.parking.label }}</span>
                    <span class="arrow"><i class="fas fa-chevron-right"></i></span>
                  </span>
                </a>
              </div>
            </div>
            <div *ngIf="!currentUserEvent.parking.isAssigned">
              <app-callout title="No Parking" class="parking">
                <div>
                  You can view all parking options at <a class="long-link"
                    href="https://rockworld.foc.zone/rockworld?id=kb_article&table=kb_knowledge&sysparm_article=KB0013045" target="_blank"
                    rel="noopener">Rockworld: Commute Solutions</a>
                </div>
              </app-callout>
            </div>
            <div *ngIf="isTemporaryBooking(currentUserEvent)" class="remove-temp-booking-button">
              <button mat-button (click)="removeOnsiteVisit()" class="remove-button-row secondary">
                <span class="type icon trash-icon"><i class="fas fa-trash-alt"></i></span>
                <span class="text">Remove Temporary Onsite Visit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="bookingsFeatureFlag && !currentUserEvent">
        <div class="team-member-row row-0 current-user">
          <div class="badge-col">
            <app-tm-badge [bg]="'1'" [teamMember]="currentUser" [size]="'lg'"></app-tm-badge>
            <div class="modal-title mobile">
              <div class="title-name h2">{{currentUser.firstName}} {{currentUser.lastName}}</div>
            </div>
          </div>
          <div class="links-col">
            <div class="current-user__temporary">
              <div class="modal-title desktop">
                <div class="title-name h2">{{currentUser.firstName}} {{currentUser.lastName}}</div>
              </div>
              <app-callout title="Not Onsite" type="default">
                <p>You are currently not scheduled to be onsite this day.</p>
              </app-callout>
            </div>
            <div class="schedule-temp-booking-button">
              <button mat-button (click)="scheduleTemporaryOnsiteVisit()" class="button-row">
                <span class="type icon"><i class="fas fa-calendar-plus"></i></span>
                <span class="text">Schedule Temporary Onsite Visit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let teamMember of teamMemberEvents; let i = index;">
        <div class="team-member-row row-{{ i + 1 }}">
          <div class="badge-col team-member-onsite">
            <app-tm-badge [bg]="teamMember.bgColorIndex ? teamMember.bgColorIndex : '1'" [teamMember]="teamMember"
              [size]="'lg'"></app-tm-badge>
            <div class="modal-title mobile">
              <div class="title-name h2">{{teamMember.firstName}} {{teamMember.lastName}}</div>
              <div class="title-label h4">
                <span>Onsite</span>
              </div>
            </div>
          </div>
          <div class="links-col">
            <div class="modal-title desktop">
              <div class="title-name h2">{{teamMember.firstName}} {{teamMember.lastName}}</div>
              <div class="title-label h4">
                <span>Onsite</span>
              </div>
            </div>
            <div class="team-member__temporary" *ngIf="isTemporaryBooking(teamMember)">
              <div *ngIf="teamMember.reservedSeating.isAssigned">
                <div class="link">
                  <a href="{{ seatingUrl(teamMember.reservedSeating.url) }}" mat-button class="seating" target="_blank"
                    rel="noopener" matTooltip="View Details in iOffice" matTooltipPosition="above">
                    <span class="button-content-wrapper">
                      <span class="type"><i class="fa-solid fa-chair-office"></i></span>
                      <span class="text">{{ teamMember.reservedSeating.label }}</span>
                      <span class="arrow"><i class="fas fa-chevron-right"></i></span>
                    </span>
                  </a>
                </div>
              </div>
              <div *ngIf="!teamMember.reservedSeating.isAssigned">
                <app-callout title="Need a desk?">
                  <div>
                    Hotel desks are no longer required. When coming onsite, please sit at any open desk. If specific seating is required, please speak with your leader.
                  </div>
                </app-callout>
              </div>
              <div *ngIf="isCurrentUserAParkingAdmin && teamMember.parking.isAssigned">
                <div class="link">
                  <a href="https://rockworld.foc.zone/rockworld?id=kb_article&table=kb_knowledge&sysparm_article=KB0013045" mat-button class="seating" target="_blank"
                    rel="noopener" matTooltip="View Details in MyCommute" matTooltipPosition="above">
                    <span class="button-content-wrapper">
                      <span class="type"><i class="fas fa-parking"></i></span>
                      <span class="text">{{ teamMember.parking.label }}</span>
                      <span class="arrow"><i class="fas fa-chevron-right"></i></span>
                    </span>
                  </a>
                </div>
              </div>
              <div *ngIf="isCurrentUserAParkingAdmin && !teamMember.parking.isAssigned">
                <app-callout title="No Parking" class="parking">
                </app-callout>
              </div>
            </div>
            <div class="team-member__recurring" *ngIf="isRecurring(teamMember)">
              <div *ngIf="teamMember.seating.isAssigned">
                <div class="link">
                  <a href="{{ seatingUrl(teamMember.seating.url) }}" mat-button class="seating" target="_blank"
                    rel="noopener" matTooltip="View Details in iOffice" matTooltipPosition="above">
                    <span class="button-content-wrapper">
                      <span class="type"><i class="fas fa-solid fa-chair-office"></i></span>
                      <span class="text">{{ teamMember.seating.label }}</span>
                      <span class="arrow"><i class="fas fa-chevron-right"></i></span>
                    </span>
                  </a>
                </div>
              </div>
              <div *ngIf="!teamMember.seating.isAssigned ">
                <app-callout title="No Assigned Desk">
                </app-callout>
              </div>
              <div *ngIf="isCurrentUserAParkingAdmin && teamMember.parking.isAssigned">
                <div class="link">
                  <a href="https://rockworld.foc.zone/rockworld?id=kb_article&table=kb_knowledge&sysparm_article=KB0013045" mat-button class="seating" target="_blank"
                    rel="noopener" matTooltip="View Details in MyCommute" matTooltipPosition="above">
                    <span class="button-content-wrapper">
                      <span class="type"><i class="fas fa-parking"></i></span>
                      <span class="text">{{ teamMember.parking.label }}</span>
                      <span class="arrow"><i class="fas fa-chevron-right"></i></span>
                    </span>
                  </a>
                </div>
              </div>
              <div *ngIf="isCurrentUserAParkingAdmin && !teamMember.parking.isAssigned">
                <app-callout title="No Parking" class="parking">
                </app-callout>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
