<div class="dropdown-wrapper" fxLayout="column" [ngClass]="isDateDropdownVisible ? 'visible' : ''">
    <div (click)="onFocus()" class="fc-toolbar-chunk">
        <h2 class="fc-toolbar-title" id="fc-dom-72">{{ title }}</h2>
        <button type="button" aria-pressed="false"
            class="fc-chevronDropDown-button fc-button fc-button-primary">
            <span><i class="fa fa-chevron-down"></i></span>
        </button>
    </div>
    <div tabindex="-1" class="date-dropdown" (blur)="onBlur()">
        <div class="date-dropdown-results" *ngFor="let month of monthYears" (click)="onSubmit(month)">
            <span class="months">{{month}}</span>
            <span class="icon"><i class="far fa-calendar-alt"></i></span>
        </div>
    </div>
</div>
<app-overlay [Show]="isOverlayVisible"></app-overlay>