<div *ngIf="!showThankYou">
  <div class="upload-schedules-wrapper">
    <div *ngIf="isSubmitting" class="loading-overlay">
      <div class="inner">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>

    <h1>Upload Schedules</h1>
    <div class="white-card">
      <p>
        Use this form only when updating schedules in bulk. Select
        <a routerLink="/schedule-request" href="#">Request Schedule Change</a>
        for individual updates. Please note that files must be in CSV format and
        no larger than 5MB.
      </p>
    </div>
    <div class="type-selection">
      <h2>Type</h2>
      <mat-radio-group fxLayout="row" fxLayoutAlign="space-evenly" name="isEndDateUpload" [(ngModel)]="isEndDateUpload">

        <div class="radio-row">

          <mat-radio-button [value]="false">
            <app-callout class="radio-callout" title="Update" type="tertiary" icon="fas fa-edit">
              Update Team Member Onsite Schedules
            </app-callout>
          </mat-radio-button>

          <mat-radio-button [value]="true">
            <app-callout class="radio-callout" title="End" type="tertiary" icon="fas fa-ban">
              End Team Member Onsite Schedules
            </app-callout>
          </mat-radio-button>

        </div>
      </mat-radio-group>
    </div>

    <div class="file-upload-field">
      <mat-form-field matTooltip="Click Field To Upload Schedule File" matTooltipPosition="above" floatLabel="never"
        class="has-icon file-upload">
        <label class="file-upload-label">Batch File</label>
        <input (click)="fileUploadClick()" class="file-upload-input" #fileInput matInput [readonly]="true"
          value="{{ fileName }}" />
        <div class="field-icon"><i class="fas fa-folder-open"></i></div>
        <div class="field-hint">
          Size: {{ this.file | fileSize }}
        </div>
      </mat-form-field>
    </div>

    <div class="api-errors" *ngIf="showNotification">
      <app-callout type="error" title="Error">
        {{ notificationMessage }}
      </app-callout>
    </div>

    <div fxLayout="row" fxLayoutAlign="center">
      <button mat-button (click)="submitFile()" class="submit-button primary" [disabled]="!file">
        Submit
      </button>
    </div>
  </div>

  <input (change)="fileUploaded($event)" #fileUploadField accept=".csv" style="display: none" type="file"
    tabindex="-1" />

</div>

<div *ngIf="showThankYou" class="thank-you-content">
  <div *ngIf="!hasErrors && !showErrorCommonIds" class="fancy-background"></div>
  <div class="white-card">
    <div *ngIf="!hasErrors && !showErrorCommonIds" class="upload-result-header">
      <h1>Thank You!</h1>
      <p>Your upload was successfully processed.</p>
    </div>

    <div *ngIf="hasErrors || showErrorCommonIds" class="upload-result-header">
      <h1>Uh oh...</h1>
      <p>Your upload was processed with errors.</p>
    </div>

    <div *ngIf="uploadStatistics" class="stats-content">
      <div class="results-table-header">
        <div class="results-header-label">
          <i class="fas fa-tasks"></i>
          {{ notificationMessage }}
        </div>
      </div>
      <table class="stats-table">
        <tr>
          <td>Upload Identifier</td>
          <td class="word-break">{{ uploadStatistics.uploadId }}</td>
        </tr>
        <tr>
          <td>Records Processed</td>
          <td>{{ uploadStatistics.totalRecordsEvaluated }}</td>
        </tr>
        <tr>
          <td>Records Processed With Multiple Recurrence Patterns</td>
          <td>{{ recordsWithMultipleRecurrencePatterns }}</td>
        </tr>
        <tr>
          <td>Records Inserted</td>
          <td>{{ uploadStatistics.recordsAdded }}</td>
        </tr>
        <tr>
          <td>Records Updated</td>
          <td>{{ uploadStatistics.recordsUpdated }}</td>
        </tr>
        <tr>
          <td>Records Unchanged</td>
          <td>{{ uploadStatistics.recordsUnchanged }}</td>
        </tr>
        <tr>
          <td>Record Upload Failures</td>
          <td>{{ uploadStatistics.recordUploadFails }}</td>
        </tr>
        <tr>
          <td>Team Members Not Found</td>
          <td>{{ teamMemberCommonIdsNotFound }}</td>
        </tr>
        <tr>
          <td>TMs w/ Pending Schedule Change</td>
          <td>{{ teamMemberPendingSchedule }}</td>
        </tr>
        <tr>
          <td>Duplicate Records</td>
          <td>{{ teamMemberDuplicateCommonIdsFound }}</td>
        </tr>
        <tr>
          <td>Separated Team Members</td>
          <td>{{ teamMemberCommonIdsSeparated }}</td>
        </tr>
      </table>
    </div>

    <div class="error-details">
      <div *ngIf="hasErrors" class="upload-error-results">
        <app-callout *ngFor="let error of errors" type="error" title="Upload Error">
          {{ error}}
        </app-callout>
      </div>
      <app-callout *ngIf="recordsNotFoundCommonIds" type="error" title="Team Member CommonIds Not Found">
        {{ recordsNotFoundCommonIds }}
      </app-callout>

      <app-callout *ngIf="pendingScheduleCommonIds" type="error" title="TMs w/ Pending Schedule Change">
        {{ pendingScheduleCommonIds }}
      </app-callout>

      <app-callout *ngIf="duplicateRecordCommonIds" type="error" title="CommonIds With Duplicate Schedules Uploaded">
        {{ duplicateRecordCommonIds }}
      </app-callout>

      <app-callout *ngIf="terminatedMatchCommonIds" type="error" title="CommonIds Of Separated Team Members">
        {{terminatedMatchCommonIds}}
      </app-callout>

      <app-callout *ngIf="complexOutOfSequenceCommonIds" type="error"
        title="CommonIds With A Missing Or Incorrect Order #">
        {{complexOutOfSequenceCommonIds}}
      </app-callout>

      <app-callout *ngIf="complexExceedingMaxCommonIds" type="error" title="CommonIds With More Than Two Records">
        {{complexExceedingMaxCommonIds}}
      </app-callout>

      <app-callout *ngIf="complexBadStartDateCommonIds" type="error"
        title="CommonIds With Start Dates That Do Not Match">
        {{complexBadStartDateCommonIds}}
      </app-callout>
    </div>

    <div class="button-container text-center">
      <button mat-button (click)="uploadMoreSchedules()">Upload More Schedules</button>
      <button mat-button [routerLink]="''" class="primary">Return Home</button>
    </div>
  </div>
</div>
