
<div class="text-left">
  <h1 class="error"><i class="fas fa-exclamation-triangle"></i>&nbsp; Error Logging In</h1>
  <p>Please make sure you are using the correct login credentials and have access to the site.</p>
  <br />
  <a class="primary" (click)="clientBrowserLogout()" mat-button>Retry Login</a>
</div>




