<div *ngIf="logoType === 'header'" class="header">
    <a class="logo-link" routerLink="/">
        <img src="./assets/img/logos/Logo_1.5x.png"
        alt="Rocket Central - Onsite Scheduler">
    </a>
</div>

<div *ngIf="logoType === 'footer'" class="footer">
    <a class="logo-link" routerLink="/">
        <img src="./assets/img/logos/Logo_RocketCentral_1x.png"
        alt="Rocket Central - Onsite Scheduler">
    </a>
</div>
