import { Inject, Injectable } from '@angular/core';
import { IOnsiteAuthService } from './onsite-auth-service.interface';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivate,
    Router,
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardAdminGuard implements CanActivate {
    constructor(
        @Inject('OnsiteAuthService') private authService: IOnsiteAuthService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        {
            return combineLatest([
                this.authService.hasScheduleAdminAccess$(),
                this.authService.hasParkingAdminAccess$(),
                this.authService.hasWorkspaceAdminAccess$()
            ]).pipe(
                map(([scheduleAdminAccess, parkingAdminAccess, workspaceAdminAccess]) => {
                    const hasAccess = scheduleAdminAccess || parkingAdminAccess || workspaceAdminAccess;
                    if (!hasAccess) {
                        this.router.navigate(['']);
                    }
                    return hasAccess;
                })
            );
        }
    }
}
