import { ClipboardModule } from '@angular/cdk/clipboard';
import { PortalModule } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { NgxSpinnerModule } from 'ngx-spinner';
import {
    AdminDashboardDataSource
} from 'src/app/services/admin-dashboard/admin-dashboard-data-source';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationGuard } from './auth/authentication-guard';
import { DashboardAdminGuard } from './auth/dashboard-admin.guard';
import { EngineeringTeamGuard } from './auth/engineering-team-guard';
import { OnsiteAuthService } from './auth/onsite-auth-service';
import { OnsiteApprovalAdminGuard } from './auth/onsiteApproval-admin.guard';
import { RequestScheduleGuard } from './auth/request-schedule.guard';
import { ScheduleAdminGuard } from './auth/schedule-admin.guard';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import {
    ScheduleRequestTableComponent
} from './components/admin-dashboard/schedule-request-table/schedule-request-table.component';
import {
    ClaimRequestComponent
} from './components/admin-dashboard/schedule-request-ticket/claim-request/claim-request.component';
import {
    RequestDetailsComponent
} from './components/admin-dashboard/schedule-request-ticket/request-details/request-details.component';
import {
    RequestHistoryComponent
} from './components/admin-dashboard/schedule-request-ticket/request-history/request-history.component';
import {
    RequestedScheduleComponent
} from './components/admin-dashboard/schedule-request-ticket/requested-schedule/requested-schedule.component';
import {
    ScheduleRequestTicketComponent
} from './components/admin-dashboard/schedule-request-ticket/schedule-request-ticket.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import {
    DialogBookingConfirmationComponent
} from './components/calendar/dialog-create-booking/dialog-booking-confirmation/dialog-booking-confirmation.component';
import {
    DialogBookingSuccessComponent
} from './components/calendar/dialog-create-booking/dialog-booking-success/dialog-booking-success.component';
import {
    DialogCreateBookingComponent
} from './components/calendar/dialog-create-booking/dialog-create-booking.component';
import {
    ScheduleVisitComponent
} from './components/calendar/dialog-create-booking/dialog-schedule-visit/dialog-schedule-visit.component';
import {
    SelectMonthDropdownComponent
} from './components/calendar/dialog-create-booking/dialog-schedule-visit/select-month-dropdown/select-month-dropdown.component';
import {
    DialogRemoveOnsiteVisitComponent
} from './components/calendar/dialog-remove-onsite-visit/dialog-remove-onsite-visit.component';
import {
    DialogSingleDateComponent
} from './components/calendar/dialog-single-date/dialog-single-date.component';
import {
    DialogSingleEventComponent
} from './components/calendar/dialog-single-event/dialog-single-event.component';
import { SingleEventComponent } from './components/calendar/single-event/single-event.component';
import {
    TmSearchCarousalComponent
} from './components/calendar/tm-search-carousal/tm-search-carousal.component';
import { CalloutComponent } from './components/callout/callout.component';
import {
    ConfirmationModalComponent
} from './components/confirmation-modal/confirmation-modal.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DatePreviewComponent } from './components/date-preview/date-preview.component';
import { UnauthorizedComponent } from './components/error/unauthorized/unauthorized.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LogoComponent } from './components/logo/logo.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import {
    EditEndScheduleComponent
} from './components/request-schedule-card/edit-end-schedule/edit-end-schedule.component';
import {
    RequestScheduleCardComponent
} from './components/request-schedule-card/request-schedule-card.component';
import {
    NewScheduleCardComponent
} from './components/request-schedule-change-complex/new-schedule-card/new-schedule-card.component';
import {
    NewScheduleModalComponent
} from './components/request-schedule-change-complex/new-schedule-modal/new-schedule-modal.component';
import {
    RequestScheduleChangeComplexComponent
} from './components/request-schedule-change-complex/request-schedule-change-complex.component';
import {
    RequestScheduleChangeEndComponent
} from './components/request-schedule-change-end/request-schedule-change-end.component';
import {
    RequestScheduleChangeNonAdminComponent
} from './components/request-schedule-change-nonadmin/request-schedule-change-nonadmin.component';
import {
    RequestScheduleChangeSearchComponent
} from './components/request-schedule-change-search/request-schedule-change-search.component';
import {
    RequestScheduleChangeSubmitSuccessComponent
} from './components/request-schedule-change-submit-success/request-schedule-change-submit-success.component';
import {
    RequestScheduleChangeUpdateComponent
} from './components/request-schedule-change-update/request-schedule-change-update.component';
import {
    RequestScheduleChangeComponent
} from './components/request-schedule-change/request-schedule-change.component';
import { ScheduleUploadComponent } from './components/schedule-upload/schedule-upload.component';
import { SearchComponent } from './components/search/search.component';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';
import { TmBadgeComponent } from './components/tm-badge/tm-badge.component';
import { BookingDialogDirective } from './directives/booking-dialog.directive';
import { CalendarEvents } from './models/calendar-events';
import { AdGroupUploadService } from './services/adgroup-upload/adgroup-upload.service';
import { AdminDashboardService } from './services/admin-dashboard/admin-dashboard.service';
import { BookingService } from './services/booking/booking.service';
import { CalendarModalService } from './services/calendar/calendar-modal.service';
import { SingleEventService } from './services/calendar/single-event.service';
import { ConfirmationModalService } from './services/confirmation/confirmation-modal.service';
import {
    RequestScheduleChangeService
} from './services/request-schedule-change/request-schedule-change.service';
import { RoutingService } from './services/routing/routing.service';
import { ScheduleUploadService } from './services/schedule-upload/schedule-upload.service';
import { ScheduleUtilities } from './services/schedule-utilities';
import { SchedulerService } from './services/scheduler/scheduler.service';
import { SearchService } from './services/search/search.service';
import { FileSizePipe } from './transforms/file-size.transform';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    StyleGuideComponent,
    ScheduleUploadComponent,
    SearchComponent,
    HeaderComponent,
    FooterComponent,
    StyleGuideComponent,
    UnauthorizedComponent,
    RequestScheduleChangeComponent,
    RequestScheduleChangeSearchComponent,
    RequestScheduleChangeUpdateComponent,
    RequestScheduleChangeEndComponent,
    CalendarComponent,
    SingleEventComponent,
    DialogSingleEventComponent,
    DialogSingleDateComponent,
    DialogCreateBookingComponent,
    DialogBookingConfirmationComponent,
    DialogBookingSuccessComponent,
    DialogRemoveOnsiteVisitComponent,
    TmBadgeComponent,
    RequestScheduleChangeSubmitSuccessComponent,
    CalloutComponent,
    TmSearchCarousalComponent,
    ContactUsComponent,
    OverlayComponent,
    FileSizePipe,
    AdminDashboardComponent,
    ScheduleRequestTableComponent,
    RequestScheduleChangeNonAdminComponent,
    RequestScheduleChangeComplexComponent,
    RequestScheduleCardComponent,
    EditEndScheduleComponent,
    LoadingComponent,
    NewScheduleModalComponent,
    NewScheduleCardComponent,
    ConfirmationModalComponent,
    ScheduleRequestTicketComponent,
    RequestedScheduleComponent,
    RequestDetailsComponent,
    ClaimRequestComponent,
    RequestHistoryComponent,
    BookingDialogDirective,
    DatePreviewComponent,
    ScheduleVisitComponent,
    SelectMonthDropdownComponent,
    LogoComponent
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FullCalendarModule,
    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      audience: environment.auth.audience,
      redirectUri: environment.auth.redirect,
      errorPath: '/unauthorized',
      useRefreshTokens: true,
      httpInterceptor: {
        allowedList: [
          environment.api.supportApi.url + '/*',
          environment.api.scheduleApi.url + '/*'
        ]
      }
    }),
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    MatRadioModule,
    MatRippleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatInputModule,
    FlexLayoutModule,
    PortalModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTabsModule,
    ClipboardModule
  ],
  providers: [
    Title,
    ScheduleAdminGuard,
    DashboardAdminGuard,
    OnsiteApprovalAdminGuard,
    AuthenticationGuard,
    EngineeringTeamGuard,
    RequestScheduleGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: 'HttpClient', useClass: HttpClient },
    { provide: 'AuthService', useClass: AuthService },
    { provide: 'OnsiteAuthService', useClass: OnsiteAuthService },
    { provide: 'SearchService', useClass: SearchService },
    { provide: 'ScheduleUploadService', useClass: ScheduleUploadService },
    { provide: 'ScheduleUtilities', useClass: ScheduleUtilities },
    { provide: 'MatDialog', useClass: MatDialog },
    { provide: 'MatDialogRef', useValue: MatDialogRef },
    { provide: 'AdGroupUploadService', useClass: AdGroupUploadService },
    { provide: 'AdminDashboardService', useClass: AdminDashboardService },
    FlexLayoutModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTooltipModule,
    FormsModule,
    { provide: 'SchedulerService', useClass: SchedulerService },
    { provide: 'RequestScheduleChangeService', useClass: RequestScheduleChangeService },
    { provide: 'CalendarModalService', useClass: CalendarModalService },
    { provide: 'SingleEventService', useClass: SingleEventService },
    { provide: 'DatePipe', useClass: DatePipe },
    { provide: 'ScheduleService', useClass: SchedulerService },
    { provide: 'CalendarEvents', useClass: CalendarEvents },
    { provide: 'BookingCalendarEvents', useClass: CalendarEvents },
    { provide: 'HttpClient', useClass: HttpClient },
    { provide: 'CalloutComponent', useClass: CalloutComponent },
    { provide: 'AdminDashboardDataSource', useClass: AdminDashboardDataSource },
    { provide: 'ConfirmationModalService', useClass: ConfirmationModalService },
    { provide: 'RoutingService', useClass: RoutingService },
    { provide: 'BookingService', useClass: BookingService }
  ],
  bootstrap: [AppComponent],
})

// @ts-ignore
export class AppModule { }
