import { TeamMemberBadge } from './team-member-badge';

export interface ITeamMember extends TeamMemberBadge {
    commonId: string;
    firstName: string;
    lastName: string;
    email: string;
    team: string;
    companyName: string;
    jobTitle: string;
    bgColorIndex?: '1' | '2' | '3' | '4' | '5' | '6';
}

export class TeamMemberSearchOption {
    public teamMember: ITeamMember;

    constructor(teamMember: ITeamMember) {
        this.teamMember = teamMember;
    }

    public get initials(): string {
        const firstInitial = this.teamMember?.firstName?.[0] || '';
        const lastInitial = this.teamMember?.lastName?.[0] || '';

        return (firstInitial + lastInitial).toUpperCase();
    }

    public get title(): string {
        const firstName = this.teamMember?.firstName || '';
        const lastName = this.teamMember?.lastName || '';

        return `${firstName} ${lastName}`;
    }

    public get subtitle(): string {
        const team = this.teamMember?.team || '';

        return team;
    }

    public get contactDetails(): string {
        const email = this.teamMember?.email || '';

        return email;
    }

    public get organizationDetails(): string {
        const company = this.teamMember?.companyName || '';
        const team = this.teamMember?.team || '';

        return `${company} - ${team}`;
    }

    public get commonId(): string {
        return this.teamMember?.commonId?.toString() || '';
    }

    public get jobTitle(): string {
        return this.teamMember?.jobTitle || '';
    }
}
