import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { RequestedScheduleData } from 'src/app/models/requested-schedule-data';
import { ScheduleChangeRequestTicketSchedule } from 'src/app/models/schedule-change-request-ticket-schedule';
import { ScheduleGroup } from 'src/app/models/schedule-group';
import { ISchedule, IScheduleRequestRecurrencePattern, ScheduleSaveType } from 'src/app/models/scheduleRequest';
import { DeviceSettings } from 'src/app/utils/device-settings';
import { ScreenSize } from 'src/app/utils/enums';

@Component({
  selector: 'app-requested-schedule',
  templateUrl: './requested-schedule.component.html',
  styleUrls: ['./requested-schedule.component.scss']
})
export class RequestedScheduleComponent implements OnInit {
  @Input() scheduleData!: RequestedScheduleData;
  @Input() teamMemberId!: string;

  public currentScheduleGroups: ScheduleGroup[] = [];
  public removedSchedule: ISchedule[] = [];
  public newSchedule: IScheduleRequestRecurrencePattern[] = [];
  public screenSize: ScreenSize;
  public readonly currentScheduleToolTip = 'Current';
  public readonly removedScheduleToolTip = 'Removed';
  public readonly requestedScheduleToolTip = 'Requested';
  public readonly notScheduledToolTip = 'Not Scheduled';

  constructor() {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  ngOnInit(): void {
    this.removedSchedule = this.getRemovedSchedule();
    this.currentScheduleGroups = this.getCurrentScheduleGroups();
    this.newSchedule = this.getNewSchedule();
  }

  public get showMobileLayout(): boolean {
    return this.screenSize <= ScreenSize.medium;
  }

  private makeSchedule(schedule: ScheduleChangeRequestTicketSchedule): ISchedule {
    return {
      commonId: this.teamMemberId,
      rockHumanId: '',
      scheduleId: schedule.scheduleId,
      scheduleStartDate: schedule.scheduleStartDate,
      weekDays: schedule.weekDays,
      createDate: schedule.createDate,
      createdBy: schedule.createdBy,
      isActive: schedule.isActive,
      lastUpdateDate: DateTime.now().toString(),
      lastUpdatedBy: '',
      scheduleEndDate: schedule.scheduleEndDate,
      sourceId: schedule.sourceId,
      order: schedule.order
    };
  }

  private getCurrentScheduleGroups(): ScheduleGroup[] {
    const groupedBySourceId = this.getCurrentSchedulesGroupedBySourceId();
    const scheduleGroups: ScheduleGroup[] = [];

    Object.keys(groupedBySourceId).map((sourceId: string) => {
      scheduleGroups.push({
        schedules: groupedBySourceId[sourceId].sort((a, b) => {
          return (a.order || 1) > (b.order || 1) ? 1 : -1;
        })
      });
    });

    return scheduleGroups;
  }

  private getRemovedSchedule(): ISchedule[] {
    const initialSchedule = this.scheduleData.initialSchedule.sort((a, b) => {
      const aStartDate = DateTime.fromISO(a.scheduleStartDate);
      const bStartDate = DateTime.fromISO(b.scheduleStartDate);

      return aStartDate > bStartDate ? 1 : -1;
    });

    const hasADeletedSchedule = this.scheduleData.schedules.some(
      (schedule) => schedule.saveType && schedule.saveType === ScheduleSaveType.DeleteSchedule);

    if (hasADeletedSchedule) {
      if (initialSchedule.length > 1) {
        const lastSchedule = initialSchedule[initialSchedule.length - 1];
        const secondToLastSchedule = initialSchedule[initialSchedule.length - 2];

        if (lastSchedule.sourceId && secondToLastSchedule.sourceId && lastSchedule.sourceId === secondToLastSchedule.sourceId) {
          return [secondToLastSchedule, lastSchedule].map((schedule) => {
            return this.makeSchedule(schedule);
          });
        } else {
          return [lastSchedule].map((schedule) => {
            return this.makeSchedule(schedule);
          });
        }
      } else if (initialSchedule.length === 1) {
        return [initialSchedule[0]].map((schedule) => {
          return this.makeSchedule(schedule);
        });
      }
    }

    return [];
  }

  private getNewSchedule(): IScheduleRequestRecurrencePattern[] {
    return this.scheduleData.schedules.filter((schedule) => !schedule.saveType ||
      schedule.saveType === ScheduleSaveType.NewSchedule)
      .sort((a, b) => {
        return (a.order || 1) > (b.order || 1) ? 1 : -1;
      })
      .map((schedule) => {
        return {
          isActive: schedule.isActive,
          scheduleStartDate: schedule.scheduleStartDate,
          scheduleEndDate: schedule.scheduleEndDate,
          weekDays: schedule.weekDays,
          saveType: ScheduleSaveType.NewSchedule
        };
      });
  }

  private getCurrentSchedulesGroupedBySourceId(): Record<string, ISchedule[]> {
    const removedSchedules = this.removedSchedule;

    const scheduleGroups: Record<string, ISchedule[]> = this.scheduleData.initialSchedule
      .filter((initialSchedule) =>
        removedSchedules.every((removedSchedule) => removedSchedule.scheduleId !== initialSchedule.scheduleId))
      .map((schedule) => {
        return this.makeSchedule(schedule);
      })
      .reduce((groups: any, schedule: ISchedule) => {
        const sourceId = schedule.sourceId ?? '1';
        const group: ISchedule[] = groups[sourceId] || [];
        group.push(schedule);
        groups[sourceId] = group;
        return groups;
      }, {});

    this.updateEndedScheduleData(scheduleGroups);

    return scheduleGroups;
  }

  private updateEndedScheduleData(scheduleGroups: Record<string, ISchedule[]>): void {
    const endedSchedule = this.getEndedSchedule();
    const newSchedule = this.getNewSchedule();

    if (endedSchedule) {
      this.setLastSchedulesEndDates(scheduleGroups, endedSchedule.scheduleEndDate);
    } else if (newSchedule && newSchedule.length > 0) {
      let endDate;
      const newScheduleStartDate = newSchedule[0].scheduleStartDate;

      if (newScheduleStartDate) {
        endDate = DateTime.fromISO(newScheduleStartDate).plus({ days: -1 }).toFormat('yyyy-MM-dd');
      }

      this.setLastSchedulesEndDates(scheduleGroups, endDate);
    }
  }

  private setLastSchedulesEndDates(scheduleGroups: Record<string, ISchedule[]>, endDate: string | undefined): void {
    if (!endDate) { return; }
    const sourceIds = Object.keys(scheduleGroups);
    const lastSourceId = sourceIds[sourceIds.length - 1];

    const endedScheduleGroup = scheduleGroups[lastSourceId];

    endedScheduleGroup?.forEach(schedule => {
      schedule.scheduleEndDate = endDate;
    });
  }

  private getEndedSchedule(): ScheduleChangeRequestTicketSchedule {
    return this.scheduleData.schedules.filter(
      (schedule) => schedule.saveType && schedule.saveType === ScheduleSaveType.EndSchedule)[0];
  }
}
