<div class='new-card badge-box w3-container w3-border w3-border-black w3-margin-top container'>
    <span class="bold-font label">Days</span>
    <div  *ngFor="let schedule of newScheduleData">
        <div class="weekdays-div" fxLayout="row" fxLayoutAlign="space-evenly">
            <span [ngClass]="{'singleClass' : isDaySelected('Sun', schedule.weekDays)}" class="weekday-box">{{showMobileLayout ? 'S' : 'Sun'}}</span>
            <span [ngClass]="{'singleClass' : isDaySelected('Mon', schedule.weekDays)}" class="weekday-box">{{showMobileLayout ? 'M' : 'Mon'}}</span>
            <span [ngClass]="{'singleClass' : isDaySelected('Tue', schedule.weekDays)}" class="weekday-box">{{showMobileLayout ? 'T' : 'Tue'}}</span>
            <span [ngClass]="{'singleClass' : isDaySelected('Wed', schedule.weekDays)}" class="weekday-box">{{showMobileLayout ? 'W' : 'Wed'}}</span>
            <span [ngClass]="{'singleClass' : isDaySelected('Thu', schedule.weekDays)}" class="weekday-box">{{showMobileLayout ? 'T' : 'Thu'}}</span>
            <span [ngClass]="{'singleClass' : isDaySelected('Fri', schedule.weekDays)}" class="weekday-box">{{showMobileLayout ? 'F' : 'Fri'}}</span>
            <span [ngClass]="{'singleClass' : isDaySelected('Sat', schedule.weekDays)}" class="weekday-box">{{showMobileLayout ? 'S' : 'Sat'}}</span>
        </div>
    </div>
    <div class="startdate-div"><span class="bold-font label">Start</span><span class="date-span">{{startDate}}</span></div>
    <div><span class="bold-font label">End  </span><span class="date-span">&#8212;</span></div>
    <div *ngIf="!readOnly" class="trashcan" (click)="deleteSchedule()">
        <span matTooltipClass="new-schedule-card-tool-tip" matTooltipPosition="above" [matTooltip]="removeScheduleTooltip">
            <i class="fas fa-trash-alt white"></i>
        </span>
    </div>
</div>
