
<div style="margin-bottom: 45px;">
  <h1>End {{ teamMemberName }}'s Recurrence</h1>
</div>
<form [formGroup]="scheduleForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <h2>On</h2>
    <input matInput [matDatepicker]="picker" formControlName="endDate" [min]="minDate" (click)="picker.open()" autocomplete="off" />
    <div class="field-icon" style="top: 2.8em !important;"><i class="fas fa-calendar-alt"></i></div>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <div style="margin-bottom: 45px;">
    <!-- might need to adjust the checkbox horizontal spacing and vertical alignment in a followup story at a global level -->
    <mat-checkbox formControlName="confirmationCheckBox">
      I understand this request will end the selected team member's recurring
      schedule on the date I have entered above and they will no longer be
      coming into our offices on a weekly cadence after this date.
    </mat-checkbox>
  </div>
  <div style="margin-bottom: 60px;">
    <app-callout title="Reminder" type="default">
      <p>
        By submitting the request below, you are permanently changing a Team Member's recurring schedule.
      </p>
    </app-callout>
    <app-callout *ngIf="bannerErrorMessage" type="error" title="Error">
      <p>
        {{bannerErrorMessage}}
      </p>
    </app-callout>
  </div>
  <div class="centered">
    <button routerLink="/request-schedule-change tertiary">Back</button>
    <button class="primary" [disabled]="!scheduleForm.valid" type="submit">Submit</button>
  </div>
</form>
