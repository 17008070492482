import { DatePipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Inject,
    Injector,
    OnDestroy,
    Optional,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import dayGridMonth from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { CreateBookingActionData } from 'src/app/models/create-booking-action-data';
import { CreateBookingMetadata } from 'src/app/models/create-booking-metadata';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import IDialogCreateModal from '../dialog-create-modal';
import {
    SelectMonthDropdownComponent
} from './select-month-dropdown/select-month-dropdown.component';

@Component({
  selector: 'app-dialog-schedule-visit',
  templateUrl: './dialog-schedule-visit.component.html'
})
export class ScheduleVisitComponent implements AfterViewInit, OnDestroy, IDialogCreateModal {
  @Output() backClick: EventEmitter<undefined> = new EventEmitter();
  @Output() nextClick: EventEmitter<unknown> = new EventEmitter();

  @ViewChild('bookingCalendar') calendarComponent!: FullCalendarComponent;

  private distinctTmRecords: Record<string, any> = {};
  private dropdownComponentInstance!: ComponentRef<SelectMonthDropdownComponent>;
  private fetchEventsOperation = 0;
  private hasInitialized = false;

  public apiError = false;
  public isCalendarLoading = false;
  public data!: CreateBookingMetadata;
  public selectedDates: string[] = [];
  public calendarOptions: CalendarOptions = {
    plugins: [
      dayGridMonth,
      interactionPlugin
    ],
    initialView: 'dayGridMonth',
    height: 'auto',
    contentHeight: 'auto',
    fixedWeekCount: false,
    dayHeaderFormat: { weekday: 'short' },
    headerToolbar: {
      start: '',
      center: '',
      right: ''
    },
    datesSet: async (data: any) => {
      this.isCalendarLoading = true;

      if (this.hasInitialized) {
        await this.fetchEvents();
      } else {
        this.storeInitialEventData(data);
      }

      this.selectedDates.forEach((date) => {
        const dayEl = document.querySelector(`.booking-calendar td[data-date="${date}"]`);
        if (dayEl) {
          dayEl.classList.add('selected-booking');
        }
      });

      this.data.scheduledDays.forEach((day) => {
        const dayEl = document.querySelector(`.booking-calendar td[data-date="${day}"]`);
        if (dayEl) {
          dayEl.classList.add('already-scheduled');
        }
      });

      this.isCalendarLoading = false;
    },
    loading: (isLoading: boolean) => {
      this.isCalendarLoading = isLoading;
    },
    events: [],
    dateClick: (data: any) => {
      const dateClicked = data.dateStr;
      const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd') ?? '';
      if (today <= dateClicked && !this.data.scheduledDays.some((day) => day === dateClicked)) {
        this.toggleDate(data);
        this.updateCalendarEvents();
      }
    }
  };

  constructor(
    @Optional() @Inject('MatDialog') public dialogRef: MatDialogRef<ScheduleVisitComponent>,
    @Inject('SchedulerService') private schedulerService: SchedulerService,
    @Inject('DatePipe') private datePipe: DatePipe,
    @Inject(ComponentFactoryResolver) protected componentFactoryResolver: ComponentFactoryResolver,
    @Inject(ViewContainerRef) private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.moveCalendarToSelectedMonth();
    this.attachDropdownComponentInstance();
    this.setInitialSelectedDate();
    this.hasInitialized = true;
  }

  ngOnDestroy(): void {
    if (this.dropdownComponentInstance) {
      this.dropdownComponentInstance.destroy();
    }
  }

  public get buttonText(): string {
    let message = '';

    if (this.selectedDates.length > 0) {
      message += this.selectedDates.length;
      const separator = ' - ';
      if (this.selectedDates.length > 1) {
        message += ' Days';
      }
      else {
        message += ' Day';
      }
      message += separator;
    }

    return message + 'Continue';
  }

  public next(): void {
    if (this.selectedDates.length) {
      const actionData: CreateBookingActionData = {
        data: {
          selectedDates: this.selectedDates
        }
      };

      this.nextClick.emit(actionData);
    }
  }

  private toggleDate(data: any): void {
    if (this.dateIsAlreadySelected(data)) {
      this.removeDate(data);
      this.sortSelectedDates();
    } else {
      if (this.selectedDates.length < 5) {
        this.addDate(data);
        this.sortSelectedDates();
      } else {
        this.alertUserThatTheyCantSelectMoreDays();
      }
    }
  }

  private addDate(data: any): void {
    this.selectedDates.push(data.dateStr);
    data.dayEl.classList.add('selected-booking');
  }

  private removeDate(data: any): void {
    this.selectedDates = this.filterOutDate(data);
    data.dayEl.classList.remove('selected-booking');
  }

  private dateIsAlreadySelected(data: any): boolean {
    return this.selectedDates.indexOf(data.dateStr) > -1;
  }

  private filterOutDate(data: any): string[] {
    return this.selectedDates.filter((date: string) => date !== data.dateStr);
  }

  private setInitialSelectedDate(): void {
    this.attachCSSClassToSelectedDate();
    this.updateCalendarEvents();
  }

  private updateCalendarEvents(): void {
    const calApi = this.calendarComponent.getApi();
    this.selectedDates.forEach((date: string) => {
      calApi.addEvent({
        id: 'tb-' + date,
        date,
      });
    });
  }

  private getCalendarHeaderElement(): Element {
    const calendar = document.getElementsByClassName('booking-calendar')[0];
    const headerToolbar = calendar.getElementsByClassName('fc-header-toolbar')[0];
    const headerEl = headerToolbar.getElementsByClassName('fc-toolbar-chunk')[0];
    return headerEl;
  }

  private attachDropdownComponentInstance(): void {
    const calApi = this.calendarComponent.getApi();
    const injector = Injector.create({
      providers: [
        { provide: 'initialMonthYear', useValue: calApi.currentData.viewTitle }
      ]
    });

    const customHeaderComponentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectMonthDropdownComponent);
    this.dropdownComponentInstance = this.viewContainerRef.createComponent(customHeaderComponentFactory, 0, injector);
    this.dropdownComponentInstance.changeDetectorRef.detectChanges();
    this.dropdownComponentInstance.instance.monthChange.subscribe((monthYear: string) => this.handleMonthChange(monthYear));

    const headerEl = this.getCalendarHeaderElement();
    headerEl.replaceWith(this.dropdownComponentInstance.location.nativeElement);
  }

  private attachCSSClassToSelectedDate(): void {
    const date = this.data.selectedDate;
    this.selectedDates.push(date);
    this.changeDetectorRef.detectChanges();

    const dayEl = document.querySelector(`.booking-calendar td[data-date="${date}"]`);
    if (dayEl) {
      dayEl.classList.add('selected-booking');
    } else {
      console.error(`Date could not be found on the full calendar: ${date}`);
    }
  }

  private moveCalendarToSelectedMonth(): void {
    const calApi = this.calendarComponent.getApi();

    calApi.gotoDate(this.data.selectedDate);
  }

  private handleMonthChange(monthYear: string): void {
    const date = DateTime.fromFormat(monthYear, 'MMMM yyyy').toFormat('yyyy-MM-dd');
    const calApi = this.calendarComponent.getApi();

    calApi.gotoDate(date);
  }

  private alertUserThatTheyCantSelectMoreDays(): void {
    document.querySelector('.calendar-col')?.classList.add('vibrate');
    document.querySelector('#select-up-to-five-days')?.classList.add('vibrate');
    window.setTimeout(() => document.querySelector('#select-up-to-five-days')?.classList.remove('vibrate'), 250);
    window.setTimeout(() => document.querySelector('.calendar-col')?.classList.remove('vibrate'), 250);
  }

  private async fetchEvents(): Promise<any> {
    const operation = ++this.fetchEventsOperation;

    const calApi = this.calendarComponent.getApi();
    if (!calApi) {
      return;
    }

    const startDate = this.datePipe.transform(calApi.view.activeStart, 'yyyy-MM-dd') || '';
    const endDate = this.datePipe.transform(calApi.view.activeEnd, 'yyyy-MM-dd') || '';

    const eventData = await this.populateCalendarData(this.data.currentUser, startDate, endDate);

    if (operation !== this.fetchEventsOperation) {
      return;
    }

    const currentUserSchedule: any[] = this.schedulerService.buildTeamMemberScheduleDataOnly(eventData, this.data.currentUser);

    currentUserSchedule.forEach((event: any) => {
      this.data.scheduledDays.push(event.date);
    });

    this.calendarOptions.events = currentUserSchedule;

    this.data.scheduledDays = [...new Set(this.data.scheduledDays)];
  }

  private async populateCalendarData(currentUser: OnsiteUser, startDate: string, endDate: string): Promise<any> {
    this.apiError = false;
    const localstoreKey = this.getlocalStoreKey(currentUser.commonId, startDate, endDate);
    if (!this.distinctTmRecords[localstoreKey]) { // if the results are not already in memory, make api call.
      try {
        const result = await this.schedulerService.getTeamMemberScheduleDataOnly(currentUser.commonId, startDate, endDate).toPromise();
        this.distinctTmRecords[localstoreKey] = { tmData: currentUser, tmResponse: result };
      } catch (e) {
        this.apiError = true;
      }
    } else {
      this.distinctTmRecords[localstoreKey].tmData = currentUser;
    }
    return this.distinctTmRecords[localstoreKey];
  }

  private getlocalStoreKey(commonId: string, startDate: string, endDate: string): string {
    return `${commonId},${startDate},${endDate}`;
  }

  private storeInitialEventData(data: any): void {
    const startDate = this.getBaseDate(data.startStr);
    const endDate = this.getBaseDate(data.endStr);
    const localstoreKey = this.getlocalStoreKey(this.data.currentUser.commonId, startDate, endDate);

    if (!this.distinctTmRecords[localstoreKey]) {
      this.distinctTmRecords[localstoreKey] = { tmData: this.data.currentUser, tmResponse: this.defaultTmResponse() };
    }
  }

  private getBaseDate(date: string): string {
    return date.split('T')[0];
  }

  private defaultTmResponse(): any {
    return {
      teamMemberSchedule: { data: this.data.scheduledDays, hasError: false },
      parkingAssignment: { data: null, hasError: false },
      userSpace: { data: null, hasError: false }
    };
  }

  private sortSelectedDates(): void {
    this.selectedDates = _.orderBy(this.selectedDates, item => item, ['asc']);
  }
}
