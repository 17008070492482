import { Inject, Injectable, Optional } from '@angular/core';
import { DialogSingleEventComponent } from '../../components/calendar/dialog-single-event/dialog-single-event.component';
import { MatDialog } from '@angular/material/dialog';
import { SingleEventModelFactory } from '../../models/single-event-model-factory';
import { DialogSingleDateComponent } from 'src/app/components/calendar/dialog-single-date/dialog-single-date.component';
import { OnsiteUser } from 'src/app/models/onsite-user';

@Injectable({
  providedIn: 'root'
})
export class CalendarModalService {
  constructor(@Optional() @Inject('MatDialog') public dialog: MatDialog) { }

  open(data: any, currentUser: OnsiteUser): void {
    const factory = new SingleEventModelFactory(data, currentUser);
    const model = factory.getModel();

    if (data.event) {
      this.dialog.open(DialogSingleEventComponent, {
        width: '850px',
        maxWidth: 850,
        data: model,
        autoFocus: false
      });
    } else {
      this.dialog.open(DialogSingleDateComponent, {
        width: '850px',
        maxWidth: 850,
        data: model,
        autoFocus: false
      });
    }
  }
}
