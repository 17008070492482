import { Inject, Injectable } from '@angular/core';
import { IOnsiteAuthService } from './onsite-auth-service.interface';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivate,
    Router,
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class RequestScheduleGuard implements CanActivate {
    constructor(
        @Inject('OnsiteAuthService') private authService: IOnsiteAuthService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return combineLatest([
                this.authService.hasScheduleAdminAccess$(),
                this.authService.getUser$()
            ]).pipe(
                map(([hasAdminAccess, user]) => {
                    const hasAccess = hasAdminAccess || (JSON.parse(environment.teamLeaderFeatureFlag) && user.isEffectivelyLeader);

                    if (!hasAccess) {
                        this.router.navigate(['']);
                    }

                    return hasAccess;
                })
            );
    }
}
