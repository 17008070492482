<div class="request-schedule-change-non-admin-wrapper">
  <div *ngIf="!teamLeaderFeatureFlag" class="message">
    <h1>Request Schedule Change</h1>
  
    <p><strong>Team Members, </strong>
      please speak with your leader regarding the desired change to your schedule. Only leaders are permitted
      to submit requests for schedule changes at this time.</p>
  
    <p><strong>Team Leaders, </strong>
      <a href="https://shorty/OnsiteSchedulerInquiry">click here</a> to submit a Cherwell schedule change request for a team member. Please allow up to 10 business days for this request
      to be fulfilled. Our team will be in touch regarding the next steps once we review your request. If you have any trouble accessing Cherwell, please
      send the schedule change request to <a href="{{ workspaceSupport.mailto }}" [innerHTML]="workspaceSupport.breakableEmail"></a>.</p>
  </div>
  <h1 *ngIf="teamLeaderFeatureFlag" class="message">Please speak with your Team Leader regarding a desired change to your schedule.</h1>
  <div>
    <app-callout title="Team Leaders Only" type="default" icon="fa fa-info-circle">
      <p>
        At this time only leaders can submit a request for an individual team member.
      </p>
    </app-callout>
  </div>
</div>
