import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  private isViewingChangeComplexSchedulePage = new BehaviorSubject<boolean>(false);

  public isViewingChangeComplexSchedulePage$ = this.isViewingChangeComplexSchedulePage.asObservable();

  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((e) => {
      if ((e as NavigationEnd).url === '/request-schedule-change-complex') {
        this.isViewingChangeComplexSchedulePage.next(true);
      } else {
        this.isViewingChangeComplexSchedulePage.next(false);
      }
    });
  }
}
