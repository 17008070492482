<div class="scheduler-modal">
  <mat-dialog-content>
    <mat-toolbar fxLayoutAlign="left center"><span class="header-modal">Set Up New Schedule for {{data.name}}</span></mat-toolbar>
    <div class="dialog-close modal" [mat-dialog-close]="false"><i class="fa-regular fa-xmark white"></i></div>
    <div class="create-schedule-form" fxLayout="column">
      <div class="startdate" fxLayout="row">
        <mat-form-field class="date-picker">
          <label>Start  <span *ngIf="showStartDateToolTip" matTooltipClass="new-schedule-modal-tool-tip" matTooltipPosition="above" [matTooltip]="startToolTip"><i class="fas fa-info-circle"></i></span></label>
          <input matInput [(ngModel)]="scheduleStartDate" [matDatepicker]="picker" [min]="minDate" (click)="picker.open()" autocomplete="off" readonly>
          <div class="field-icon" (click)="picker.open()"><i class="fas fa-calendar-alt"></i></div>
          <mat-datepicker [touchUi]="isMobile" #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="days">
          <label>Days  <span matTooltipClass="new-schedule-modal-tool-tip" matTooltipPosition="above" [matTooltip]="daysToolTip"><i class="fas fa-info-circle"></i></span></label>
          <div fxLayout="row" fxLayoutAlign="center center" class="badge-box container">
            <div class="section-content" >
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(0, 'Sun')}" (click)="toggleSelectedWeekdays(0, 'Sun')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Sunday' : 'Sun'}}</span>
                <span *ngIf="showMobileLayout">S</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(0, 'Mon')}" (click)="toggleSelectedWeekdays(0, 'Mon')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Monday' : 'Mon'}}</span>
                <span *ngIf="showMobileLayout">M</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(0, 'Tue')}" (click)="toggleSelectedWeekdays(0, 'Tue')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Tuesday' : 'Tue'}}</span>
                <span *ngIf="showMobileLayout">T</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(0, 'Wed')}" (click)="toggleSelectedWeekdays(0, 'Wed')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Wednesday' : 'Wed'}}</span>
                <span *ngIf="showMobileLayout">W</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(0, 'Thu')}" (click)="toggleSelectedWeekdays(0, 'Thu')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Thursday' : 'Thu'}}</span>
                <span *ngIf="showMobileLayout">T</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(0, 'Fri')}" (click)="toggleSelectedWeekdays(0, 'Fri')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Friday' : 'Fri'}}</span>
                <span *ngIf="showMobileLayout">F</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(0, 'Sat')}" (click)="toggleSelectedWeekdays(0, 'Sat')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Saturday' : 'Sat'}}</span>
                <span *ngIf="showMobileLayout">S</span>
              </button>
              <span class="trashcan"></span>
            </div>
          </div>
          <button matRipple *ngIf="!isComplex" class="add-week-button tertiary" (click)="toggleComplex()"><i class="fas fa-plus"></i> Add Week</button>
          <div *ngIf="isComplex" fxLayout="row" fxLayoutAlign="center center" class="badge-box container">
            <div class="section-content">
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(1, 'Sun')}" (click)="toggleSelectedWeekdays(1, 'Sun')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Sunday' : 'Sun'}}</span>
                <span *ngIf="showMobileLayout">S</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(1, 'Mon')}" (click)="toggleSelectedWeekdays(1, 'Mon')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Monday' : 'Mon'}}</span>
                <span *ngIf="showMobileLayout">M</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(1, 'Tue')}" (click)="toggleSelectedWeekdays(1, 'Tue')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Tuesday' : 'Tue'}}</span>
                <span *ngIf="showMobileLayout">T</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(1, 'Wed')}" (click)="toggleSelectedWeekdays(1, 'Wed')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Wednesday' : 'Wed'}}</span>
                <span *ngIf="showMobileLayout">W</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(1, 'Thu')}" (click)="toggleSelectedWeekdays(1, 'Thu')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Thursday' : 'Thu'}}</span>
                <span *ngIf="showMobileLayout">T</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(1, 'Fri')}" (click)="toggleSelectedWeekdays(1, 'Fri')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Friday' : 'Fri'}}</span>
                <span *ngIf="showMobileLayout">F</span>
              </button>
              <button class="weekdayToggle" [ngClass]="{'day-selected': isDaySelected(1, 'Sat')}" (click)="toggleSelectedWeekdays(1, 'Sat')">
                <span *ngIf="!showMobileLayout">{{isLargeScreen ? 'Saturday' : 'Sat'}}</span>
                <span *ngIf="showMobileLayout">S</span>
              </button>
              <span class="trashcan" (click)="toggleComplex()" matTooltipClass="new-schedule-trashcan-tool-tip"
                    matTooltipPosition="above" [matTooltip]="removeWeekTooltip">
                  <i class="fas fa-trash-alt"></i>
              </span>
            </div>
          </div>
      </div>
      <div *ngIf="showComplexScheduleNote" class="complex-schedule-note">
        <app-callout title="Note" type="Note" icon="fa fa-info-circle">
            Please verify the start date aligns with the days and weeks selected. 
        </app-callout>
      </div>
      <div class="centered">
        <button mat-button (click)="dialogRef.close()" class="tertiary">Back</button>
        <button mat-button (click)="saveModal()" [disabled]="!isFormValid" class="primary">Continue</button>
      </div>
    </div>
  </mat-dialog-content>

</div>
