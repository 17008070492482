import { DatePipe } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Inject,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import dayGridMonth from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { OnsiteAuthService } from 'src/app/auth/onsite-auth-service';
import { CalendarEvents } from 'src/app/models/calendar-events';
import { IEventTeamMember } from 'src/app/models/calendar-interfaces';
import { OnsiteTeamMember } from 'src/app/models/onsite-team-member';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { environment } from 'src/environments/environment';
import { ITeamMember, TeamMemberSearchOption } from '../../models/team-member';
import { CalendarModalService } from '../../services/calendar/calendar-modal.service';
import { SingleEventService } from '../../services/calendar/single-event.service';
import { searchConfig } from '../search/search.config';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})

@HostListener('window:resize', ['$event'])
export class CalendarComponent implements OnDestroy {
  @Output() selectedTeamMembersChange = new EventEmitter<TeamMemberSearchOption[]>();

  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  public searchToolTip = searchConfig.toolTip;
  public eventsData: Array<OnsiteTeamMember> = [];
  public apiError = false;
  public isCalendarLoading = true;
  public label = '<i class="fas fa-user"></i><span class="tm-label">Add Team Member</span>';
  public bookingsFeatureFlag = false;
  public hasParkingAdminAccess = false;

  private distinctTmRecords: Record<string, OnsiteTeamMember> = {}; // Dic to store records to avoid duplicate pulling from API
  private fetchEventsOperation = 0;
  private currentUsersScheduledDaysThisMonth: string[] = [];

  public selectedTeamMembers: TeamMemberSearchOption[] = [];
  public calendarOptions: CalendarOptions = {
    plugins: [
      dayGridMonth,
      interactionPlugin
    ],
    initialView: 'dayGridMonth',
    height: 'auto',
    contentHeight: 'auto',
    fixedWeekCount: false,
    titleFormat: { month: 'long' },
    dayHeaderFormat: { weekday: 'short' },
    headerToolbar: {
      left: '',
      center: '',
      right: ''
    },
    events: [],
    eventContent: (arg: any) => {
      return this.buildEventContainer(arg);
    },
    dateClick: (date: any) => {
      if (this.bookingsFeatureFlag) {
        const dateClicked = date.dateStr;
        const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd') ?? '';
        if (today <= dateClicked) {
          const data: any = {
            date: dateClicked,
            reload: this.reload,
            scheduledDays: this.currentUsersScheduledDaysThisMonth
          };
          this.modalService.open(data, this.currentOnsiteUser);
        }
      }
    },
    eventClick: (event: any) => {
      const eventDate = event.event.extendedProps.date;
      const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd') ?? '';
      if (today <= eventDate) {
        event.reload = this.reload;
        event.scheduledDays = this.currentUsersScheduledDaysThisMonth;
        this.modalService.open(event, this.currentOnsiteUser);
      }
    },
    loading: (isLoading: boolean) => {
      this.isCalendarLoading = isLoading;
      if (!isLoading) {
        this.headerOptionsOnResize();
      }
    },
    windowResize: (view: any) => {
      this.headerOptionsOnResize();
    },
    windowResizeDelay: 100,
    datesSet: (range: any) => {
      this.fetchEvents();
    }
  };

  currentOnsiteUser!: OnsiteUser;

  currentUser: ITeamMember = {
    commonId: '',
    firstName: '',
    lastName: '',
    email: '',
    team: '',
    companyName: '',
    jobTitle: ''
  };

  public obs$: any;

  constructor(
    @Inject('SchedulerService') private schedulerService: SchedulerService,
    @Inject('CalendarModalService') private modalService: CalendarModalService,
    @Inject('SingleEventService') private singleEventService: SingleEventService,
    @Inject('OnsiteAuthService') private authService: OnsiteAuthService,
    @Inject('DatePipe') private datePipe: DatePipe,
    @Inject('CalendarEvents') public calendarEvents: CalendarEvents,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.bookingsFeatureFlag = JSON.parse(environment.bookingsFeatureFlag);

    this.authService.hasParkingAdminAccess$().subscribe((hasParkingAdminAccess: boolean) => {
      this.hasParkingAdminAccess = hasParkingAdminAccess;
    });

    this.authService.getUser$().subscribe((user: OnsiteUser) => {
      let shouldFetchEvents = false;

      if (user) {
        // Fetch if already fetched before (or actively fetching) and commonId changed
        shouldFetchEvents =
          this.fetchEventsOperation > 0
          && user.commonId !== this.currentUser.commonId;

        this.currentUser.commonId = user.commonId;
        this.currentUser.firstName = user.firstName;
        this.currentUser.lastName = user.lastName;
        this.currentOnsiteUser = user;
      }

      if (shouldFetchEvents) {
        this.fetchEvents();
      }
    });
  }

  /**
   * We need to destroy our ComponentRefs when our component is destroyed to prevent a memory leak.
   */
  ngOnDestroy(): void {
    this.singleEventService.destroyAllRefs();
  }

  /**
   * Handles getting the events from the API through the schedulerService.
   */
  public async fetchEvents(): Promise<any> {

    // Track we are on a new operation (terminates old operations at checkpoints below)
    const operation = ++this.fetchEventsOperation;

    const calApi = this.calendarComponent.getApi();
    if (!calApi) {
      return;
    }

    const startDate = this.datePipe.transform(calApi.view.activeStart, 'yyyy-MM-dd') || '';
    const endDate = this.datePipe.transform(calApi.view.activeEnd, 'yyyy-MM-dd') || '';

    this.isCalendarLoading = true;
    this.changeDetectorRef.detectChanges();
    this.eventsData = [];

    await Promise.all(
      [this.currentUser, ...this.selectedTeamMembers.map(i => i.teamMember)]
        .filter(tm => !!tm?.commonId)
        .map(async (tm) => {
          await this.populateCalendarData(tm, startDate, endDate);
        })
    );

    if (operation !== this.fetchEventsOperation) {
      return;
    }

    this.calendarEvents.clearEvents();
    let allEventData: any[] = [];

    // Present selected team members
    for (let i = this.selectedTeamMembers.length - 1; i >= 0; i--) {
      const selectedEventItem = this.eventsData.find((item) => item.tmData.commonId === this.selectedTeamMembers[i].commonId);

      if (selectedEventItem) {
        allEventData = this.schedulerService.buildTeamMemberSchedule(selectedEventItem, this.currentOnsiteUser);
      }
    }

    // Present current user
    if (this.currentUser.commonId) {
      const currentUserItem = this.eventsData.find((item) => item.tmData.commonId === this.currentUser.commonId);

      if (currentUserItem) {
        allEventData = this.schedulerService.buildTeamMemberSchedule(currentUserItem, this.currentOnsiteUser) || [];

        const currentUserSchedule: any[] = allEventData.filter(
          (event: any) => (event.extendedProps.teamMembers as Map<string, IEventTeamMember>).has(this.currentUser.commonId)
        ) || [];
        this.currentUsersScheduledDaysThisMonth = currentUserSchedule.map((data) => data.date);
      }
    }

    this.calendarOptions.events = allEventData;
    this.isCalendarLoading = false;
    this.changeDetectorRef.detectChanges();
  }

  private getlocalStoreKey(commonId: string, startDate: string, endDate: string): string {
    return `${commonId},${startDate},${endDate}`;
  }

  public async populateCalendarData(tm: ITeamMember, startDate: string, endDate: string): Promise<any> {

    const localstoreKey = this.getlocalStoreKey(tm.commonId, startDate, endDate);
    if (!tm?.commonId) {
      console.warn('Invalid team member provided. Skipping.');
      this.apiError = true;
    } else if (!this.distinctTmRecords[localstoreKey]) { // if the results are not already in memory, make api call.
      try {
        const canViewParkingData = this.hasParkingAdminAccess || tm.commonId === this.currentUser.commonId;
        const result = await this.schedulerService.getTeamMemberData(tm.commonId, startDate, endDate, canViewParkingData).toPromise();
        this.distinctTmRecords[localstoreKey] = { tmData: tm, tmResponse: result };
      } catch (e) {
        this.apiError = true;
      }

    } else {
      // what if bgcolor is changed in tm model
      this.distinctTmRecords[localstoreKey].tmData = tm;
    }
    this.eventsData.push(this.distinctTmRecords[localstoreKey]);
  }

  /**
   * Builds the Dom Node that will contain our SingleEventComponent, handles to the call to destroy the event
   * components ref before creating another one. This is necessary due to the way that FullCalendar "redraws"
   * itself on resize, it essentially destroys and rebuilds the events from your data after the resize
   * is complete. Therefore we must do the same.
   */
  buildEventContainer(event: any): any {
    const id = event.event.id;
    this.singleEventService.destroyRef(id);
    const el = document.createElement('div');
    el.setAttribute('class', 'single-event-wrapper');
    this.singleEventService.addToCalendar(event, el, this.currentOnsiteUser);
    return { domNodes: [el] };
  }

  /**
   * Uses the FullCalendarApi to change the column headers based on the window size.
   */
  headerOptionsOnResize(): void {
    const calendarApi = this.calendarComponent.getApi();
    if (typeof calendarApi !== typeof undefined && calendarApi !== null) {

      if (window.innerWidth <= 600) {
        calendarApi.setOption('dayHeaderFormat', { weekday: 'narrow' });
      } else if (window.innerWidth <= 1200) {
        calendarApi.setOption('dayHeaderFormat', { weekday: 'short' });
      } else {
        calendarApi.setOption('dayHeaderFormat', { weekday: 'long' });
      }
    }
  }

  public get currentMonth(): string {
    return this.datePipe.transform(this.calendarComponent?.getApi()?.view?.currentStart, 'MMMM') || '';
  }

  public get currentMonthMobile(): string {
    return this.datePipe.transform(this.calendarComponent?.getApi()?.view?.currentStart, 'MMM') || '';
  }

  public get currentYear(): string {
    return this.datePipe.transform(this.calendarComponent?.getApi()?.view?.currentStart, 'yyyy') || '';
  }

  public get currentYearMobile(): string {
    return this.datePipe.transform(this.calendarComponent?.getApi()?.view?.currentStart, 'YY') || '';
  }

  public showPreviousMonth(): void {
    this.calendarComponent.getApi().prev();
  }

  public showThisMonth(): void {
    this.calendarComponent.getApi().today();
    this.todayEffect();
  }

  public showNextMonth(): void {
    this.calendarComponent.getApi().next();
  }

  public todayEffect(): void {
    const today = document.querySelectorAll('.fc-day-today .fc-daygrid-day-top');

    if (today.length) {
      today[0].scrollIntoView({behavior: 'smooth', block: 'center'});

      const rippleEl = document.createElement('div');
      rippleEl.classList.add('ripple-effect');

      today[0].appendChild(rippleEl);
      rippleEl.setAttribute('data-start-today-animation', 'true');

      window.setTimeout(() => {
        rippleEl.removeAttribute('data-start-today-animation');
        today[0].removeChild(rippleEl);
      }, 1000);
    }
  }

  public get canShowSelectedTeamMembers(): boolean {
    return this.selectedTeamMembers && this.selectedTeamMembers.length > 0;
  }

  public onTeamMemberSelected(selectedTeamMember: TeamMemberSearchOption): void {
    if (this.selectedTeamMembers.find(i => i.commonId === selectedTeamMember.commonId)
      || selectedTeamMember.commonId === this.currentUser.commonId) {
      return;
    }
    selectedTeamMember.teamMember.bgColorIndex = this.getTeamMemberBgColorIndex(this.selectedTeamMembers.length);
    this.selectedTeamMembers = [...this.selectedTeamMembers, selectedTeamMember];
    this.selectedTeamMembersChange.emit(this.selectedTeamMembers);
    this.fetchEvents();
  }

  public onUnselectTeamMember(teamMember: TeamMemberSearchOption): void {
    this.selectedTeamMembers.splice(this.selectedTeamMembers.indexOf(teamMember), 1);
    this.selectedTeamMembers = [...this.selectedTeamMembers];
    for (let i = 0; i < this.selectedTeamMembers.length; i++) {
      this.selectedTeamMembers[i].teamMember.bgColorIndex = this.getTeamMemberBgColorIndex(i);
    }
    this.selectedTeamMembersChange.emit(this.selectedTeamMembers);
    this.fetchEvents();
  }

  public getTeamMemberBgColorIndex(index: number): '1' | '2' | '3' | '4' | '5' | '6' {
    index = index % 6;
    switch (index) {
      case 0:
        return '2';
      case 1:
        return '3';
      case 2:
        return '4';
      case 3:
        return '5';
      case 4:
        return '6';
      case 5:
        return '1';
      default:
        return '1';
    }
  }

  public get customCalendarClasses(): string {
    let classes = this.isCalendarLoading ? 'loading-calendar' : '';

    if (this.bookingsFeatureFlag) {
      classes += this.isCalendarLoading ? ' bookings-enabled' : 'bookings-enabled';
    }

    return classes;
  }

  reload = (): void => {
    this.distinctTmRecords = {};
    this.fetchEvents();
  }
}
