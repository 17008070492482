<mat-sidenav-container>
    <div id="site-container" class="site-container">
        <app-header></app-header>
        <main class="clearfix main-container">
            <div class="container">
                <router-outlet></router-outlet>
            </div>
        </main>
        <app-footer></app-footer>
    </div>
</mat-sidenav-container>
