import { ScreenSize } from './enums';

export class DeviceSettings {
    private static largeWidthThreshold = 1200;
    private static mediumWidthThreshold = 900;
    private static smallWidthThreshold = 600;

    public static GetScreenSize(windowWidth: number): ScreenSize {
        let screenSize = ScreenSize.xl;

        if (this.IsExtraLarge(windowWidth)) {
            screenSize = ScreenSize.xl;
        } else if (this.IsLarge(windowWidth)) {
            screenSize = ScreenSize.large;
        } else if (this.IsMedium(windowWidth)) {
            screenSize = ScreenSize.medium;
        } else if (this.IsSmall(windowWidth)) {
            return ScreenSize.small;
        }

        return screenSize;
    }

    private static IsExtraLarge(windowWidth: number): boolean {
        return windowWidth > this.largeWidthThreshold;
    }

    private static IsLarge(windowWidth: number): boolean {
        return windowWidth <= this.largeWidthThreshold && windowWidth > this.mediumWidthThreshold;
    }

    private static IsMedium(windowWidth: number): boolean {
        return windowWidth <= this.mediumWidthThreshold && windowWidth > this.smallWidthThreshold;
    }

    private static IsSmall(windowWidth: number): boolean {
        return windowWidth <= this.smallWidthThreshold;
    }
}
