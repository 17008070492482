import {
    Component,
    ComponentFactoryResolver,
    Inject,
    OnDestroy,
    OnInit,
    Optional,
    ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BookingDialogDirective } from 'src/app/directives/booking-dialog.directive';
import { Booking } from 'src/app/models/booking';
import { BookingDialogPage } from 'src/app/models/booking-dialog-page';
import { CreateBookingActionData } from 'src/app/models/create-booking-action-data';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { BookingService } from 'src/app/services/booking/booking.service';
import {
    DialogBookingConfirmationComponent
} from './dialog-booking-confirmation/dialog-booking-confirmation.component';
import {
    DialogBookingSuccessComponent
} from './dialog-booking-success/dialog-booking-success.component';
import { ScheduleVisitComponent } from './dialog-schedule-visit/dialog-schedule-visit.component';

@Component({
  selector: 'app-dialog-create-booking',
  template: `<div class="create-booking-wrapper">
    <ng-template appBookingDialog></ng-template>
  </div>`,
  styleUrls: ['./dialog-create-booking.component.scss'],
})
export class DialogCreateBookingComponent implements OnInit, OnDestroy {
  @ViewChild(BookingDialogDirective, { static: true })
  bookingDialog!: BookingDialogDirective;

  private componentRefsMap: Map<string, any> = new Map();
  private reloadCalendar: () => void;
  private scheduledDays: string[] = [];
  private selectedDate: string;
  private currentUser: OnsiteUser;
  private bookings: Booking[] = [];
  private pageIndex = 0;
  private pages: BookingDialogPage[] = [
    {
      component: ScheduleVisitComponent,
    },
    {
      component: DialogBookingConfirmationComponent,
      saveOnSubmit: true
    },
    {
      component: DialogBookingSuccessComponent,
    }
  ];

  constructor(
    @Optional()
    @Inject(MatDialogRef)
    public dialogRef: MatDialogRef<BookingDialogPage>,

    @Inject(ComponentFactoryResolver)
    protected componentFactoryResolver: ComponentFactoryResolver,

    @Inject(MAT_DIALOG_DATA)
    public data: any,

    @Inject('BookingService')
    private bookingService: BookingService
  ) {
    this.currentUser = data.currentUser;
    this.reloadCalendar = data.reloadCalendar;
    this.selectedDate = data.selectedDate;
    this.scheduledDays = data.scheduledDays;
  }

  ngOnInit(): void {
    this.loadDialogContent();
  }

  ngOnDestroy(): void {
    for (const [_, value] of this.componentRefsMap) {
      value.destroy();
    }
  }

  private async nextPage(actionData: CreateBookingActionData): Promise<void> {
    this.disableSubmitButton();

    if (actionData?.data?.selectedDates != null) {
      this.createBookings(actionData.data.selectedDates);
    }

    const doSave = !!this.pages[this.pageIndex].saveOnSubmit;
    const saveSuccess = doSave && await this.saveBookings();

    if (!doSave || saveSuccess) {
      this.pageIndex++;
      this.loadDialogContent();
    } else if (doSave && !saveSuccess) {
      this.enableSubmitButton();
    }
  }

  private previousPage(): void {
    this.pageIndex--;
    this.loadDialogContent();
  }

  private async saveBookings(): Promise<boolean> {
    const saveBookingAPICalls: Observable<unknown>[] = this.bookings
      .map((booking: Booking) => this.bookingService.saveBooking(booking));

    try {
      await combineLatest(saveBookingAPICalls)
        .pipe(
          tap(() => this.reloadCalendar())
        )
        .toPromise();

      return true;
    } catch (err) {
      console.error(err);

      return false;
    }
  }

  private createBookings(dates: string[]) {
    this.bookings = dates.map((date: string): Booking => {
      return {
        bookingId: '',
        bookingHolderId: this.currentUser.commonId,
        endDate: `${date}T23:59:59.9999999Z`,
        reason: '',
        scheduleType: 'temporary',
        startDate: `${date}T00:00:00.0000000Z`,
        spaceType: 'other',
        usersTimeZone: DateTime.now().toFormat('z'),
      };
    });
  }

  private loadDialogContent(): void {
    if (this.pageIndex >= this.pages.length) {
      this.dialogRef.close();
      return;
    }

    const component = this.componentFactoryResolver.resolveComponentFactory(
      this.pages[this.pageIndex].component
    );

    this.bookingDialog.viewContainerRef.clear();

    const componentRef = this.bookingDialog.viewContainerRef.createComponent(component);

    componentRef.instance.data = {
      currentUser: this.currentUser,
      bookings: this.bookings,
      selectedDate: this.selectedDate,
      scheduledDays: this.scheduledDays,
    };


    componentRef.instance.backClick.subscribe(() =>
      this.previousPage()
    );
    componentRef.instance.nextClick.subscribe(($event: CreateBookingActionData) =>
      this.nextPage($event)
    );

    this.componentRefsMap.set(
      `id-${this.pageIndex}`,
      componentRef
    );
  }

  private enableSubmitButton(): void {
    document
      .getElementById('submit-booking-button')
      ?.removeAttribute('disabled');
  }

  private disableSubmitButton(): void {
    document
      .getElementById('submit-booking-button')
      ?.setAttribute('disabled', 'true');
  }
}
