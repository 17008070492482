<header class="header">
    <mat-toolbar>
        <div class="container">
            <app-logo [logoType]="'header'"></app-logo>
            <div fxFlex fxLayout fxLayoutAlign="end" fxShow fxHide.lt-md>
                <ul fxLayout="row" fxLayoutGap="60px" class="navigation-items">
                    <li *ngIf=isDashboardAdminVisible><a [routerLinkActive]="'active'"
                            [routerLinkActiveOptions]="{exact: true}" routerLink="/schedule-request/dashboard">My
                            Dashboard</a></li>
                    <li><a [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}" routerLink="/">My
                            Schedule</a></li>
                    <li *ngIf="!isViewingChangeComplexSchedulePage"><a [routerLinkActive]="'active'"
                            [routerLinkActiveOptions]="{exact: true}" routerLink="/schedule-request">Request Schedule
                            Change</a></li>
                    <li *ngIf="isViewingChangeComplexSchedulePage"><a [routerLinkActive]="'active'"
                            [routerLinkActiveOptions]="{exact: true}"
                            routerLink="/request-schedule-change-complex">Request Schedule
                            Change</a></li>
                </ul>
            </div>

            <div *ngIf="teamMember" fxLayoutAlign="end" fxFlexAlign="center" fxShow fxHide.lt-md>
                <app-tm-badge [teamMember]="teamMember" [noTooltip]="true" [size]="'md'"></app-tm-badge>
            </div>

            <div fxFlex fxLayout="row" fxLayoutAlign="end" fxHide fxShow.lt-md>
                <button [matMenuTriggerFor]="mobileMenu" #mobileMenuTrigger="matMenuTrigger" class="mobile-menu-button"
                    mat-icon-button fxFlexAlign="center">
                    <mat-icon class="mobile-menu-icon">
                        <div [innerHTML]="mobileMenuIcon"></div>
                    </mat-icon>
                </button>
            </div>

        </div>
    </mat-toolbar>
</header>
<mat-menu #mobileMenu class="mobile-header-menu">
    <div fxLayout="row">
        <div fxLayout="column" fxLayoutGap="2px" class="links-column navigation-items">
            <div *ngIf=isDashboardAdminVisible class="mobile-menu-link">
                <a class="link-text" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}"
                    routerLink="/schedule-request/dashboard">My
                    Dashboard
                </a>
            </div>
            <div class="mobile-menu-link">
                <a class="link-text" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}"
                    routerLink="/">My Schedule
                </a>
            </div>
            <div *ngIf="!isViewingChangeComplexSchedulePage" class="mobile-menu-link">
                <a class="link-text" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}"
                    routerLink="/schedule-request">Request Schedule Change
                </a>
            </div>
            <div *ngIf="isViewingChangeComplexSchedulePage" class="mobile-menu-link">
                <a class="link-text" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}"
                    routerLink="/request-schedule-change-complex">Request Schedule Change
                </a>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start" class="badge-column">
            <app-tm-badge [teamMember]="teamMember" [noTooltip]="true" [size]="'static-md'" class="badge-icon-mobile-header">
            </app-tm-badge>
        </div>
    </div>
</mat-menu>
