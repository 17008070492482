import { Component, EventEmitter, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { Booking } from 'src/app/models/booking';
import { CreateBookingMetadata } from 'src/app/models/create-booking-metadata';
import IDialogCreateModal from '../dialog-create-modal';

@Component({
  selector: 'app-dialog-booking-success',
  templateUrl: './dialog-booking-success.component.html',
})
export class DialogBookingSuccessComponent implements IDialogCreateModal {
  @Output() backClick: EventEmitter<undefined> = new EventEmitter();
  @Output() nextClick: EventEmitter<unknown> = new EventEmitter();

  public data!: CreateBookingMetadata;

  public next(): void {
    this.nextClick.emit({});
  }

  public getDates(): string[] {
    const dates: string[] = [];

    return this.data && this.data.bookings && this.data.bookings.length
      ? this.data.bookings.map((booking: Booking) =>
          this.getBaseDate(booking.startDate)
        )
      : dates;
  }

  public getDateMessage(): string {
    const dates = this.getDates();

    let message = '';

    const formattedDates = dates.map((date: string) => {
      const formattedDate = DateTime.fromISO(date, { zone: 'utc' });

      return formattedDate.setLocale('utc').toLocaleString(DateTime.DATE_SHORT);
    });

    if (formattedDates.length > 1) {
      const last = formattedDates.pop();
      message = formattedDates.join(', ') + ' and ' + last;
    } else {
      message += formattedDates.join(', ');
    }

    return message;
  }

  private getBaseDate(date: string): string {
    return date.split('T')[0];
  }
}
