import { Inject } from '@angular/core';
import { IEventTeamMember } from './calendar-interfaces';
import { OnsiteUser } from './onsite-user';

export class SingleEventModel {
  public teamMembers: Map<string, IEventTeamMember> = new Map();
  public date!: string;
  public currentUser!: OnsiteUser;
  public reloadCalendar!: any;
  public scheduledDays: string[] = [];

  constructor(@Inject('EVENT_DATE') date: string, @Inject('CURRENT_USER') currentUser: OnsiteUser) {
    this.date = date;
    this.currentUser = currentUser;
  }

  public setDate(date: string): void {
    this.date = date;
  }

  public addTeamMember(teamMember: IEventTeamMember): void {
    this.teamMembers.set(teamMember.commonId, teamMember);
  }

  public removeTeamMember(commonId: string): void {
    if (this.teamMembers.has(commonId)) {
      this.teamMembers.delete(commonId);
    }
  }

  public isUserInTeamMembers(commonId: string): boolean {
    return this.teamMembers.has(commonId);
  }

  public teamMembersToArray(): Array<IEventTeamMember> {
    const arr: Array<IEventTeamMember> = [];
    this.teamMembers.forEach((tm: IEventTeamMember, key: string) => {
      arr.push(tm as IEventTeamMember);
    });
    return arr;
  }
}
