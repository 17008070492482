import { IOnsiteAuthService } from './onsite-auth-service.interface';
import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs/operators';
import { containsAny } from './utilities';
import { OnsiteUser } from '../models/onsite-user';
@Injectable({
    providedIn: 'root',

})
export class OnsiteAuthService implements IOnsiteAuthService {

    constructor(
        @Inject('AuthService') private auth: AuthService,
    ) { }

    public getUser$(): Observable<OnsiteUser> {
        return combineLatest([
            this.auth.user$,
            this.hasLeaderImpersonationAccess$()
        ]).pipe(
            map(([user, hasLeaderImpersonationAccess]) => {
                return new OnsiteUser(user, hasLeaderImpersonationAccess);
            })
        );
    }

    public hasScheduleAdminAccess$(): Observable<boolean> {
        return this.auth.user$.pipe(
            map((user) => this.isUserPartOfGroups(user, environment.auth.scheduleAdminGroups))
        );
    }

    public hasParkingAdminAccess$(): Observable<boolean> {
        return this.auth.user$.pipe(
            map((user) => this.isUserPartOfGroups(user, environment.auth.parkingAdminGroups))
        );
    }

    public hasWorkspaceAdminAccess$(): Observable<boolean> {
        return this.auth.user$.pipe(
            map((user) => this.isUserPartOfGroups(user, environment.auth.workspaceAdminGroups))
        );
    }

    public hasOnsiteApprovalAdminAccess$(): Observable<boolean> {
        return this.auth.user$.pipe(
            map((user) => this.isUserPartOfGroups(user, environment.auth.onsiteApprovalAdminGroups))
        );
    }

    public hasEngineeringTeamAccess$(): Observable<boolean> {
        return this.auth.user$.pipe(
            map((user) => this.isUserPartOfGroups(user, environment.auth.engineeringTeamGroups))
        );
    }

    private isUserPartOfGroups(user: any, groups: string[]): boolean {
        const usersGroups = user ? user['https://ql.custom.openid.com/groups'] || [] : [];
        return containsAny(groups, usersGroups);
    }

    private hasLeaderImpersonationAccess$(): Observable<boolean> {
        return this.auth.user$.pipe(
            map((user) => this.isUserPartOfGroups(user, environment.auth.impersonationLeaderGroup))
        );
    }

    public isLoggedIn$(): Observable<boolean> {
        return this.auth.isAuthenticated$;
    }

    public login(redirect?: string): Observable<void> {
        return this.auth.loginWithRedirect({
            connection: environment.auth.connection,
            appState: { target: redirect }
        });
    }

    public clientBrowserLogout(): void {
        this.auth.logout({
            localOnly: true
        });
    }
}
