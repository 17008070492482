import { Inject, Injectable } from '@angular/core';
import { IOnsiteAuthService } from './onsite-auth-service.interface';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OnsiteApprovalAdminGuard implements CanActivate {
  constructor(
    @Inject('OnsiteAuthService') private authService: IOnsiteAuthService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    {
      return this.authService.hasOnsiteApprovalAdminAccess$().pipe(
        map((isAdmin) => {
          if (!isAdmin) {
            this.router.navigate(['']);
          }
          return isAdmin;
        })
      );
    }
  }
}
