export const environment = {
  production: false,
  contactEmail: 'mailto:RCDSoftwareEngineeringSupport@rockcentraldetroit.com?subject=Onsite Schedule App',
  complexFeatureFlag: 'true',
  teamLeaderFeatureFlag: 'true',
  bookingsFeatureFlag: 'true',
  auth: {
    domain: 'sso.test.authrock.com',
    clientId: 'M3d72YjTPq170E1hLVxGJVQYcRfwQ15o',
    audience: 'urn:ql-api:onsite_ui_bff_dev-208415:Test',
    redirect: `${window.location.origin}`,
    scheduleAdminGroups: ['OnsiteApp Schedule Admins Non-Prod'],
    parkingAdminGroups: ['OnsiteApp Parking Admins Non-Prod'],
    workspaceAdminGroups: ['OnsiteApp Workspace Admins Non-Prod'],
    onsiteApprovalAdminGroups: ['OnsiteApp OnsiteApproval Admins Non-Prod'],
    engineeringTeamGroups: ['OnsiteApp Schedule Admins Non-Prod'],
    impersonationLeaderGroup: ['OnsiteApp Impersonation Non-Prod'],
    connection: 'azure-mi-corp-rockfin-com'
  },
  api: {
    scheduleApi: {
      url: 'https://schedule-api-dev.onsite-np.foc.zone/api',
    },
    supportApi: {
      url: 'https://onsitesupportapi-dev.backbone-np.foc.zone/api',
    },
  },
  iOffice: {
    url: 'https://test-rockfoc.iofficeconnect.com/home.i#/'
  }
};
