import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestMetadata } from 'src/app/models/request-metadata';
import { ScheduleChangeRequestApiResponse } from 'src/app/models/schedule-change-request-api-response';
import { ScheduleChangeRequestTicket } from 'src/app/models/schedule-change-request-ticket';
import { ScheduleChangeRequestTicketApiResponse } from 'src/app/models/schedule-change-request-ticket-api-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminDashboardService {
  constructor(private http: HttpClient) { }

  adminRequestUrl = `${environment.api.scheduleApi.url}/v1/admin/requests/`;
  requestUrl = `${environment.api.scheduleApi.url}/v1/requests/`;
  metadataUrl = `${this.requestUrl}metadata`;

  isApiError = false;

  public findScheduleChangeRequests(
    limit: string,
    sort: string,
    filter: string | undefined,
    lastEvaluatedKey: string | undefined
  ): Observable<any> {
    const params = this.makeHttpParams(limit, sort, filter, lastEvaluatedKey);

    return this.http.get<ScheduleChangeRequestApiResponse>(this.metadataUrl, { params })
      .pipe(map((res: ScheduleChangeRequestApiResponse) => {
        res.metadata.forEach((scheduleRequest: any) => {
          this.mapScheduleChangeRequestApiResponse(scheduleRequest);
        });

        return res;
      }));
  }

  public getRequestTicketData(requestId: string): Observable<ScheduleChangeRequestTicket> {
    return this.http.get<ScheduleChangeRequestTicketApiResponse>(`${this.adminRequestUrl}${requestId}`).pipe(
      map((res: ScheduleChangeRequestTicketApiResponse) => {
        return this.mapScheduleChangeRequestTicket(res);
      }));
  }

  public assignTicket(requestId: string, teamMemberId: string): Observable<any> {
    return this.http
      .patch(
        `${this.adminRequestUrl}${requestId}/metadata/${requestId}`, { assignedToId: teamMemberId }
      );
  }

  public approveTicket(metadata: RequestMetadata, adminComment: string): Observable<any> {
    const data = {
      actionType: 'Approved',
      reason: adminComment
    };

    return this.http
      .post(
        `${this.adminRequestUrl}${metadata.requestId}/actions`, data
      );
  }

  public denyTicket(metadata: RequestMetadata, adminComment: string): Observable<any> {
    const data = {
      actionType: 'Denied',
      reason: adminComment
    };

    return this.http
      .post(
        `${this.adminRequestUrl}${metadata.requestId}/actions`, data
      );
  }

  private convertCommonIdsToStrings(res: ScheduleChangeRequestTicket): ScheduleChangeRequestTicket {
    res.metadata.scheduleHolder.commonId = res.metadata.scheduleHolder.commonId.toString();

    if (res.metadata.scheduleHolder.teamMemberJobs?.length > 0 &&
      res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader) {
      res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader.commonId =
        res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader.commonId.toString();
    }

    if (res.metadata.requester) {
      res.metadata.requester.commonId = res.metadata.requester.commonId.toString();
    }

    if (res.metadata.assignedToId) {
      res.metadata.assignedToId = res.metadata.assignedToId.toString();
    }

    if (res.metadata.assignedTo) {
      res.metadata.assignedTo.commonId = res.metadata.assignedTo.commonId.toString();
    }

    if (res.actions?.length > 0) {
      res.actions.forEach((action) => {
        if (action.actor) {
          action.actor.commonId = action.actor.commonId.toString();
        }
      });
    }

    return res;
  }

  private mapScheduleChangeRequestTicket(res: ScheduleChangeRequestTicketApiResponse): ScheduleChangeRequestTicket {
    res.metadata.scheduleHolder.firstName = res.metadata.scheduleHolder.preferredFirstName;
    res.metadata.scheduleHolder.lastName = res.metadata.scheduleHolder.preferredLastName;
    delete res.metadata.scheduleHolder.preferredFirstName;
    delete res.metadata.scheduleHolder.preferredLastName;

    if (res.metadata.assignedTo) {
      res.metadata.assignedTo.firstName = res.metadata.assignedTo.preferredFirstName;
      res.metadata.assignedTo.lastName = res.metadata.assignedTo.preferredLastName;
      delete res.metadata.assignedTo.preferredFirstName;
      delete res.metadata.assignedTo.preferredLastName;
    }

    if (res.metadata.requester) {
      res.metadata.requester.firstName = res.metadata.requester.preferredFirstName;
      res.metadata.requester.lastName = res.metadata.requester.preferredLastName;
      delete res.metadata.requester.preferredFirstName;
      delete res.metadata.requester.preferredLastName;
    }

    if (res.metadata.scheduleHolder.teamMemberJobs.length && res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader) {
      res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader.firstName =
        res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader.preferredFirstName;

      res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader.lastName =
        res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader.preferredLastName;

      delete res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader.preferredFirstName;
      delete res.metadata.scheduleHolder.teamMemberJobs[0].teamLeader.preferredLastName;
    }

    res.actions.forEach((action: any) => {
      if (action.actor) {
        action.actor.firstName = action.actor.preferredFirstName;
        action.actor.lastName = action.actor.preferredLastName;
        delete action.actor.preferredFirstName;
        delete action.actor.preferredLastName;
      }
    });

    return this.convertCommonIdsToStrings(res);
  }

  private mapScheduleChangeRequestApiResponse(scheduleRequest: any): void {
    scheduleRequest.scheduleHolder.commonId = scheduleRequest.scheduleHolder.commonId.toString();

    scheduleRequest.scheduleHolder.firstName = scheduleRequest.scheduleHolder.preferredFirstName;
    scheduleRequest.scheduleHolder.lastName = scheduleRequest.scheduleHolder.preferredLastName;
    delete scheduleRequest.scheduleHolder.preferredFirstName;
    delete scheduleRequest.scheduleHolder.preferredLastName;

    if (scheduleRequest.assignedTo) {
      scheduleRequest.assignedTo.firstName = scheduleRequest.assignedTo.preferredFirstName;
      scheduleRequest.assignedTo.lastName = scheduleRequest.assignedTo.preferredLastName;
      delete scheduleRequest.assignedTo.preferredFirstName;
      delete scheduleRequest.assignedTo.preferredLastName;
    }

    if (scheduleRequest.requesterId) {
      scheduleRequest.requesterId = scheduleRequest.requesterId.toString();
    }
  }

  private makeHttpParams(
    limit: string,
    sort: string,
    filter: string | undefined,
    lastEvaluatedKey: string | undefined
  ): HttpParams {
    let params = new HttpParams();

    params = this.setIfTruthy(params, 'filter', filter);
    params = this.setIfTruthy(params, 'limit', limit);
    params = this.setIfTruthy(params, 'sort', sort);
    params = this.setIfTruthy(params, 'lastEvaluatedKey', lastEvaluatedKey);

    return params;
  }

  private setIfTruthy(
    params: HttpParams,
    key: string,
    value: string | undefined
  ): HttpParams {
    if (value) {
      params = params.set(key, value);
    }

    return params;
  }
}
