import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class ScheduleUtilities {
  
  public determineTimeZone(): string {
    return DateTime.now().zoneName;
  }
  
  public getAdjustedDate(startDate: any): string {
    // this function is needed because the datepicker would convert the date selected to the previous
    // day if you were in a timezone where the time was early in the A.M. on one day
    // but UTC was still in the previous day
    // getMonth() is 0 based which is why we need to add 1 to the month.
    try {
      return DateTime.utc(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate())
        .toISO().toString().split('T')[0];
    } catch (error) { throw new Error('Input for getAdjustedDate is not valid'); }
  }

}
