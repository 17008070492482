<div class="search-component" fxLayout="row" fxLayoutAlign="start center">
  <label fxHide="true" fxHide.gt-sm="false" class="first-label" style="font-weight: bold; margin-right: 0.5rem;"
    [innerHTML]="label"></label>
  <div fxLayout="column" class="search-field-wrapper">
    <mat-form-field floatLabel="never" class="has-icon team-member-search-form-field">
      <label fxHide="false" fxHide.gt-sm="true" class="second-label" [innerHTML]="label"></label>
      <label fxHide="true" fxHide.gt-sm="false" class="second-label">&nbsp;</label>
      <input matInput type="text" [(ngModel)]="searchInput" (keyup)="searchInputOnKeyup()"
        (keydown)="searchInputOnKeydown()" (blur)="onTeamMemberSearchBlur()" (focus)="onTeamMemberSearchFocus()"
        autocomplete="off" [matTooltip]="searchToolTip" matTooltipPosition="above" matTooltipClass="big-tooltip"
        [disabled]="disabled ? disabled : false" [placeholder]="placeHolder">
      <div *ngIf="isSearchIconVisible" class="field-icon"><i class="fas fa-search"></i></div>
      <div *ngIf="isLoadingIconVisible" class="field-icon loading-icon"><i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </mat-form-field>
    <div *ngIf="isSearchResultsVisible" class="search-options">
      <div class="search-option" *ngFor="let option of searchResults" (mousedown)="selectTeamMember(option)">
        <div fxLayout="row" fxLayoutAlign="start center">
          <app-tm-badge [bg]="tmBadgeIconBgColorIndex" [size]="'static-md'" [teamMember]="option.teamMember"></app-tm-badge>
          <div class="search-details">
            <div><span class="title">{{option.title}} </span> <span class="job-title">({{option.jobTitle}})</span></div>
            <div class="contact-details"><u>{{option.contactDetails}}</u></div>
            <div class="organization-details">{{option.organizationDetails}}</div>
          </div>
          <div class="plus-icon" [matTooltip]="'Add'" matTooltipPosition="above"><i class="fas fa-plus"></i></div>
        </div>
      </div>
      <div *ngIf="noResultsFound" class="no-search-result search-option" fxLayout="row" fxLayoutAlign="center center">
        <div class="no-search-result-message-container" fxLayout="column" fxLayoutAlign="center start">
          <div class="no-search-result-message">
            Sorry, no Team Members match the name or email address you have entered. Please check your spelling and
            update your search.
          </div>
        </div>
        <div class="no-search-result-icon"><i class="fas fa-user-slash"></i></div>
      </div>
    </div>
  </div>
</div>
<app-overlay [Show]="isOverlayVisible"></app-overlay>
