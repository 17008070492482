import { Inject } from '@angular/core';
import { SingleEventModel } from './single-event';
import { IEventTeamMember } from './calendar-interfaces';
import { OnsiteUser } from './onsite-user';
import { SingleDateModel } from './single-date';

export class SingleEventModelFactory {
  data!: any;
  currentUser: OnsiteUser;

  constructor(@Inject('SINGLE_EVENT_FACTORY_EVENT_DATA') data: any, @Inject('FACTORY_CURRENT_USER') currentUser: OnsiteUser) {
    this.data = data;
    this.currentUser = currentUser;
  }

  public getModel(): SingleEventModel | SingleDateModel {
    if (this.data) {
      if (this.data.event && this.data.event.extendedProps) {
        const eventInfo = this.data.event.extendedProps;
        const model = new SingleEventModel(eventInfo.date || '', this.currentUser);
        const teamMembers = this.data.event.extendedProps.teamMembers || [];

        teamMembers.forEach((tm: IEventTeamMember) => {
          model.addTeamMember(tm);
        });

        model.reloadCalendar = this.data.reload;
        model.scheduledDays = this.data.scheduledDays;

        return model;

      } else if (this.data.date) {
        const model = new SingleDateModel(this.data.date || '', this.currentUser);
        model.reloadCalendar = this.data.reload;
        model.scheduledDays = this.data.scheduledDays;

        return model;
      }
    }

    throw new Error('The data passed into this objects constructor is formatted incorrectly.');
  }
}
