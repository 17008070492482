<h1>Update {{ teamMemberName }}'s Recurrence</h1>
<form [formGroup]="scheduleForm" (ngSubmit)="onSubmit()" fxLayout="column">
  <div class="section">
    <div class="section-title">On</div>
    <div class="section-content" formGroupName="recurrenceOn">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-between none">
        <mat-checkbox formControlName="isSunday">Sunday</mat-checkbox>
        <mat-checkbox formControlName="isMonday">Monday</mat-checkbox>
        <mat-checkbox formControlName="isTuesday">Tuesday</mat-checkbox>
        <mat-checkbox formControlName="isWednesday">Wednesday</mat-checkbox>
        <mat-checkbox formControlName="isThursday">Thursday</mat-checkbox>
        <mat-checkbox formControlName="isFriday">Friday</mat-checkbox>
        <mat-checkbox formControlName="isSaturday">Saturday</mat-checkbox>
      </div>
    </div>
  </div>
  <div>
    <mat-form-field>
      <label>Start</label>
      <input matInput [matDatepicker]="picker" formControlName="startDate" [min]="minDate" (click)="picker.open()" autocomplete="off"/>
      <div class="field-icon"><i class="fas fa-calendar-alt"></i></div>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field style="width: 100%;">
      <label>Reason</label>
      <div class="textarea-form-field">
        <textarea matInput class="materialize-textarea" formControlName="message"></textarea>
      </div>
    </mat-form-field>
  </div>
  <div style="margin-bottom: 60px;">
    <app-callout title="Reminder" type="default">
      <p>
        By submitting the request below, you are permanently changing a Team Member's recurring schedule.
      </p>
    </app-callout>
    <app-callout *ngIf="bannerErrorMessage" type="error" title="Error">
      <p>
        {{bannerErrorMessage}}
      </p>
    </app-callout>
  </div>
  <div class="centered" colspan="7" rowspan="1.5">
    <button routerLink="/request-schedule-change tertiary">Back</button>
    <button type="submit" [disabled]="!scheduleForm.valid" class="primary">Submit</button>
  </div>
</form>
