<div class="fancy-background"></div>
<div class="schedule-change-submit-success-wrapper">
    <div class="white-card">
        <h1>Thank You!</h1>
        <p><strong>Your request was submitted.</strong></p>
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center"
            fxLayoutGap="20px">
            <button mat-button class="secondary" routerLink="/schedule-request">Request Another Schedule Change</button>
            <button mat-button routerLink="/" class="primary">Return Home</button>
        </div>
    </div>
</div>
