<div class="request-history">
    <div>
        <h2>History</h2>
    </div>
    <div class="request-history-card__container"
        [ngClass]="historyData && historyData.assignedToId ? 'request-history-card__container-small' : ''">
        <div *ngIf="hasActions || hasRequestReason" class="request-history-card">
            <div *ngFor="let action of actions; index as index" fxLayout="row" fxLayoutAlign="start baseline"
                class="request-history-card__action">
                <div class="tm-history-badge" fxLayoutAlign="start start">
                    <app-tm-badge [teamMember]="getTeamMemberBadge(action.actor)"
                        [bg]="getBackground(action.actor.commonId)" [noTooltip]="true" [size]="'md'">
                    </app-tm-badge>
                </div>
                <div fxLayout="column" class="request-history-reason">
                    <div class="request-history-reason__message">
                        <p>{{action.reason}}</p>
                    </div>
                    <div class="request-history-action-type-and-date">
                        <span><span [innerHTML]="getActionMessage(action)"></span> -
                            {{formatActionCreateDate(action.createDate)}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="hasRequestReason" fxLayout="row" fxLayoutAlign="start baseline"
                class="request-history-card__action">
                <div class="tm-history-badge" fxLayoutAlign="start start">
                    <app-tm-badge [teamMember]="getTeamMemberBadge(historyData.requester)"
                        [bg]="getBackground(historyData.requester?.commonId)" [noTooltip]="true" [size]="'md'">
                    </app-tm-badge>
                </div>
                <div fxLayout="column" class="request-history-reason">
                    <div class="request-history-reason__message">
                        <p>{{historyData.requestReason}}</p>
                    </div>
                    <div class="request-history-action-type-and-date">
                        <span>
                            <span [innerHTML]="getRequestReasonMessage()"></span> -
                            {{formatActionCreateDate(historyData.requestDate)}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="historyData && !hasRequestReason && !hasActions" class="no-history">
            <i class="fas fa-history no-history__icon"></i>
            <div class="no-history__message">No History</div>
        </div>
    </div>
</div>
