<div class="remove-onsite-visit-modal">

    <div class="title-container">
        <div class="title h1">Remove Temporary Onsite Visit</div>
        <div class="dialog-close" [mat-dialog-close]="true"><i class="fa-regular fa-xmark"></i></div>
    </div>

    <mat-dialog-content>
        <div class="team-member-row current-user">
            <div class="links-col">
                <div class="confirm-removal-description">
                    Are you sure you want to remove this visit for
                    <strong>{{ fullDate }}</strong>?
                </div>
                <div *ngIf="bookingHasReservedSeating" class="desk-reservation-reminder">
                    <app-callout title="Note" id="tertiarty">
                        Remember to delete your desk reservation in <a class="long-link"
                            href="{{ iOfficeReservationURL }}" target="_blank"
                            rel="noopener">rockfoc.iofficeconnect.com</a> if you are no longer coming onsite.
                    </app-callout>

                </div>
                <div class="centered button-container">
                    <button mat-button id="submit-button" (click)="removeBooking()" class="primary submit-button"
                        [disabled]="isSaving">Yes</button>
                    <button mat-button id="return-button" (click)="cancel()" type="submit" class="bottom-button tertiary">
                        No
                    </button>
                </div>
            </div>
        </div>
    </mat-dialog-content>

</div>
