import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';
import { TeamMemberSearchOption } from 'src/app/models/team-member';
import { ScheduleChangeRequest } from 'src/app/models/schedule-change-request';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AdminDashboardService } from 'src/app/services/admin-dashboard/admin-dashboard.service';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { AdminDashboardDataSource } from 'src/app/services/admin-dashboard/admin-dashboard-data-source';
import { DatePipe } from '@angular/common';
import { RequestStatus, ScheduleStatusRequest } from 'src/app/utils/enums';
import { IOnsiteAuthService } from '../../../auth/onsite-auth-service.interface';
import { MatDialog } from '@angular/material/dialog';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { combineLatest, Observable, of } from 'rxjs';
import { ChangeRequestTeamMember } from 'src/app/models/change-request-team-member';
import { ParkingAndSeating } from 'src/app/models/scheduler-api-interfaces';
import { KeyValuePair } from 'src/app/models/key-value-pair';
import { ScheduleRequestTicketComponent } from '../schedule-request-ticket/schedule-request-ticket.component';
import { ITeamLeader } from 'src/app/models/team-leader';
import { ScheduleChangeRequestTicket } from 'src/app/models/schedule-change-request-ticket';

@Component({
  selector: 'app-schedule-request-table',
  templateUrl: './schedule-request-table.component.html',
  styleUrls: ['./schedule-request-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleRequestTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public selectedTeamMember: TeamMemberSearchOption | undefined;
  @Output() statusChanged: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginatorTop') topPaginator!: MatPaginator;
  @ViewChild('paginatorBottom') bottomPaginator!: MatPaginator;
  @ViewChild('paginatorBottomLabel') bottomPaginatorLabel!: MatPaginator;

  public dataSource!: AdminDashboardDataSource;
  public columnsToDisplay!: string[];
  public columns!: any[];
  public statusFilterSelectList!: any;
  public totalScheduleRequests = 0;
  public selectedStatusFilter!: string;
  public isOverlayVisible = false;
  public isStatusChanged = false;
  public isLoading = false;
  public requestTicketDetailHasError = false;
  public parkingAndWorkspaceHasError = false;
  public isModalOpen = false;
  private isParkingAdmin!: boolean;
  private isWorkspaceAdmin!: boolean;

  constructor(
    private adminDashboardService: AdminDashboardService,
    @Inject('DatePipe') private datePipe: DatePipe,
    @Inject('OnsiteAuthService') private authService: IOnsiteAuthService,
    @Inject('MatDialog') public dialog: MatDialog,
    @Inject('SchedulerService') private schedulerService: SchedulerService
  ) {
    this.dataSource = new AdminDashboardDataSource(this.adminDashboardService);
  }

  async ngOnInit(): Promise<void> {
    await this.setDefaults();
    this.loadInitialData();
  }

  ngAfterViewInit(): void {
    this.initSortEventListener();
    this.initPageSizeEventListener();
    this.initPageIndexEventListener();
    this.initDataSourceSubscription();
    this.setPaginatorLabels();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleTeamMemberSearchChanges(changes.selectedTeamMember);
  }

  public displayRequestTicketDetails(metadata: ScheduleChangeRequest): void {
    if (!this.isModalOpen) {
      this.isLoading = true;

      this.adminDashboardService.getRequestTicketData(metadata.requestId)
        .pipe(catchError(() => of(undefined)))
        .subscribe((details) => {
          if (details !== undefined && details.metadata !== undefined) {

            this.setParkingAndWorkspaceDataAndOpenModal(details);
          } else {
            this.handleScheduleApiError();
          }
        });
    }
  }
  
  public getStatusIcon(status: string): string {
    const statusEnumKey = this.getEnumKey(status);
    if (RequestStatus[statusEnumKey]) {
      if (
        RequestStatus[statusEnumKey] === RequestStatus.Completed || 
        RequestStatus[statusEnumKey] === RequestStatus.Denied || 
        RequestStatus[statusEnumKey] === RequestStatus.Separated
        ) {
        return 'fa-solid fa-book-open';
      }
    }

    return 'fas fa-edit';
  }

  public onDropdownToggle(isOpen: boolean): void {
    this.isOverlayVisible = isOpen;
  }

  public changeStatus(selectedStatus: string): void {
    this.selectedStatusFilter = selectedStatus;
    this.resetTableIndex();
    this.loadScheduleChangeRequestsPage();
    this.statusChanged.emit(this.selectedStatusFilter);
  }

  public get noScheduleChangeRequestsMessage(): string {
    let suffix = '';
    if (this.selectedStatusFilter === ScheduleStatusRequest['120:PENDINGWORKSPACE']
      || this.selectedStatusFilter === ScheduleStatusRequest['130:PENDINGPARKING']) {
      suffix = ' to Review';
    }

    return `Currently there are no ${this.selectedStatusFilter} requests${suffix}.`;
  }

  public get loadingOverlayHeight(): { [klass: string]: any; } | null {
    const tableElement = document.querySelector('table') as HTMLElement;
    const inlineStyle = { 
      'height.px': tableElement ? tableElement.scrollHeight : 0,
      'width.px': tableElement ? tableElement.scrollWidth : 0
    };
    return inlineStyle;
  }

  private initSortEventListener(): void {
    this.sort.sortChange.pipe(
      tap(() => {
        this.resetTableIndex();
        this.loadScheduleChangeRequestsPage();
      })).subscribe();
  }

  private initPageSizeEventListener(): void {
    this.topPaginator.page.pipe(
      tap(() => {
        this.bottomPaginator.pageSize = this.topPaginator.pageSize;
        this.bottomPaginatorLabel.pageSize = this.topPaginator.pageSize;
        this.resetTableIndex();
        this.loadScheduleChangeRequestsPage();
      })).subscribe();
  }

  private initPageIndexEventListener(): void {
    this.bottomPaginator.page.pipe(
      tap(() => {
        this.topPaginator.pageIndex = this.bottomPaginator.pageIndex;
        this.bottomPaginatorLabel.pageIndex = this.bottomPaginator.pageIndex;
        this.topPaginator.pageSize = this.bottomPaginator.pageSize;

        this.loadScheduleChangeRequestsPage();
      })).subscribe();
  }

  private initDataSourceSubscription(): void {
    this.dataSource.totalChangeRequests().subscribe(res => {
      this.totalScheduleRequests = res;
    });
  }

  private setPaginatorLabels(): void {
    this.topPaginator._intl.itemsPerPageLabel = 'Show';
    this.bottomPaginator._intl.getRangeLabel = (page: number, pageSize: number) => {
      const startingChangeRequestCount = page * pageSize + 1;
      const endingChangeRequestCount = Math.min((page * pageSize + pageSize), this.totalScheduleRequests);
      const hasChangeRequests = this.totalScheduleRequests > 0;

      return hasChangeRequests
        ? `Showing ${startingChangeRequestCount} to ${endingChangeRequestCount} of ${this.totalScheduleRequests}`
        : 'Showing 0 to 0 of 0';
    };
  }

  private handleTeamMemberSearchChanges(teamMemberChanges: SimpleChange): void {
    if (this.hasChanges(teamMemberChanges)) {
      this.resetTableIndex();
      this.loadScheduleChangeRequestsPage();

      if (!teamMemberChanges.currentValue) {
        this.statusChanged.emit(this.selectedStatusFilter);
      }
    }
  }

  private hasChanges(changes: SimpleChange): boolean {
    return !changes.firstChange && changes.currentValue !== changes.previousValue;
  }

  private loadScheduleChangeRequestsPage(): void {
    const filter = this.getFilter();
    const sort = `${this.sort.active}:${this.sort.direction}`;
    const limit = this.topPaginator.pageSize.toString();

    this.dataSource.loadScheduleChangeRequests(limit, sort, this.bottomPaginator.pageIndex, filter);
  }

  private getFilter(): string | undefined {
    let filter;

    if (this.selectedTeamMember) {
      filter = `scheduleHolderId:${this.selectedTeamMember.commonId}`;
    } else if (this.selectedStatusFilter) {
      filter = `requestStatus:${this.selectedStatusFilter}`;
    }

    return filter;
  }

  private resetTableIndex(): void {
    this.topPaginator.pageIndex = 0;
    this.bottomPaginator.pageIndex = 0;
    this.bottomPaginatorLabel.pageIndex = 0;
  }

  private loadInitialData(): void {
    const statusfilter = `requestStatus:${this.selectedStatusFilter}`;
    this.dataSource.loadScheduleChangeRequests('25', 'effectiveDate:asc', 0, statusfilter);
  }

  private setDefaultStatusFilter(): void {
    if (this.isWorkspaceAdmin || !this.isParkingAdmin) {
      this.selectedStatusFilter = ScheduleStatusRequest['120:PENDINGWORKSPACE'];
    } else {
      this.selectedStatusFilter = ScheduleStatusRequest['130:PENDINGPARKING'];
    }
    this.statusChanged.emit(this.selectedStatusFilter);
  }

  private setParkingAndWorkspaceDataAndOpenModal(details: ScheduleChangeRequestTicket): void {
    try {
      const teamLeader = this.getTeamLeader(details.metadata.scheduleHolder);

      this.loadSupportApiData(details.metadata.scheduleHolder.commonId,
        details.metadata.requesterId,
        teamLeader?.commonId ?? undefined)
        .subscribe(([scheduleHolderParkingAndSeating, teamLeaderParkingAndSeating, requesterParkingAndSeating]) => {
          if (scheduleHolderParkingAndSeating || teamLeaderParkingAndSeating || requesterParkingAndSeating) {
            this.setParkingAndWorkSpaceForTeamMember(
              details.metadata.scheduleHolder,
              scheduleHolderParkingAndSeating,
              details.metadata.createDate
            );
            if (teamLeader) {
              this.setWorkSpaceForTeamLeader(
                teamLeader,
                teamLeaderParkingAndSeating,
                details.metadata.createDate
              );
            }
            this.setWorkSpaceForRequester(
              details.metadata.requester,
              requesterParkingAndSeating,
              details.metadata.createDate
            );
            this.openTicketModal(details);
          }
        });
    } catch (ex) {
      this.handleSupportApiError(ex);
    }
  }

  private openTicketModal(details: ScheduleChangeRequestTicket): void {
    this.requestTicketDetailHasError = false;
    this.parkingAndWorkspaceHasError = false;
    this.isModalOpen = true;

    const dialogRef = this.dialog.open(ScheduleRequestTicketComponent, {
      width: '100%',
      maxWidth: '1280px',
      data: {
        metadata: details.metadata,
        schedules: details.schedules,
        actions: details.actions
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((isClosed: boolean | undefined) => {
      if (isClosed === undefined) {
        this.isModalOpen = false;
      } else {
        this.isModalOpen = !isClosed;
      }
    });

    dialogRef.componentInstance.onAssignTicket.subscribe((kvp: KeyValuePair) => {
      const requestId = kvp.key;
      const teamMember: any = JSON.parse(kvp.value);

      this.dataSource.updateAssignedTicket(requestId, teamMember);
    });

    dialogRef.componentInstance.onApproveOrDeny.subscribe(() => {
      const filter = this.getFilter();
      const sort = `${this.sort.active}:${this.sort.direction}`;
      const limit = this.topPaginator.pageSize.toString();

      this.dataSource.updateApprovedOrDeniedTicket(limit, sort, this.bottomPaginator.pageIndex, filter);
    });
  }

  private loadSupportApiData(
    scheduleHoldersTeamMemberId: string,
    requestersTeamMemberId: string,
    teamLeadersTeamMemberId?: string,
  ): Observable<any[]> {
    if (!requestersTeamMemberId) {
      this.handleSupportApiError('The requesterId is undefined');
      return of([]);
    }

    const scheduleHolderDetails = this.schedulerService.getParkingAndWorkplace(
      scheduleHoldersTeamMemberId
    );

    let teamLeaderDetails = of({});
    let requesterDetails = of({});

    if (teamLeadersTeamMemberId) {
      teamLeaderDetails = this.schedulerService.getParkingAndWorkplace(
        teamLeadersTeamMemberId
      );
    }

    if (parseInt(requestersTeamMemberId, 10)) {
      requesterDetails = this.schedulerService.getParkingAndWorkplace(
        requestersTeamMemberId
      );
    }

    return combineLatest([
      scheduleHolderDetails,
      teamLeaderDetails,
      requesterDetails
    ]).pipe(
      catchError((ex) => {
        this.handleSupportApiError(ex);
        return of([]);
      }),
      finalize(() => this.isLoading = false)
    );
  }

  private setParkingAndWorkSpaceForTeamMember(
    teamMember: ChangeRequestTeamMember,
    parkingAndSeating: ParkingAndSeating,
    createDate: string
  ): void {
    const scheduleHolderParkingAssignment = this.schedulerService.determineParkingAssignment(parkingAndSeating.ParkingAssignment,
      parkingAndSeating.ParkingRemediation,
      parkingAndSeating.ParkingCheckPassed,
      createDate);

    const scheduleHolderSeatingAssignment = this.schedulerService.determineSeatingAssignment(parkingAndSeating.SeatingAssignment,
      parkingAndSeating.SeatingRemediation,
      parkingAndSeating.SeatingCheckPassed,
      createDate);

    teamMember.parking = scheduleHolderParkingAssignment.label;
    teamMember.workSpace = scheduleHolderSeatingAssignment.label;
  }

  private setWorkSpaceForTeamLeader(teamLeader: ITeamLeader, parkingAndSeating: ParkingAndSeating, createDate: string): void {
    const teamLeaderSeatingAssignment = this.schedulerService.determineSeatingAssignment(parkingAndSeating.SeatingAssignment,
      parkingAndSeating.SeatingRemediation,
      parkingAndSeating.SeatingCheckPassed,
      createDate);

    teamLeader.workSpace = teamLeaderSeatingAssignment.label;
  }

  private setWorkSpaceForRequester(requester: ChangeRequestTeamMember, parkingAndSeating: ParkingAndSeating, createDate: string): void {
    if (requester) {
      const requesterSeatingAssignment = this.schedulerService.determineSeatingAssignment(parkingAndSeating.SeatingAssignment,
        parkingAndSeating.SeatingRemediation,
        parkingAndSeating.SeatingCheckPassed,
        createDate);

      requester.workSpace = requesterSeatingAssignment.label;
    }
  }

  private handleScheduleApiError(): void {
    this.requestTicketDetailHasError = true;
    this.handleTicketModalApiError();
  }

  private handleSupportApiError(ex: any): void {
    console.error(ex);
    this.parkingAndWorkspaceHasError = true;
    this.handleTicketModalApiError();
  }

  private handleTicketModalApiError(): void {
    this.isLoading = false;
    this.isModalOpen = false;
    this.scrollToTop();
  }

  private async setDefaults(): Promise<void> {
    this.isParkingAdmin = await this.authService.hasParkingAdminAccess$().pipe(take(1)).toPromise();
    this.isWorkspaceAdmin = await this.authService.hasWorkspaceAdminAccess$().pipe(take(1)).toPromise();

    this.columns = [
      {
        columnDef: 'requestStatus',
        header: 'Status',
        cell: (element: ScheduleChangeRequest) => `${element.requestStatus}`,
        sortDisabled: true
      },
      {
        columnDef: 'effectiveDate',
        header: 'Effective',
        cell: (element: ScheduleChangeRequest) => this.datePipe.transform(element.effectiveDate, 'MM/dd/yyyy'),
        sortDisabled: false
      },
      {
        columnDef: 'lastUpdateDate',
        header: 'Updated',
        cell: (element: ScheduleChangeRequest) => this.datePipe.transform(element.lastUpdateDate, 'MM/dd/yyyy'),
        sortDisabled: false
      },
      {
        columnDef: 'scheduleHolder',
        header: 'Name',
        cell: (element: ScheduleChangeRequest) =>
          `${element.scheduleHolder.firstName} ${element.scheduleHolder.lastName}`,
        sortDisabled: true
      },
      {
        columnDef: 'businessArea',
        header: 'Business Area',
        cell: (element: ScheduleChangeRequest) => {
          if (element.scheduleHolder.teamMemberJobs.length > 0 && element.scheduleHolder.teamMemberJobs[0].businessArea) {
            return element.scheduleHolder.teamMemberJobs[0].businessArea;
          }

          return '';
        },
        sortDisabled: true
      },
      {
        columnDef: 'requestType',
        header: 'Type',
        cell: (element: ScheduleChangeRequest) => `${element.requestType}`,
        sortDisabled: true
      },
      {
        columnDef: 'assignedTo',
        header: 'Assigned To',
        cell: (element: ScheduleChangeRequest) => element.assignedTo ?
          `${element.assignedTo.firstName} ${element.assignedTo.lastName}` :
          '—',
        sortDisabled: true
      }
    ];

    this.columnsToDisplay = this.columns.map(c => c.columnDef);
    this.columnsToDisplay.push('action');

    this.setDefaultStatusFilter();

    this.statusFilterSelectList = Object.values(ScheduleStatusRequest).map((r: string) => ({ name: r, value: r }));
  }

  private scrollToTop(): void {
    const header = document.querySelector('header.header');
    header?.scrollIntoView({ behavior: 'smooth' });
  }

  private getEnumKey(requestStatus: string): keyof typeof RequestStatus {
    return requestStatus as keyof typeof RequestStatus;
  }

  private getTeamLeader(scheduleHolder: ChangeRequestTeamMember): ITeamLeader | undefined {
    return scheduleHolder.teamMemberJobs.length > 0
      ? scheduleHolder.teamMemberJobs[0].teamLeader
      : undefined;
  }
}
