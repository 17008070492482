import { Component, Inject } from '@angular/core';
import { DateTime } from 'luxon';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dialog-remove-onsite-visit',
    templateUrl: './dialog-remove-onsite-visit.component.html',
    styleUrls: ['./dialog-remove-onsite-visit.component.scss']
})
export class DialogRemoveOnsiteVisitComponent {
    public bookingHasReservedSeating: boolean;
    public iOfficeReservationURL = '';
    public isSaving = false;

constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DialogRemoveOnsiteVisitComponent>,
        @Inject('SchedulerService') private schedulerService: SchedulerService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.iOfficeReservationURL = environment.iOffice.url + 'reservation/myreservations';
        this.bookingHasReservedSeating = this.data.hasReservedSeating;
    }

    public get fullDate(): string {
        return DateTime.fromISO(this.data.date).toLocaleString(DateTime.DATE_HUGE);
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public removeBooking(): void {
        if (this.data.booking) {
            this.isSaving = true;
            this.schedulerService.removeBookingData(this.data.booking).pipe(
                catchError((error: any) => {
                    return of({
                        message: error,
                        isError: true
                    });
                })
            ).subscribe((data: any) => {
                if (data && data.isError) {
                    this.isSaving = false;
                    console.error(data.message);
                } else {
                    this.dialogRef.close();
                    this.data.model.reloadCalendar();
                }
            });
        }
    }
}
