import { DataSource } from '@angular/cdk/collections';
import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AdminDashboardLastEvaluatedKey} from 'src/app/models/admin-dashboard-last-evaluated-key';
import { ChangeRequestTeamMember } from 'src/app/models/change-request-team-member';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { ScheduleChangeRequest } from 'src/app/models/schedule-change-request';
import { ScheduleChangeRequestData } from 'src/app/models/schedule-change-request-data';
import { AdminDashboardService } from 'src/app/services/admin-dashboard/admin-dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardDataSource implements DataSource<ScheduleChangeRequest> {
  private totalChangeRequestsSubject = new BehaviorSubject<number>(0);
  private changeRequestSubject = new BehaviorSubject<ScheduleChangeRequest[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private hasErrorSubject = new BehaviorSubject<boolean>(false);

  private lastEvaluatedKey!: AdminDashboardLastEvaluatedKey;
  private previouslyLoadedChangeRequestResponsesMap: Record<string, ScheduleChangeRequestData> = {};

  public loading$ = this.loadingSubject.asObservable();
  public hasError$ = this.hasErrorSubject.asObservable();

  constructor(@Inject('AdminDashboardService') private adminDashboardService: AdminDashboardService) { }

  public connect(): Observable<ScheduleChangeRequest[]> {
    return this.changeRequestSubject.asObservable();
  }

  public disconnect(): void {
    this.totalChangeRequestsSubject.complete();
    this.changeRequestSubject.complete();
    this.loadingSubject.complete();
    this.hasErrorSubject.complete();
  }

  public totalChangeRequests(): Observable<number> {
    return this.totalChangeRequestsSubject.asObservable();
  }

  public loadScheduleChangeRequests(
    limit: string,
    sort: string,
    pageNumber: number,
    filter: string | undefined
  ): void {
    this.hasErrorSubject.next(false);
    this.loadingSubject.next(true);

    const localStoreKey = this.getlocalStoreKey(limit, sort, filter ?? '-1', pageNumber);
    if (!this.previouslyLoadedChangeRequestResponsesMap[localStoreKey]) {
      this.loadScheduleChangeRequestsFromService(limit, sort, filter, pageNumber, localStoreKey);
    } else {
      const changeRequest = this.previouslyLoadedChangeRequestResponsesMap[localStoreKey];

      this.changeRequestSubject.next(changeRequest.metadata);
      this.totalChangeRequestsSubject.next(changeRequest.requestCount);
      this.lastEvaluatedKey = changeRequest.lastEvaluatedKey;
      this.loadingSubject.next(false);
    }
  }

  public updateAssignedTicket(requestId: string, teamMember: OnsiteUser): void {
    const changeRequest = this.changeRequestSubject.value.filter((scr) => scr.requestId === requestId)[0];

    if (changeRequest) {
      const assignedTo: ChangeRequestTeamMember = {
        commonId: teamMember.commonId,
        firstName: teamMember.firstName,
        lastName: teamMember.lastName,
        parking: '',
        rockHumanId: '',
        teamMemberJobs: [],
        workSpace: ''
      };

      changeRequest.assignedTo = assignedTo;
      changeRequest.lastUpdateDate = DateTime.now().toISODate();
      this.changeRequestSubject.next(this.changeRequestSubject.value);
    }
  }

  public updateApprovedOrDeniedTicket(limit: string, sort: string, pageNumber: number, filter: string | undefined): void {
    this.reloadCurrentPageData(limit, sort, pageNumber, filter);
    this.clearPagesAfterCurrent(limit, sort, pageNumber, filter);
    this.clearAllPagesExceptCurrentState(limit, sort, filter);
  }

  private getlocalStoreKey(
    limit: string,
    sort: string,
    filter: string,
    pageNumber: number
  ): string {
    return limit + ',' + sort + ',' + filter + ',' + pageNumber;
  }

  private getLastEvaluatedKey(pageNumber: number): string | undefined {
    return pageNumber === 0 ? undefined : JSON.stringify(this.lastEvaluatedKey);
  }

  private reloadCurrentPageData(limit: string, sort: string, pageNumber: number, filter: string | undefined): void {
    this.loadingSubject.next(true);

    pageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    const localStoreKey = this.getlocalStoreKey(limit, sort, filter ?? '-1', pageNumber);

    this.loadScheduleChangeRequestsFromService(limit, sort, filter, pageNumber, localStoreKey);
  }

  private clearPagesAfterCurrent(limit: string, sort: string, pageNumber: number, filter: string | undefined): void {
    if (pageNumber === 0) {
      return;
    }

    let localStoreKey = this.getlocalStoreKey(limit, sort, filter ?? '-1', pageNumber);
    const keysToRemove = [];

    while (this.previouslyLoadedChangeRequestResponsesMap[localStoreKey]) {
      keysToRemove.push(localStoreKey);

      pageNumber++;
      localStoreKey = this.getlocalStoreKey(limit, sort, filter ?? '-1', pageNumber);
    }

    const updatedMap: Record<string, ScheduleChangeRequestData> = {};

    for (const key in this.previouslyLoadedChangeRequestResponsesMap) {
      if (!keysToRemove.some((keyToRemove) => key === keyToRemove)) {
        updatedMap[key] = this.previouslyLoadedChangeRequestResponsesMap[key];
      }
    }

    this.previouslyLoadedChangeRequestResponsesMap = updatedMap;
  }

  private clearAllPagesExceptCurrentState(limit: string, sort: string, filter: string | undefined): void {
    const keys = Object.keys(this.previouslyLoadedChangeRequestResponsesMap);

    const keysToRemove = keys.filter((key) => {
      const properties = key.split(',');

      const thisLimit = properties[0];
      const thisSort = properties[1];
      const thisFilter = properties[2];

      return !(thisLimit === limit && thisSort === sort && thisFilter === filter);
    });

    const updatedMap: Record<string, ScheduleChangeRequestData> = {};

    for (const key in this.previouslyLoadedChangeRequestResponsesMap) {
      if (!keysToRemove.some((keyToRemove) => key === keyToRemove)) {
        updatedMap[key] = this.previouslyLoadedChangeRequestResponsesMap[key];
      }
    }

    this.previouslyLoadedChangeRequestResponsesMap = updatedMap;
  }

  private loadScheduleChangeRequestsFromService(
    limit: string,
    sort: string,
    filter: string | undefined,
    pageNumber: number,
    localStoreKey: string
  ): void {
    this.adminDashboardService.findScheduleChangeRequests(limit, sort, filter, this.getLastEvaluatedKey(pageNumber))
      .pipe(
        catchError(() => {
          this.hasErrorSubject.next(true);
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((changeRequests) => {
        this.changeRequestSubject.next(changeRequests.metadata);
        this.totalChangeRequestsSubject.next(changeRequests.requestCount);
        this.lastEvaluatedKey = changeRequests.lastEvaluatedKey;
        this.previouslyLoadedChangeRequestResponsesMap[localStoreKey] = changeRequests;
      });
  }
}
