<div class="request-details">
    <div>
        <h2>Details</h2>
    </div>
    <div>
        <mat-tab-group *ngIf="requestTicketDetails" mat-align-tabs="center" class="tm-details-tabs">
            <mat-tab>
                <ng-template mat-tab-label>
                    Team Member
                </ng-template>
                <div [hidden]="this.requestTicketDetails.teamMember?.teamMemberJobs?.length">
                    <app-callout title="Note" type="default" class="missing-tm-information-callout">
                        <p>{{requestTicketDetails.teamMember.firstName}}
                            {{requestTicketDetails.teamMember.lastName}}'s information is missing due
                            to separation.</p>
                    </app-callout>
                </div>
                <div [fxLayout]="showMobileLayout ? 'column' : 'row'"
                    [fxLayoutAlign]="showMobileLayout ? 'start start' : 'start center'" class="tm-details-tab">
                    <div [ngClass]="showMobileLayout ? 'tm-details-badge_mobile' : 'tm-details-badge'">
                        <app-tm-badge [teamMember]="requestTicketDetails.teamMemberBadge" [bg]="'6'" [noTooltip]="true"
                            [size]="'lg'"></app-tm-badge>
                    </div>
                    <div class="table">
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Name</div>
                            <div class="table-cell">{{requestTicketDetails.teamMember.firstName}}
                                {{requestTicketDetails.teamMember.lastName}}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Email</div>
                            <div class="table-cell">{{requestTicketDetails.teamMember.teamMemberJobs.length ?
                                requestTicketDetails.teamMember.teamMemberJobs[0].email : ''}}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Title</div>
                            <div class="table-cell">
                                {{requestTicketDetails.teamMember.teamMemberJobs.length ?
                                requestTicketDetails.teamMember.teamMemberJobs[0].displayJobTitle : ''}}
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Company</div>
                            <div class="table-cell">
                                {{requestTicketDetails.teamMember.teamMemberJobs.length ?
                                requestTicketDetails.teamMember.teamMemberJobs[0].company.displayName : ''}}
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Biz. Area</div>
                            <div class="table-cell">{{requestTicketDetails.teamMember.teamMemberJobs.length ?
                                requestTicketDetails.teamMember.teamMemberJobs[0].businessArea : ''}}
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Common ID</div>
                            <div class="table-cell tm-commonId-cell">
                                <span class="commonId-value-cell" (click)="toggleCommonIdShow($event)">{{showTmCommonId
                                    ? requestTicketDetails.teamMember.commonId :'. . . . . . . '}}</span>
                                <span>
                                    <div (click)="toggleCommonIdShow($event)" class="commond-id-locks">
                                        <div *ngIf="!showTmCommonId"><i class="fa-regular fa-lock"></i></div>
                                        <div *ngIf="showTmCommonId"><i class="fa-regular fa-unlock"></i></div>
                                    </div>
                                </span>
                                <span>
                                    <div [cdkCopyToClipboard]="requestTicketDetails.teamMember.commonId"
                                        (click)="showCopiedToolTip(tooltip)" #tooltip="matTooltip" matTooltip=""
                                        matTooltipPosition="above" class="copy-common-id-icon">
                                        <i class="fa-regular fa-copy"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Workspace</div>
                            <div class="table-cell">{{getTeamMemberWorkspace()}}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Parking</div>
                            <div class="table-cell">{{getTeamMemberParking()}}</div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Team Leader
                </ng-template>
                <div
                    [hidden]="requestTicketDetails.teamLeader && requestTicketDetails.teamLeader.teamMemberJobs.length">
                    <app-callout title="Note" type="default" class="missing-tm-information-callout">
                        <p>No Team Leader information available.</p>
                    </app-callout>
                </div>
                <div [fxLayout]="showMobileLayout ? 'column' : 'row'"
                    [fxLayoutAlign]="showMobileLayout ? 'start start' : 'start center'" class="tm-details-tab">
                    <div [ngClass]="showMobileLayout ? 'tm-details-badge_mobile' : 'tm-details-badge'">
                        <app-tm-badge [teamMember]="requestTicketDetails.teamLeaderBadge" [bg]="'6'" [noTooltip]="true"
                            [size]="'lg'"></app-tm-badge>
                    </div>
                    <div class="table">
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Name</div>
                            <div class="table-cell">{{requestTicketDetails.teamLeader?.firstName}}
                                {{requestTicketDetails.teamLeader?.lastName}}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Email</div>
                            <div class="table-cell">{{teamLeaderEmail}}
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Company</div>
                            <div class="table-cell">
                                {{teamLeadersCompany}}
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Biz. Area</div>
                            <div class="table-cell">{{teamLeadersBizArea}}
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Workspace</div>
                            <div class="table-cell">{{getTeamLeadersWorkspace()}}</div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [aria-label]="getRequesterIsLeaderClass()">
                <ng-template *ngIf="isRequesterTeamLeader" mat-tab-label>Requester</ng-template>
                <ng-template *ngIf="!isRequesterTeamLeader" mat-tab-label>
                    <div class="requester-tab__not-from-leader" [matTooltip]="'Request Not From Team Leader'"
                        matTooltipPosition="above" matTooltipClass="big-tooltip">
                        <mat-icon class="label-tab-icon">error</mat-icon>
                        <span>Requester</span>
                    </div>
                </ng-template>
                <div
                    *ngIf="!requestTicketDetails.requester || requestTicketDetails.requester.teamMemberJobs.length === 0">
                    <app-callout title="Note" type="default" class="missing-tm-information-callout">
                        <p>No Requester information is available, this could be due to separation.</p>
                    </app-callout>
                </div>
                <div [fxLayout]="showMobileLayout ? 'column' : 'row'"
                    [fxLayoutAlign]="showMobileLayout ? 'start start' : 'start center'" class="tm-details-tab">
                    <div [ngClass]="showMobileLayout ? 'tm-details-badge_mobile' : 'tm-details-badge'">
                        <app-tm-badge [teamMember]="requestTicketDetails.requesterBadge" [bg]="'6'" [noTooltip]="true"
                            [size]="'lg'"></app-tm-badge>
                    </div>
                    <div class="table">
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Name</div>
                            <div class="table-cell">{{requestTicketDetails.requester?.firstName ||
                                'Onsite'}}
                                {{requestTicketDetails.requester?.lastName || 'System'}}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Email</div>
                            <div class="table-cell">{{requestersEmail}}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Company</div>
                            <div class="table-cell">
                                {{requestersCompany}}
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Biz. Area</div>
                            <div class="table-cell">{{requestersBizArea}}
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell table-cell-label">Workspace</div>
                            <div class="table-cell">{{getRequestersWorkspace()}}</div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
