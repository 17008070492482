import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IAdGroupUploadService } from '../../interfaces';
import { environment } from '../../../environments/environment';
import { IBulkADUploadResponse } from 'src/app/models/bulk-ad-upload-response';

@Injectable({
  providedIn: 'root'
})
export class AdGroupUploadService implements IAdGroupUploadService {

  constructor(@Inject('HttpClient') private httpClient: HttpClient) {

  }

  public uploadFile(file: File): Promise<IBulkADUploadResponse> {
    const apiEndpoint = environment.api.supportApi.url;

    return this.httpClient
      .post<IBulkADUploadResponse>(apiEndpoint + `/v1/Uploads/OnsiteApproval`, file).toPromise();
  }
}
