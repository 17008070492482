import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IScheduleUploadService } from '../../interfaces';
import { UploadResponse } from '../../models/upload-response';
import { ScheduleUtilities } from '../schedule-utilities';

@Injectable()
export class ScheduleUploadService implements IScheduleUploadService {
    constructor(
      @Inject('HttpClient') private httpClient: HttpClient,
      @Inject('ScheduleUtilities') private scheduleUtilities: ScheduleUtilities
      ){         
      }

    uploadFile(file: File, isEndDateUpload: boolean): Promise<UploadResponse> {
      const apiEndpoint = environment.api.scheduleApi.url;

      const timeZone = this.scheduleUtilities.determineTimeZone();
      
      return this.httpClient
        .post<UploadResponse>(apiEndpoint + `/v1/schedules?isEndDateUpload=${ isEndDateUpload }&timeZone=${ timeZone }`, file).toPromise();
    }
}
