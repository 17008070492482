<div class="footer">
    <div class="container">
        <ul class="navigation-items">
            <li *ngIf=isDashboardAdminVisible><a [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}"
                    routerLink="/schedule-request/dashboard">My Dashboard</a></li>
            <li><a [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}" routerLink="/">My
                    Schedule</a></li>
            <li *ngIf="!isViewingChangeComplexSchedulePage"><a [routerLinkActive]="'active'"
                    [routerLinkActiveOptions]="{exact: true}" routerLink="/schedule-request">Request
                    Schedule Change</a></li>
            <li *ngIf="isViewingChangeComplexSchedulePage"><a [routerLinkActive]="'active'"
                    [routerLinkActiveOptions]="{exact: true}" routerLink="/request-schedule-change-complex">Request
                    Schedule Change</a></li>
            <li *ngIf="isScheduleAdminVisible"><a [routerLinkActive]="'active'"
                    [routerLinkActiveOptions]="{exact: true}" routerLink="/schedule-upload">Upload
                    Schedules</a></li>
            <li><a [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}"
                    routerLink="/contact-us">Contact
                    Us</a></li>
        </ul>
        <div class="rkt-footer">
            <app-logo [logoType]="'footer'"></app-logo>
        </div>
        <span>&copy; {{ year }} Rock Central, LLC. All Rights Reserved.</span>
    </div>
</div>
