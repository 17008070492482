import { DuplicateUserId } from 'src/app/models/duplicate-user-id';
import UploadErrorMessageEnum from '../../shared/upload-error-enums';

export const getTeamMemberDuplicateCommonIds = (
  duplicateRecord: Array<DuplicateUserId> | null | undefined
): string | null => {
  if (!duplicateRecord) {
    return null;
  }

  return duplicateRecord.map((s) => s.userId).join(', ');
};

export const generateNotificationMessage = (statusCode: number, hasErrors?: boolean): string => {
  let notificationMessage;

  switch (statusCode) {
    case 500:
      notificationMessage = `UPLOAD_ERROR ${statusCode}: ${ UploadErrorMessageEnum[500] }`;
      break;
    case 400:
      notificationMessage = `UPLOAD_ERROR ${statusCode}: ${ UploadErrorMessageEnum[400] + ' (please see the errors listed below)'}`;
      break;
    case 200:
      notificationMessage = `${UploadErrorMessageEnum[200]}${hasErrors ? ' With Warnings (please see the errors listed below)' : ''}`;
      break;
    default:
      notificationMessage = `UPLOAD_ERROR ${statusCode}: ${ UploadErrorMessageEnum[0] }`;
      break;
  }

  return notificationMessage;
};

export const getRecordCommonIds = (
  recordsNotFound: string[] | undefined
): string | null => {
  if (!recordsNotFound) {
    return null;
  }

  return recordsNotFound.toString().replace(/,/g, ', ');
};
