import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ISchedule, IScheduleRequest, IScheduleRequestRecurrencePattern, ScheduleSaveType } from 'src/app/models/scheduleRequest';
import { SchedulerService } from 'src/app/services/scheduler/scheduler.service';
import { DeviceSettings } from 'src/app/utils/device-settings';
import { LabelEnums, ScreenSize } from 'src/app/utils/enums';
import { NewScheduleModalComponent } from './new-schedule-modal/new-schedule-modal.component';
import { TeamMemberSearchOption } from 'src/app/models/team-member';
import * as _ from 'lodash';
import { IEditScheduleEndDates } from 'src/app/models/edit-schedule-end-dates';
import { RequestScheduleChangeService } from 'src/app/services/request-schedule-change/request-schedule-change.service';
import { RRule } from 'rrule';

@Component({
  
  selector: 'app-request-schedule-change-complex',
  templateUrl: './request-schedule-change-complex.component.html',
  styleUrls: ['./request-schedule-change-complex.component.scss']
})
export class RequestScheduleChangeComplexComponent implements OnInit {
  public scheduleForm: FormGroup = {} as FormGroup;
  public newScheduleData: IScheduleRequestRecurrencePattern[] = [];
  public endScheduleData: IEditScheduleEndDates = {deleteScheduleEndDate: '', editScheduleEndDate: ''};
  public schedulesToBeSaved: IScheduleRequestRecurrencePattern[] = [];
  public intitialSchedule: Array<ISchedule> = [];
  public submitRequestFailed = false;
  public showCheckbox = false;
  public formAlreadySubmitted = false;
  public screenSize: ScreenSize;
  public hasMax = false;
  public isLoading = true;
  public schedules: Array<ISchedule> = [];
  public hasReceivedData = false;
  private currentUserTeam: string | undefined;

  constructor(
    @Inject('SchedulerService') private schedulerService: SchedulerService, private router: Router,
    @Inject('RequestScheduleChangeService') private requestScheduleChangeService: RequestScheduleChangeService,
    @Optional() @Inject('MatDialog') public dialog: MatDialog,
    @Inject('AuthService') private authService: AuthService
  ) {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
    this.authService.idTokenClaims$.subscribe((changes) => {
      if (changes) {
        this.currentUserTeam = changes['https://ql.custom.openid.com/subteam'];
      }
    });
    this.scheduleForm = new FormGroup({
      message: new FormControl(null, [Validators.required]),
      confirmationCheckBox: new FormControl(null, [Validators.required])
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasMax = await this.hasMaxActiveSchedules();
    this.isLoading = false;
    // Need to make a clone of schedules that wont be mutated as this.schedules is changed
    this.intitialSchedule = this.schedules.map(x => ({ ...x }));
  }

  public returnToSearch(): void {
    this.router.navigateByUrl('schedule-request', { skipLocationChange: true });
  }

  public async submitRequest(): Promise<void> {
    this.isLoading = true;
    this.schedulesToBeSaved = [];
    this.mapRequestData();

    const request: IScheduleRequest =
      this.requestScheduleChangeService.createScheduleRequest(
        this.schedulesToBeSaved,
        this.scheduleForm,
        this.intitialSchedule
      );

    await this.saveSchedule(request);

    this.isLoading = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  public get teamMemberNameLabel(): string {
    return this.teamMemberName ? `${this.teamMemberName}'s Request Schedule Change` : '';
  }

  public get teamMemberName(): string {
    const teamMember = this.schedulerService.selectedTeamMember?.teamMember;
    return !!teamMember ? `${teamMember?.firstName} ${teamMember?.lastName}` : '';
  }

  public get selectedTeamMember(): TeamMemberSearchOption | undefined {
    return this.schedulerService.selectedTeamMember;
  }

  public get complexReasonToolTip(): string {
    return LabelEnums.ComplexReasonToolTip;
  }

  public get mobileContainerClass(): string {
    return this.showMobileLayout && !this.isSmallScreen ? 'mobile-schedule-container' : '';
  }

  public get showMobileLayout(): boolean {
    return this.screenSize <= ScreenSize.medium;
  }

  public get isSmallScreen(): boolean {
    return this.screenSize <= ScreenSize.small;
  }

  public get reasonCharacterCount(): string {
    const message: string = this.scheduleForm.get('message')?.value ?? '';
    return `${message.length >= 1000 ? '1,000' : message.length.toString()}/1,000`;
  }

  public get onSameTeam(): boolean {
    return this.schedulerService.selectedTeamMember?.teamMember.team === this.currentUserTeam;
  }

  public get canSubmit(): boolean {
    // Currently needs new data and a reason filled out to enable button
    // will have to add updating flag for end date update requests.
    const message: string = this.scheduleForm.get('message')?.value ?? '';
    const endDateConfirmation: boolean = this.scheduleForm.get('confirmationCheckBox')?.value;
    const hasScheduleData: boolean = this.newScheduleDataExists || (this.endScheduleDataExists && endDateConfirmation);

    return hasScheduleData && !!message && !this.formAlreadySubmitted;
  }

  public createSchedule(): void {
    this.dialog.open(NewScheduleModalComponent, {
      panelClass: 'create-dialogue-container',
      width: '850px',
      maxWidth: 850,
      data: {
        name: this.teamMemberName
      },
      autoFocus: false
    }).afterClosed().subscribe(result => {
      if (result) {
        this.newScheduleData = result;
        this.endScheduleData.editScheduleEndDate = '';
      }
    });
  }

  public resetForm(): void {
    this.newScheduleData = [];
    if (!this.endScheduleData.deleteScheduleEndDate) {
      this.showCheckbox = false;
    }
    this.endScheduleData.editScheduleEndDate = '';
  }

  public get newScheduleDataExists(): boolean {
    return this.newScheduleData.length > 0;
  }

  public get endScheduleDataExists(): boolean {
    return this.endScheduleData.deleteScheduleEndDate !== '' || this.endScheduleData.editScheduleEndDate !== '';
  }

  public mapRequestData(): void{
    // ------------- New Schedules
    this.newScheduleData.forEach((schedule) => {
      schedule.interval = this.newScheduleData.length;
      schedule.frequency = RRule.FREQUENCIES[RRule.WEEKLY];
      schedule.order = this.newScheduleData.indexOf(schedule) + 1;
    });

    this.schedulesToBeSaved = this.schedulesToBeSaved.concat(this.newScheduleData);

    // ------------- End Schedules
    if ( this.endScheduleData.deleteScheduleEndDate !== ''){
      this.schedulesToBeSaved.push(
        {
          scheduleEndDate: this.endScheduleData.deleteScheduleEndDate,
          isActive: true,
          order: 1,
          saveType: ScheduleSaveType.DeleteSchedule
        });
    }

    if ( this.endScheduleData.editScheduleEndDate !== ''){
      this.schedulesToBeSaved.push(
        {
          scheduleEndDate: this.endScheduleData.editScheduleEndDate,
          isActive: true,
          order: this.endScheduleData.deleteScheduleEndDate ? 2 : 1,
          saveType: ScheduleSaveType.EndSchedule
        });
    }
  }

  public async saveSchedule(request: IScheduleRequest): Promise<void>{
    this.formAlreadySubmitted = true;
    try {
      await this.schedulerService.changeOrEndSchedule(request);
      this.router.navigateByUrl('request-schedule-change-submit-success', { skipLocationChange: true });
    }
    catch (error) {
        console.log(error);
        this.submitRequestFailed = true;
        this.formAlreadySubmitted = false;
    }
  }

  public async hasMaxActiveSchedules(): Promise<boolean> {
    const today = new Date();
    let hasMaxSchedules = false;
    const commonId = this.schedulerService.selectedTeamMember?.commonId;
    if (commonId) {
      this.schedules = await this.schedulerService.getTmScheduleData(commonId);
      this.hasReceivedData = true;
      if (this.schedules.length > 0) {
        const groupedSchedules = Object.values(_.groupBy(this.schedules, 'sourceId'));
        if ((groupedSchedules.length > 1) || (new Date(groupedSchedules[0][0].scheduleStartDate) > today)) {
          hasMaxSchedules = true;
        }
      }
    }
    return hasMaxSchedules;
  }
}
