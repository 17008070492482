<div
  class="single-event"
  [ngClass]="updateEventClass()"
  [matTooltip]="hoverText"
  [matTooltipDisabled]="isDisabled()"
  matTooltipPosition="above"
>
  <div class="title">
    <div *ngIf="currentUserEvent">
      <span *ngIf="doesCurrentUserHaveRecurringEvent;else temporary_icon" class="recurring"><i class="fas fa-retweet"></i></span>
      <ng-template #temporary_icon>
        <span class="temporary"><i class="fas fa-hourglass-half"></i></span>
      </ng-template>
    </div>
  </div>
  <div class="tm-initial" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="firstTmToDisplay">
      <app-tm-badge
        [bg]="
          firstTmToDisplay.bgColorIndex ? firstTmToDisplay.bgColorIndex : '1'
        "
        [teamMember]="firstTmToDisplay"
        [size]="'sm'"
        [disabled]="isDisabled()"
      ></app-tm-badge>
    </div>
    <div *ngIf="secondTmToDisplay">
      <app-tm-badge
        [bg]="
          secondTmToDisplay.bgColorIndex ? secondTmToDisplay.bgColorIndex : '1'
        "
        [teamMember]="secondTmToDisplay"
        [size]="'sm'"
        [disabled]="isDisabled()"
      ></app-tm-badge>
    </div>
    <div *ngIf="thirdTmToDisplay">
      <app-tm-badge
        [bg]="
          thirdTmToDisplay.bgColorIndex ? thirdTmToDisplay.bgColorIndex : '1'
        "
        [teamMember]="thirdTmToDisplay"
        [size]="'sm'"
        [disabled]="isDisabled()"
      ></app-tm-badge>
    </div>
    <div *ngIf="fourthTmToDisplay">
      <app-tm-badge
        [bg]="
          fourthTmToDisplay.bgColorIndex ? fourthTmToDisplay.bgColorIndex : '1'
        "
        [teamMember]="fourthTmToDisplay"
        [size]="'sm'"
        [disabled]="isDisabled()"
      ></app-tm-badge>
    </div>
    <div *ngIf="isCountDisplay">
      <span class="tm-count">
        <span class="num">+{{ countNumberToDisplay }}</span>
      </span>
    </div>
  </div>
</div>
