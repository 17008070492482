import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { ScreenSize } from 'src/app/utils/enums';
import { IEventTeamMember } from '../../../models/calendar-interfaces';
import { SingleEventModel } from '../../../models/single-event';
import { DeviceSettings } from '../../../utils/device-settings';

@Component({
  selector: 'app-single-event',
  templateUrl: './single-event.component.html',
  styleUrls: ['./single-event.component.scss']
})
export class SingleEventComponent {
  public eventModel!: SingleEventModel;
  public teamMembers: Array<IEventTeamMember> = [];
  public screenSize: ScreenSize;

  constructor(
    @Inject('DatePipe') private datePipe: DatePipe
  ) {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  public isDisabled(): boolean {
    const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd') ?? '';
    if (this.eventModel.date < today) {
      return true;
    }
    return false;
  }

  public get hoverText(): string {
    if (this.currentUserEvent || this.teamMembers.length > 0) {
      return 'View Details';
    }
    return '';
  }

  public updateEventClass(): string {
    if (this.currentUserEvent) {
      return this.currentUserEvent.scheduleType === 'recurring'
        ? 'current-users-event recurring-event'
        : 'current-users-event temporary-event';
    }

    return 'selected-team-member-event';
  }

  public updateEvent(event: SingleEventModel): void {
    this.eventModel = event;
    this.teamMembers = event.teamMembersToArray();
  }

  public get getRowHeight(): string {
    return this.screenSize > ScreenSize.medium ? '1.3:1' : '1:1';
  }

  public get firstTmToDisplay(): IEventTeamMember | any {
    if (this.teamMembers.length === 1) {
      return this.teamMembers[0];
    }
    return this.teamMembers.length > 0 && this.screenSize > ScreenSize.small ? this.teamMembers[0] : null;
  }

  public get secondTmToDisplay(): IEventTeamMember | any {
    const screenSizeLimit = this.teamMembers.length > 2 ? ScreenSize.medium : ScreenSize.small;
    return this.teamMembers.length > 1 && this.screenSize > screenSizeLimit ? this.teamMembers[1] : null;
  }

  public get thirdTmToDisplay(): IEventTeamMember | any {
    const screenSizeLimit = this.teamMembers.length > 3 ? ScreenSize.large : ScreenSize.medium;
    return this.teamMembers.length > 2 && this.screenSize > screenSizeLimit ? this.teamMembers[2] : null;
  }

  public get fourthTmToDisplay(): IEventTeamMember | any {
    if (this.teamMembers.length > 4) {
      return null;
    }
    return this.teamMembers.length > 3 && this.screenSize > ScreenSize.large ? this.teamMembers[3] : null;
  }

  public get isCountDisplay(): boolean {
    return this.teamMembers.length > this.getNumberOfColumns;
  }

  public get countNumberToDisplay(): number {
    let columnCount = this.getNumberOfColumns;

    if (!this.fourthTmToDisplay) {
      columnCount = columnCount - 1;
    }
    return this.isCountDisplay ? this.teamMembers.length - columnCount : 0;
  }

  public get currentUserEvent(): IEventTeamMember | null {
    if (!this.eventModel?.currentUser) {
      return null;
    }

    const teamMemberEvents = this.eventModel.teamMembersToArray();

    return teamMemberEvents.find((teamMember) => teamMember.commonId === this.eventModel.currentUser.commonId) || null;
  }

  public get doesCurrentUserHaveRecurringEvent(): boolean {
    return !this.currentUserEvent || this.currentUserEvent.scheduleType === 'recurring';
  }

  private get getNumberOfColumns(): number {
    return this.screenSize;
  }
}
