import { ApplicationRef, ComponentFactoryResolver, Inject, Injectable, Injector } from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { SingleEventComponent } from '../../components/calendar/single-event/single-event.component';
import { SingleEventModelFactory } from '../../models/single-event-model-factory';
import { SingleEventModel } from 'src/app/models/single-event';
import { OnsiteUser } from 'src/app/models/onsite-user';


@Injectable({
  providedIn: 'root'
})
export class SingleEventService {

  componentRefsMap: Map<string, any> = new Map();

  constructor(
    @Inject(ComponentFactoryResolver) protected componentFactoryResolver: ComponentFactoryResolver,
    @Inject(ApplicationRef) protected appRef: ApplicationRef,
    @Inject(Injector) protected injector: Injector,
  ) {

  }


  addToCalendar(event: any, targetElement: HTMLElement, currentUser: OnsiteUser): void {

    const host = this.getHost(targetElement);

    const componentRef = host.attach(this.getComponentPortal());

    const factory = new SingleEventModelFactory(event, currentUser); 

    const model = factory.getModel() as SingleEventModel;

    componentRef.instance.eventModel = model;
    componentRef.instance.teamMembers = model.teamMembersToArray();
    componentRef.changeDetectorRef.detectChanges();

    this.componentRefsMap.set(event.event.id, componentRef);

  }


  getHost(targetElement: HTMLElement): DomPortalOutlet {
    return new DomPortalOutlet(
      targetElement, // target element to append to
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
  }


  getComponentPortal(): ComponentPortal<SingleEventComponent> {
    return new ComponentPortal(SingleEventComponent);
  }


  destroyAllRefs(): void {

    for (const [key, value] of this.componentRefsMap) {
      value.destroy();
    }

  }


  destroyRef(eventId: string): void {

    if (this.componentRefsMap.has(eventId)) {

      const ref = this.componentRefsMap.get(eventId);
      if (typeof ref !== typeof undefined) {
        ref.destroy();
        this.componentRefsMap.delete(eventId);
      }

    }

  }

}
