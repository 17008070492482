import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './components/error/unauthorized/unauthorized.component';
import { ScheduleUploadComponent } from './components/schedule-upload/schedule-upload.component';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';
import { RequestScheduleChangeComponent } from './components/request-schedule-change/request-schedule-change.component';
import { RequestScheduleChangeSearchComponent } from './components/request-schedule-change-search/request-schedule-change-search.component';
import { RequestScheduleChangeUpdateComponent } from './components/request-schedule-change-update/request-schedule-change-update.component';
import { RequestScheduleChangeEndComponent } from './components/request-schedule-change-end/request-schedule-change-end.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { RequestScheduleChangeSubmitSuccessComponent } from './components/request-schedule-change-submit-success/request-schedule-change-submit-success.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { RequestScheduleChangeComplexComponent } from './components/request-schedule-change-complex/request-schedule-change-complex.component';

// Import auth guards
import { ScheduleAdminGuard } from './auth/schedule-admin.guard';
import { AuthenticationGuard } from './auth/authentication-guard';
import { OnsiteApprovalAdminGuard } from './auth/onsiteApproval-admin.guard';
import { DashboardAdminGuard } from './auth/dashboard-admin.guard';
import { EngineeringTeamGuard } from './auth/engineering-team-guard';
import { RequestScheduleGuard } from './auth/request-schedule.guard';

const routes: Routes = [
  { path: '', component: CalendarComponent, canActivate: [AuthenticationGuard], data: { title: 'My Schedule' } },
  { path: 'unauthorized', component: UnauthorizedComponent, data: { title: 'Unauthorized' } },
  {
    path: 'schedule-upload',
    component: ScheduleUploadComponent,
    canActivate: [AuthenticationGuard, ScheduleAdminGuard],
    data: { title: 'Upload Schedules' }
  },
  {
    path: 'request-schedule-change-complex',
    component: RequestScheduleChangeComplexComponent,
    canActivate: [AuthenticationGuard, RequestScheduleGuard],
    data: { title: 'Request Schedule Change' }
  },
  {
    path: 'request-schedule-change',
    component: RequestScheduleChangeComponent,
    canActivate: [AuthenticationGuard, ScheduleAdminGuard],
    data: { title: 'Request Schedule Change' }
  },
  {
    path: 'schedule-request',
    component: RequestScheduleChangeSearchComponent,
    canActivate: [AuthenticationGuard],
    data: { title: 'Request Schedule Change' }
  },
  {
    path: 'schedule-request/dashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthenticationGuard, DashboardAdminGuard],
    data: { title: 'My Dashboard' }
  },
  {
    path: 'request-schedule-change-update',
    component: RequestScheduleChangeUpdateComponent,
    canActivate: [AuthenticationGuard, ScheduleAdminGuard],
    data: { title: 'Request Schedule Change' }
  },
  {
    path: 'request-schedule-change-end',
    component: RequestScheduleChangeEndComponent,
    canActivate: [AuthenticationGuard, ScheduleAdminGuard],
    data: { title: 'Request Schedule Change' }
  },
  {
    path: 'request-schedule-change-submit-success',
    component: RequestScheduleChangeSubmitSuccessComponent,
    canActivate: [AuthenticationGuard, RequestScheduleGuard],
    data: { title: 'Thank You!' }
  },
  {
    path: 'request-schedule-change-submit',
    component: RequestScheduleChangeSubmitSuccessComponent,
    canActivate: [AuthenticationGuard, RequestScheduleGuard],
    data: { title: 'Thank You!' }
  },
  { path: 'style-guide', component: StyleGuideComponent, canActivate: [AuthenticationGuard, ScheduleAdminGuard], data: { title: 'Style Guide' } },
  { path: 'contact-us', component: ContactUsComponent, canActivate: [AuthenticationGuard], data: { title: 'Contact Us' } },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
