import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import { TeamMemberSearchOption } from 'src/app/models/team-member';
import { ScreenSize } from 'src/app/utils/enums';
import { DeviceSettings } from '../../../utils/device-settings';

@Component({
  selector: 'app-tm-search-carousal',
  templateUrl: './tm-search-carousal.component.html',
  styleUrls: ['./tm-search-carousal.component.scss']
})
export class TmSearchCarousalComponent implements OnChanges, AfterViewInit {
  @Input() public selectedTeamMembers: TeamMemberSearchOption[] = [];
  @Output() public teamMemberRemoved = new EventEmitter<TeamMemberSearchOption>();

  public screenSize: ScreenSize;
  public firstRecordIndexToShownInCarousal = 0;
  private prevSelectedTeamMembersCount = 0;
  private prevMaxNumberOfRecordsToDisplay = 0;

  constructor() {
    this.prevMaxNumberOfRecordsToDisplay = this.maxNumberOfRecordsToDisplay;
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleTeamMemberSearchChanges(changes.selectedTeamMembers);
  }

  ngAfterViewInit(): void {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.prevMaxNumberOfRecordsToDisplay = this.maxNumberOfRecordsToDisplay;
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
    if (this.prevMaxNumberOfRecordsToDisplay < this.maxNumberOfRecordsToDisplay) {
      this.firstRecordIndexToShownInCarousal = Math.max(0, this.selectedTeamMembers.length - this.maxNumberOfRecordsToDisplay);
    }
  }

  public moveViewLeft(): void {
    if (this.enableLeftArrayIcon) {
      this.firstRecordIndexToShownInCarousal--;
    }
  }

  public moveViewRight(): void {
    if (this.enableRightArrayIcon) {
      this.firstRecordIndexToShownInCarousal++;
    }
  }

  public get canShowSelectedTeamMembers(): boolean {
    return this.selectedTeamMembers.length > 0;
  }

  private handleTeamMemberSearchChanges(change: SimpleChange): void {
    if (!change.firstChange && change.currentValue.length > change.previousValue.length) {
      this.updateTeamMemberSearchCarouselIndex();
    }
  }

  private updateTeamMemberSearchCarouselIndex(): void {
    if (this.prevMaxNumberOfRecordsToDisplay < this.maxNumberOfRecordsToDisplay) {
      this.firstRecordIndexToShownInCarousal = Math.max(0, this.selectedTeamMembers.length - this.maxNumberOfRecordsToDisplay);
    }
    if (this.prevSelectedTeamMembersCount !== this.selectedTeamMembers.length) { // find update index to show recent added member
      this.firstRecordIndexToShownInCarousal = Math.max(0, this.selectedTeamMembers.length - this.maxNumberOfRecordsToDisplay);
      this.prevSelectedTeamMembersCount = this.selectedTeamMembers.length;
    }
  }

  public onUnselectTeamMember(teamMember: TeamMemberSearchOption): void {
    if (this.isOnLastCarouselPage()) {
      this.firstRecordIndexToShownInCarousal = Math.max(0, this.firstRecordIndexToShownInCarousal - 1);
    }
    this.teamMemberRemoved.emit(teamMember);
  }

  private isOnLastCarouselPage(): boolean {
    return (this.selectedTeamMembers.length - this.firstRecordIndexToShownInCarousal) === this.maxNumberOfRecordsToDisplay;
  }

  public get maxNumberOfRecordsToDisplay(): number {
    return this.screenSize > ScreenSize.medium ? 3 : 1;
  }

  public get displayArrayIcons(): boolean {
    return this.selectedTeamMembers.length > this.maxNumberOfRecordsToDisplay;
  }

  public get enableLeftArrayIcon(): boolean {
    return this.firstRecordIndexToShownInCarousal > 0;
  }

  public get enableRightArrayIcon(): boolean {
    return this.firstRecordIndexToShownInCarousal < (this.selectedTeamMembers.length - this.maxNumberOfRecordsToDisplay);
  }

  public get displayTeamMembersList(): TeamMemberSearchOption[] {
    return this.selectedTeamMembers.slice(this.firstRecordIndexToShownInCarousal,
      Math.min(this.firstRecordIndexToShownInCarousal + this.maxNumberOfRecordsToDisplay, this.selectedTeamMembers.length));
  }
}
