<div class="contact-us-container">
    <div class="contact-us-title">
        <h1>Contact Us</h1>
    </div>

    <div class="contact-us-form">
        <p>Need assistance with Onsite Scheduler? We'll get you the help you need.</p>

        <h2 class="no-margin-bottom">Schedule Support</h2>
        <p><a href="{{ scheduleSupport.mailto }}" [innerHTML]="scheduleSupport.breakableEmail"></a></p>

        <h2 class="no-margin-bottom">Seat/Workspace Assignment</h2>
        <p><a href="{{ workspaceSupport.mailto }}" [innerHTML]="workspaceSupport.breakableEmail"></a></p>

        <h2 class="no-margin-bottom">Parking Assignment</h2>
        <p><a href="{{ parkingSupport.mailto }}" [innerHTML]="parkingSupport.breakableEmail"></a></p>

        <h2 class="no-margin-bottom">Technical/Technology Support</h2>
        <p><a href="{{ techSupport.mailto }}" [innerHTML]="techSupport.breakableEmail"></a></p>
    </div>
</div>