<div class="change-requests-table">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="center start">
        <div class="change-requests-table__page-size">
            <mat-paginator #paginatorTop [length]="totalScheduleRequests" [pageSizeOptions]="[25, 50]"
                [disabled]="(dataSource.loading$ | async) || !totalScheduleRequests || isLoading">
            </mat-paginator>
        </div>
        <div [hidden]="selectedTeamMember" class="change-request-status-filter-wrapper">
            <label class="change-requests-table__status-label">Status</label>
            <mat-form-field class="change-request-filter"
                [ngClass]="isOverlayVisible ? 'change-request-filter__focus' : ''">
                <mat-select [(ngModel)]="selectedStatusFilter" (valueChange)="changeStatus($event)"
                    (openedChange)="onDropdownToggle($event)" [disabled]="(dataSource.loading$ | async) || isLoading">
                    <mat-option *ngFor="let status of statusFilterSelectList" [value]="status.value">{{status.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="scrollable-xy" [ngClass]="!totalScheduleRequests ? 'no-change-request-table' : ''">
        <div class="loading-overlay" [ngStyle]="loadingOverlayHeight" *ngIf="(dataSource.loading$ | async) || isLoading">
            <div class="inner">
                <i class="fas fa-circle-notch fa-spin"></i>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" matSort matSortActive="effectiveDate" matSortDirection="asc"
            matSortDisableClear>

            <tr class="no-shadow" mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" [matTooltip]="'View details of this request'"
                matTooltipPosition="above" (click)="displayRequestTicketDetails(row)"></tr>

            <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.sortDisabled">
                    {{column.header}}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ column.cell(row) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <i class="schedule-change-request__edit-icon" [ngClass]="getStatusIcon(row.requestStatus)"></i>
                </td>
            </ng-container>

            <tr class="mat-row no-change-requests" *matNoDataRow>
                <td class="mat-cell" colspan="8" rowspan="1">
                    <div class="no-change-requests-title">
                        <i class="fas fa-clipboard-check no-change-requests__icon"></i>
                        <span><strong>None</strong></span>
                    </div>
                    <div *ngIf="selectedTeamMember; else filteredOnStatus">
                        <em>Currently there are no requests to review for {{selectedTeamMember.title}}.</em>
                    </div>
                    <ng-template #filteredOnStatus>
                        <div>
                            <em>{{noScheduleChangeRequestsMessage}}</em>
                        </div>
                    </ng-template>
                </td>
            </tr>
        </table>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="change-requests-table__footer-label">
            <mat-paginator #paginatorBottomLabel [length]="totalScheduleRequests" [pageSizeOptions]="[25, 50]"
                [disabled]="true">
            </mat-paginator>
        </div>
        <div class="change-requests-table__footer">
            <mat-paginator #paginatorBottom [length]="totalScheduleRequests" [pageSizeOptions]="[25, 50]"
                [disabled]="(dataSource.loading$ | async) || !totalScheduleRequests || isLoading">
            </mat-paginator>
        </div>
    </div>
</div>
<app-overlay [Show]="isOverlayVisible" [DoubleZIndex]="true"></app-overlay>
