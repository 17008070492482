<!--TM search-->
<div class="search-container" *ngIf=hasAccess>
  <div class="schedule-change-search">
    <app-callout *ngIf="!complexFeatureFlag" title="Reminder" type="default" icon="fa fa-info-circle">
      <p>
        Complex scheduling is only supported through the
        <a routerLink="/schedule-upload">Upload Schedules</a>
         feature at this time. Please only use this form when setting a single recurrence schedule for a team member.
      </p>
    </app-callout>
  </div>
  <app-loading [showLoader]="isLoading()"></app-loading>
  <h1>Which Team Member's schedule would you like to request to change?</h1>
  <app-search *ngIf="isTeamMemberSearchVisible" (teamMemberSelected)="onTeamMemberSelected($event)"
    [searchToolTip]="'Begin typing a name to select'"></app-search>
  <div *ngIf="selectedTeamMember" class="selected-member">
    <div class="search-option">
      <div fxLayout="row" fxLayoutAlign="start center">
        <app-tm-badge [size]="'static-md'" [teamMember]="selectedTeamMember.teamMember"></app-tm-badge>
        <div class="search-details">
          <div><span class="title">{{selectedTeamMember.title}} </span> <span class="job-title">({{selectedTeamMember.jobTitle}})</span></div>
          <div class="contact-details"><u>{{selectedTeamMember.contactDetails}}</u></div>
          <div class="organization-details">{{selectedTeamMember.organizationDetails}}</div>
        </div>
        <div class="minus-icon" [matTooltip]="'Remove'" matTooltipPosition="above" (click)="unselectTeamMember()"><i class="fa-regular fa-xmark"></i></div>
      </div>
    </div>
  </div>
  <div *ngIf="complexFeatureFlag && hasReceivedData && requestInProcess" class="alert-callout">
    <app-callout title="Alert" type="alert" icon="fas fa-exclamation-circle">
      Sorry, there is a schedule change already in progress for this team member. Another request cannot be submitted until the request in progress is completed.
    </app-callout>
  </div>
  <div *ngIf="complexFeatureFlag && isSameUser" class="alert-callout">
    <app-callout title="Alert" type="alert" icon="fas fa-exclamation-circle">
      Sorry, you cannot request schedule changes for yourself at this time. Please reach out to your leader to submit a change.
    </app-callout>
  </div>
  <div class="centered">
    <button mat-button *ngIf="selectedTeamMember" (click)="unselectTeamMember()" class="back-bottom-button tertiary">Back</button>
    <button mat-button (click)="nextClick()" [disabled]="isNextButtonDisabled" class="primary bottom-button">Continue</button>
  </div>
</div>
<div *ngIf=!hasAccess>
  <app-request-schedule-change-nonadmin></app-request-schedule-change-nonadmin>
</div>
