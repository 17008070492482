<div class="current-schedule-card">
    <div *ngIf="schedules.length > 0" fxLayout="column" fxLayoutAlign="center center">
        <div *ngFor="let schedule of schedules;let index = index; let last = last; let first = first;" class='schedule-outer
             badge-box w3-container w3-border w3-border-black w3-margin-top container'>
            <span class="bold-font label">Days</span>
            <div *ngFor="let weekDays of schedule.weekDays;">
                <div class="daysRow" fxLayout="row" fxLayoutAlign="space-evenly">
                    <span *ngFor="let day of daysOfWeek" class="weekday-box" [ngClass]="{'day-box-selected' : isDaySelected(day, weekDays)}">
                        {{showMobileLayout ? day[0] : day}}
                    </span>
                </div>
            </div>
            <span class="trash" *ngIf="!readOnly">
                <span *ngIf="isFutureSchedule(schedule.scheduleStartDate)" (click)="deleteSchedule(index)" matTooltipClass="new-schedule-card-tool-tip" matTooltipPosition="above"
                        [matTooltip]="removeFutureScheduleTooltip">
                    <i class="fas fa-trash-alt"></i>
                </span>
            </span>
            <div class="row  startdate-div" fxLayout="row">
                <span class='bold-font label'>Start</span>
                <span class="date-span">{{formattedDate(schedule.scheduleStartDate)}}</span>
            </div>
            <div class="row" fxLayout="row">
                <span class='bold-font label'>End</span>
                <span class="date-span">
                    {{formattedDate(schedule.scheduleEndDate)}}
                    <button type="button" *ngIf="last && !hideEditEndDate && !readOnly" class="button-{{index}} button-padding"
                        (click)="editSchedule(index)">
                        <i class="fas fa-edit"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="schedules.length === 0" class="no-schedule">
        <div>
            <div class="first-line">
                <span><i class="fas fa-calendar"></i></span>
                <span class="none-message">No Schedule</span>
            </div>
            <div class="not-scheduled">
                Not scheduled to be onsite.
            </div>
        </div>
    </div>
</div>
