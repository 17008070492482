import {Inject, Injectable, Optional} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';


@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {

  constructor( @Optional() @Inject('MatDialog') public dialog: MatDialog ) { }

  open( message: string, header: string ): MatDialogRef<any> {
    return this.dialog.open( ConfirmationModalComponent, {
      width: '850px',
      maxWidth: 850,
      data: {
        message,
        header
    },
      autoFocus: false
    });
  }
}
