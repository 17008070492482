import { Component, Inject, Input, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { OnsiteAuthService } from 'src/app/auth/onsite-auth-service';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { environment } from 'src/environments/environment';
import { IEventTeamMember } from '../../../models/calendar-interfaces';
import { SingleEventModel } from '../../../models/single-event';
import {
    DialogCreateBookingComponent
} from '../dialog-create-booking/dialog-create-booking.component';
import {
    DialogRemoveOnsiteVisitComponent
} from '../dialog-remove-onsite-visit/dialog-remove-onsite-visit.component';

@Component({
  selector: 'app-dialog-single-event',
  templateUrl: './dialog-single-event.component.html',
  styleUrls: ['./dialog-single-event.component.scss']
})
export class DialogSingleEventComponent {
  isCurrentUserAParkingAdmin!: boolean;
  currentUserEvent!: IEventTeamMember | null;
  teamMemberEvents: IEventTeamMember[] = [];
  iOfficeReservationURL: string;

  private model!: SingleEventModel;

  public bookingsFeatureFlag = false;

  @Input() buttonData = {};
  constructor(
    @Optional() @Inject(MatDialogRef) public dialogRef: MatDialogRef<DialogSingleEventComponent>,
    @Optional() @Inject('MatDialog') public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: SingleEventModel,
    @Inject('OnsiteAuthService') authService: OnsiteAuthService
  ) {
    this.bookingsFeatureFlag = JSON.parse(environment.bookingsFeatureFlag);

    authService.hasParkingAdminAccess$().subscribe((isCurrentUserAParkingAdmin) => {
      this.isCurrentUserAParkingAdmin = isCurrentUserAParkingAdmin;
    });

    this.model = data;
    const teamMembers = this.model.teamMembersToArray();
    this.currentUserEvent = this.getCurrentUserEvent(teamMembers);
    this.teamMemberEvents = this.getTeamMemberEvents(teamMembers);
    this.iOfficeReservationURL = environment.iOffice.url + 'reservation/myreservations';
  }

  public get currentUser(): OnsiteUser {
    return this.model.currentUser;
  }

  public get date(): string {
    return this.model.date;
  }

  public isRecurring(teamMember: IEventTeamMember): boolean {
    return teamMember.scheduleType === 'recurring';
  }

  public isTemporaryBooking(teamMember: IEventTeamMember): boolean {
    return teamMember.scheduleType === 'temporary';
  }

  public badgeColumnClass(teamMember: IEventTeamMember): string {
    if (this.isRecurring(teamMember)) {
      return 'recurring';
    } else {
      return 'temporary';
    }
  }

  public reservedSeatingUrl(url: string): string {
    if (url.length) {
      return url;
    } else {
      return 'https://rockfoc.iofficeconnect.com/home.i#/reservation/myreservations';
    }
  }

  public seatingUrl(url: string): string {
    if (url.length) {
      return url;
    } else {
      return 'https://rockfoc.iofficeconnect.com/home.i#/space/spacedesktop?mode=information';
    }
  }

  public scheduleTemporaryOnsiteVisit(): void {
    this.dialogRef.close();

    this.dialog.open(DialogCreateBookingComponent, {
      width: '775px',
      maxWidth: 775,
      data: {
        currentUser: this.model.currentUser,
        reloadCalendar: this.data.reloadCalendar,
        scheduledDays: this.data.scheduledDays,
        selectedDate: this.data.date,
      },
      autoFocus: false
    });
  }

  public removeOnsiteVisit(): void {
    if (this.currentUserEvent && this.isTemporaryBooking(this.currentUserEvent) && this.currentUserEvent.booking) {
      this.dialog.open(DialogRemoveOnsiteVisitComponent, {
        width: '775px',
        maxWidth: 775,
        data: {
          date: this.model.date,
          booking: this.currentUserEvent.booking,
          model: this.model,
          hasReservedSeating: this.currentUserEvent.reservedSeating.isAssigned
        },
        autoFocus: false
      });
      this.dialogRef.close();
    }
  }

  private getCurrentUserEvent(teamMembers: IEventTeamMember[]): IEventTeamMember | null {
    return teamMembers.find((teamMember) => teamMember.commonId === this.model.currentUser.commonId) || null;
  }

  private getTeamMemberEvents(teamMembers: IEventTeamMember[]): IEventTeamMember[] {
    const teamMemberEvents = teamMembers.filter((teamMember) => teamMember.commonId !== this.model.currentUser.commonId);

    return _.sortBy(teamMemberEvents, 'firstName');
  }
}
