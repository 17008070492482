export const searchConfig: ISearchConfig = {
    minimumSearchLength: 3,
    debounceTime: 400,
    toolTip: 'Begin typing a name to add their schedule',
    placeholder: 'Search by name or email address'
};

export interface ISearchConfig {
    readonly minimumSearchLength: number;
    readonly debounceTime: number;
    readonly toolTip: string;
    readonly placeholder: string;
}
