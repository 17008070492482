import { Component, HostListener, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import {
    IScheduleRequestRecurrencePattern,
    ScheduleSaveType
} from 'src/app/models/scheduleRequest';
import { DeviceSettings } from 'src/app/utils/device-settings';
import { LabelEnums, ScreenSize } from 'src/app/utils/enums';

@Component({
  selector: 'app-new-schedule-modal',
  templateUrl: './new-schedule-modal.component.html',
  styleUrls: ['./new-schedule-modal.component.scss']
})
export class NewScheduleModalComponent {
  public minDate!: Date;
  public formData: IScheduleRequestRecurrencePattern[] = [];
  public scheduleStartDate!: Date;
  public screenSize: ScreenSize;
  public showStartDateToolTip = false;
  public showComplexScheduleNote = false;

  /**
   * @example console.log( data.event.title ); to access the data passed to it
   */
  constructor(
    @Optional() @Inject(MatDialogRef) public dialogRef: MatDialogRef<NewScheduleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('OnsiteAuthService') private authService: IOnsiteAuthService
  ) {
    this.formData.push({ isActive: true, weekDays: '', saveType: ScheduleSaveType.NewSchedule });
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
    this.authService.getUser$().subscribe(user => {
      this.showStartDateToolTip = user.isEffectivelyLeader;
      this.minDate = user.isEffectivelyLeader ?
        DateTime.now().plus({ days: 7 }).toJSDate() : new Date();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenSize = DeviceSettings.GetScreenSize(window.innerWidth);
  }

  public toggleSelectedWeekdays(weekIndex: number, weekday: string): void {
    const isValidIndex = this.formData.length > weekIndex && weekIndex > -1;

    if (isValidIndex) {
      if (this.formData[weekIndex].weekDays?.includes(weekday)) {
        this.formData[weekIndex].weekDays = this.formData[weekIndex].weekDays?.replace(weekday + ',', '');
      } else {
        this.formData[weekIndex].weekDays = this.formData[weekIndex].weekDays ?? '';
        this.formData[weekIndex].weekDays += weekday + ',';
      }
    } else {
      console.log('Error: weekIndex out of range.');
    }
  }

  public isDaySelected(weekIndex: number, weekday: string): boolean {
    return this.formData[weekIndex].weekDays?.includes(weekday) ?? false;
  }

  public saveModal(): void {
    this.formatData();
    this.dialogRef.close(this.formData);
  }

  public formatData(): void {
    this.formData[0].weekDays = this.formData[0].weekDays?.slice(0, -1);
    this.formData[0].scheduleStartDate = this.scheduleStartDate.toISOString()?.split('T')[0];
    if (this.isComplex) {
      this.formData[1].weekDays = this.formData[1].weekDays?.slice(0, -1);
      this.formData[1].scheduleStartDate = this.formData[0].scheduleStartDate;
    }
  }

  public toggleComplex(): void {
    // Complex schedules have 2 weeks/entries and simple schedules have one
    this.showComplexScheduleNote = !this.isComplex;
    this.isComplex ? this.formData.pop() : this.formData.push({
      isActive: true,
      weekDays: '',
      saveType: ScheduleSaveType.NewSchedule
    });
  }

  public get isComplex(): boolean {
    return this.formData.length > 1;
  }

  public get isFormValid(): boolean {
    const anyRequiredFieldEmpty = !this.scheduleStartDate || !this.formData[0].weekDays ||
      (this.formData.length > 1 && !this.formData[1].weekDays);

    return !anyRequiredFieldEmpty;
  }

  public get startToolTip(): string {
    return LabelEnums.StartDateToolTip;
  }

  public get daysToolTip(): string {
    return LabelEnums.WeekdaysToolTip;
  }

  public get removeWeekTooltip(): string {
    return LabelEnums.RemoveWeekToolTip;
  }

  public get showMobileLayout(): boolean {
    return this.screenSize <= ScreenSize.small;
  }

  public get isLargeScreen(): boolean {
    return this.screenSize > ScreenSize.large;
  }

  public get isMobile(): boolean {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }
}
