<div class="complex-component">
  <app-loading [showLoader]="isLoading"></app-loading>
  <h1>{{ teamMemberNameLabel }}</h1>
  <div *ngIf="selectedTeamMember" class="selected-member">
    <div class="search-option">
      <div fxLayout="row" fxLayoutAlign="start center">
        <app-tm-badge [size]="'static-md'" [teamMember]="selectedTeamMember.teamMember"></app-tm-badge>
        <div class="search-details">
          <div><span class="title">{{selectedTeamMember.title}} </span> <span class="job-title">({{selectedTeamMember.jobTitle}})</span></div>
          <div class="contact-details"><u>{{selectedTeamMember.contactDetails}}</u></div>
          <div class="organization-details">{{selectedTeamMember.organizationDetails}}</div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start" class="mt-2">
    <h2>Schedule</h2>
  </div>
  <form id="scheduleForm" [formGroup]="scheduleForm" (ngSubmit)="submitRequest()" fxLayout="column">
    <div [fxLayout]="showMobileLayout ? 'column' : 'row'" fxLayoutAlign="space-evenly">
      <div [ngClass]="mobileContainerClass" fxLayoutAlign="center center" class="badge-box container schedule-card-row">
        <app-request-schedule-card
          [endScheduleData]="endScheduleData" [newScheduleData]="newScheduleData" (eventEmitter)="showCheckbox=true" *ngIf="hasReceivedData"
          (removeSchedule)="hasMax=false" [tmScheduleData]="schedules" class="request-schedule-card">
        </app-request-schedule-card>
      </div>
      <div *ngIf="!showMobileLayout" fxLayoutAlign="center center" class="schedule-arrow right">
        <i class="fa-regular fa-arrow-right-long"></i>
      </div>
      <div *ngIf="showMobileLayout" fxLayoutAlign="center center" class="schedule-arrow down">
        <i class="fa-regular fa-arrow-down-long"></i>
      </div>
      <div *ngIf="!newScheduleDataExists && !hasMax" [ngClass]="mobileContainerClass" fxLayoutAlign="center center" class="badge-box container schedule-card-row">
        <button mat-button type="button" class="setup-button button" (click)="createSchedule()">Set Up New Schedule</button>
      </div>
      <div *ngIf="!newScheduleDataExists && hasMax" [ngClass]="mobileContainerClass" fxLayoutAlign="center center" class="badge-box container schedule-card-row">
        <div>
          <div class="first-line">
              <span><i class="fas fa-calendar-times"></i></span>
              <span class="max-schedules-header">Schedule Limit Reached</span>
          </div>
          <div class="max-schedules-message">
              <p>Only one upcoming schedule is supported at this time.<br>Please remove the upcoming schedule to add a new one.</p>
          </div>
      </div>
      </div>
      <div *ngIf="newScheduleDataExists" [ngClass]="mobileContainerClass" fxLayoutAlign="center center" class="badge-box container schedule-card-row">
        <app-new-schedule-card [newScheduleData]="newScheduleData" [teamMemberName]="teamMemberName" (resetEmitter)="resetForm()" style="width: 100%;"></app-new-schedule-card>
      </div>
    </div>
    <div *ngIf="!onSameTeam" class="alert-margin">
      <app-callout title="Alert" type="alert" icon="fas fa-exclamation-circle">
        The selected team member does not directly report to you. Please include an explanation below of why you are requesting a schedule change for this team member.
      </app-callout>
    </div>
    <div fxLayout="column" class="reason-row mt-2" fxLayoutAlign="center">
      <mat-form-field style="width: 100%">
        <h2>Reason & Details <span class="mat-tooltip-panel-right" matTooltipClass="tool-tip--reason" matTooltipPosition="above" [matTooltip]="complexReasonToolTip"><i class="fas fa-info-circle"></i></span></h2>
        <div class="textarea-form-field">
          <textarea
            maxlength="1000"
            matInput
            class="materialize-textarea"
            formControlName="message"
          ></textarea>
        </div>
        <label id="char-count-display">Character Count: {{ reasonCharacterCount }}</label>
        <div *ngIf="showCheckbox && !newScheduleDataExists">
          <div class="note-margin">
            <app-callout title="Note" type="default" icon="fas fa-exclamation-circle">
              You will be ending {{teamMemberName}}'s onsite schedule if you submit this request without setting up a new schedule
            </app-callout>
          </div>

          <div>
            <mat-checkbox class="end-confirmation" formControlName="confirmationCheckBox">
              I understand this request will end the selected team member's onsite
              schedule on the date I have entered above and they will no longer be
              coming into our offices after this date.
            </mat-checkbox>
          </div>
        </div>
        <div class="submit-request-error note-margin" *ngIf="submitRequestFailed">
          <app-callout type="error" title="Error">
              Service is temporarily unavailable. Please try again later.
          </app-callout>
        </div>
        <div class="centered button-container">
          <button mat-button id="return-button" (click)="returnToSearch()" class="bottom-button tertiary">Back</button>
          <button mat-button id="submit-change-button" type="submit" [disabled]="!canSubmit" class="primary submit-button">
            Submit Request
          </button>
        </div>
      </mat-form-field>
    </div>
  </form>
</div>
