import { Component, Inject, Optional, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-end-schedule',
  templateUrl: './edit-end-schedule.component.html',
  styleUrls: ['./edit-end-schedule.component.scss']
})
export class EditEndScheduleComponent implements OnInit {

  public scheduleEndDate!: Date;
  public formData = {
    endDate: this.scheduleEndDate
  };

  constructor(
    @Optional() @Inject(MatDialogRef) public dialogRef: MatDialogRef<EditEndScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.formData.endDate = this.data.endDate;
  }

  saveModal(): void {
    const endDateString = this.formData.endDate.toISOString()?.split('T')[0];
    this.dialogRef.close(endDateString);
  }
  
  get saveEnabled(): boolean {
    return (!!this.formData.endDate && !!this.formData.endDate.getTime());
  }

  get isMobile(): boolean {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }
}
