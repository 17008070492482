import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-date-preview',
  templateUrl: './date-preview.component.html',
  styleUrls: ['./date-preview.component.scss']
})
export class DatePreviewComponent implements OnInit, OnChanges {
  @Input() public selectedDate = '';

  public day = '';
  public month = '';
  public date = '';

  ngOnInit(): void {
    this.getDateParts();
  }

  ngOnChanges(): void {
    this.getDateParts();
  }

  public getDateParts(): void {
    if (DateTime.fromISO(this.selectedDate).isValid) {
      const dateParts = DateTime.fromISO(this.selectedDate).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY).split(',');
      const monthAndDatePart = dateParts[1].trim().split(' ');

      this.day = dateParts[0];
      this.month = monthAndDatePart[0];
      this.date = monthAndDatePart[1];
    } 
  }
}
