<div class="scheduler-modal">
    <mat-dialog-content>
      <mat-toolbar fxLayoutAlign="left center"><span class="header-modal">{{data.header}}</span></mat-toolbar>
      <div class="dialog-close modal" [mat-dialog-close]="false"><i class="fa-regular fa-xmark white"></i></div>
      <div class="centered confirm-message">
        <span>{{data.message}}</span>
      </div>
      <div class="centered buttons">
        <button mat-button (click)="dialogRef.close(true)" class="primary button-size">Yes</button>
        <button mat-button (click)="dialogRef.close(false)" class="backbutton button-size tertiary">No</button>
      </div>
    </mat-dialog-content>
  </div>
