<h2>Schedule</h2>
<div class="requested-schedule-card">
    <div class="row-container-schedule-details" [fxLayout]="showMobileLayout ? 'column' : 'row'"
        fxLayoutAlign="space-between">
        <div class="current-and-removed-schedule" fxLayout="column" fxLayoutAlign="center center" fxFlex="45">
            <div *ngIf="!currentScheduleGroups.length && !removedSchedule.length; else hasPreviousSchedule"
                class="not-scheduled-to-be-onsite-card" fxLayout="column" fxLayoutAlign="center center"
                [matTooltip]="notScheduledToolTip" matTooltipPosition="above" matTooltipClass="big-tooltip">
                <div class="no-schedule-message">
                    <i class="fas fa-calendar"></i><strong>&nbsp; No Schedule</strong>
                </div>
                <div class="not-scheduled-to-be-onsite">
                    <em>Not scheduled to be onsite.</em>
                </div>
            </div>
            <ng-template #hasPreviousSchedule>
                <app-request-schedule-card *ngFor="let group of currentScheduleGroups" [newScheduleData]="newSchedule"
                    [defaultScheduleData]="group.schedules" [forceMobileLayout]="true" [readOnly]="true"
                    class="request-schedule-card" [matTooltip]="currentScheduleToolTip" matTooltipPosition="above"
                    matTooltipClass="big-tooltip">
                </app-request-schedule-card>
                <app-request-schedule-card *ngIf="removedSchedule.length" [defaultScheduleData]="removedSchedule"
                    [forceMobileLayout]="true" [readOnly]="true" class="removed-schedule-card"
                    [matTooltip]="removedScheduleToolTip" matTooltipPosition="above" matTooltipClass="big-tooltip">
                </app-request-schedule-card>
            </ng-template>
        </div>
        <div *ngIf="!showMobileLayout" fxLayoutAlign="center center" class="schedule-arrow-right">
            <i class="fa-solid fa-arrow-right-long"></i>
        </div>
        <div *ngIf="showMobileLayout" fxLayoutAlign="center center" class="schedule-arrow-down">
            <i class="fa-solid fa-arrow-down-long"></i>
        </div>
        <app-new-schedule-card *ngIf="newSchedule.length; else onsiteScheduleEnded" class="requested-schedule"
            [newScheduleData]="newSchedule" [forceMobileLayout]="true" [readOnly]="true" fxFlex="45"
            [matTooltip]="requestedScheduleToolTip" matTooltipPosition="above" matTooltipClass="big-tooltip">
        </app-new-schedule-card>
        <ng-template #onsiteScheduleEnded>
            <div class="onsite-schedule-ended" fxLayout="column" fxLayoutAlign="center center" fxFlex="45"
                [matTooltip]="requestedScheduleToolTip" matTooltipPosition="above" matTooltipClass="big-tooltip">
                <div class="no-schedule-message">
                    <i class="fas fa-calendar-minus"></i><strong>&nbsp; No Schedule</strong>
                </div>
                <div class="end-onsite-access">
                    <em>End Onsite Access</em>
                </div>
            </div>
        </ng-template>
    </div>
</div>
