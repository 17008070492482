import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import { ActorTeamMember } from 'src/app/models/actor-team-member';
import { ChangeRequestTeamMember } from 'src/app/models/change-request-team-member';
import { OnsiteUser } from 'src/app/models/onsite-user';
import { ScheduleChangeRequestHistory } from 'src/app/models/schedule-change-request-history';
import { ScheduleChangeRequestTicketAction } from 'src/app/models/schedule-change-request-ticket-action';
import { TeamMemberBadge } from 'src/app/models/team-member-badge';
import { ScheduleStatusRequest } from 'src/app/utils/enums';
import { GeneralUtilities } from 'src/app/utils/general-utilities';

@Component({
  selector: 'app-request-history',
  templateUrl: './request-history.component.html',
  styleUrls: ['./request-history.component.scss']
})
export class RequestHistoryComponent implements OnInit, OnChanges {
  @Input() historyData!: ScheduleChangeRequestHistory;

  private currentUser!: OnsiteUser;
  private actorIds: string[] = [];

  constructor(@Inject('OnsiteAuthService') private onsiteAuthService: IOnsiteAuthService) { }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.onsiteAuthService.getUser$().pipe(take(1)).toPromise();

    this.setActorIds();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.anActionWasCreated(changes)) {
      this.setActorIds();
    }
  }

  public get actions(): ScheduleChangeRequestTicketAction[] {
    if (this.hasActions) {
      return this.historyData.actions.sort((a: ScheduleChangeRequestTicketAction, b: ScheduleChangeRequestTicketAction) => {
        const aCreateDate = DateTime.fromISO(a.createDate);
        const bCreateDate = DateTime.fromISO(b.createDate);

        return aCreateDate < bCreateDate ? 1 : -1;
      });
    } else {
      return [];
    }
  }

  public get hasRequestReason(): boolean {
    return this.historyData !== undefined && this.historyData.requestReason !== undefined && this.historyData.requestReason.length > 0;
  }

  public get hasActions(): boolean {
    return this.historyData !== undefined && this.historyData.actions.length > 0;
  }

  public getTeamMemberBadge(teamMember: ChangeRequestTeamMember | ActorTeamMember | undefined): TeamMemberBadge {
    return { firstName: teamMember?.firstName ?? '', lastName: teamMember?.lastName ?? '' };
  }

  public getBackground(teamMemberId: string | undefined): '1' | '2' | '3' | '4' | '5' | '6' {
    return this.getTeamMemberBgColorIndex(teamMemberId ? this.actorIds.indexOf(teamMemberId) : 6);
  }

  public formatActionCreateDate(createDate: string): string {
    return GeneralUtilities.formatDateMMDDYY(new Date(DateTime.fromISO(createDate).toLocaleString()));
  }

  public getActionMessage(action: ScheduleChangeRequestTicketAction): string {
    return `${action.actor.firstName} ${action.actor.lastName} ${action.actionType}` +
      `${ScheduleStatusRequest[action.requestStatusAtTimeOfAction as keyof typeof ScheduleStatusRequest] === ScheduleStatusRequest['910:DENIED']
        ? ''
        : ` at <strong>${this.getStatusName(action.requestStatusAtTimeOfAction)}</strong> status`
      }`;
  }

  public getRequestReasonMessage(): string {
    return this.historyData?.requester ? `${this.historyData.requester.firstName} ${this.historyData.requester.lastName} Submitted` : 'Requester Submitted';
  }

  private getTeamMemberBgColorIndex(index: number): '1' | '2' | '3' | '4' | '5' | '6' {
    index = index % 6;
    switch (index) {
      case 1:
        return '2';
      case 2:
        return '3';
      case 3:
        return '4';
      case 4:
        return '5';
      case 5:
        return '6';
      default:
        return '1';
    }
  }

  private setActorIds(): void {
    this.actorIds = [];

    this.actorIds.push(this.currentUser.commonId);

    if (this.historyData.requester?.commonId) {
      this.actorIds.push(this.historyData.requester.commonId);
    }

    this.actions.forEach((action) => {
      this.actorIds.push(action.actor.commonId);
    });

    this.actorIds = [...new Set(this.actorIds)];
  }

  private anActionWasCreated(changes: SimpleChanges): boolean {
    return !changes.historyData.firstChange &&
      changes.historyData.currentValue.actions.length >
      changes.historyData.previousValue.actions.length;
  }

  private getStatusName(requestStatusAtTimeOfAction: string): string {
    return ScheduleStatusRequest[requestStatusAtTimeOfAction as keyof typeof ScheduleStatusRequest];
  }
}
