<div style="margin-bottom: 1em;">
    <h1 class="padded-header">How would you like to change {{selectedTeamMember?.title}}'s schedule?</h1>
    <div class="radio-row">
        <!--  maybe move this into the styling for style-guide -->
        <mat-radio-button (change)="onChangeRadioButton($event)" value="update" style="margin-bottom: 1em; text-align: left;">
            <app-callout title="Update" [type]="updateCalloutType" icon="fa fa-edit" fontStyle="normal">
                Update a Team Member's Onsite Schedule
            </app-callout>
        </mat-radio-button>
        <mat-radio-button (change)="onChangeRadioButton($event)" value="end" style="margin-bottom: 1em; text-align: left;">
            <app-callout title="End" [type]="endCalloutType" icon="fa fa-ban" fontStyle="normal">
                    End a Team Member's Onsite Schedule
            </app-callout>
        </mat-radio-button>
    </div>
</div>
<div class="centered">
    <button routerLink="/schedule-request tertiary">Back</button>
    <button (click)="nextClick()" [disabled]="isNextButtonDisabled" class="primary">Next</button>
</div>
