export class OnsiteUser {
    private user: any;
    readonly hasLeaderImpersonationAccess: boolean;

    constructor(user: any, hasLeaderImpersonationAccess: boolean) 
    {
        this.user = user,
        this.hasLeaderImpersonationAccess = hasLeaderImpersonationAccess;
    }

    public get commonId(): string {
        return this.user['https://ql.custom.openid.com/common_id']?.toString() ?? '';
    }

    public get firstName(): string {
        return this.user.given_name ?? '';
    }

    public get lastName(): string {
        return this.user.family_name ?? '';
    }

    private get isLeader(): boolean {
        return this.user['https://ql.custom.openid.com/is_leader'] ?? false;
    }

    public get isEffectivelyLeader(): boolean {
        return this.isLeader || this.hasLeaderImpersonationAccess;
    }
    public toJSON(): any {
        return {
            commonId: this.commonId,
            isEffectivelyLeader: this.isEffectivelyLeader,
            firstName: this.firstName,
            lastName: this.lastName
        };
    }
}
