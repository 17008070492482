<div class="scheduler-modal">
  <div class="title-container">
    <div class="title-date h1">{{ this.date | date:'fullDate' }}</div>
    <div class="dialog-close" [mat-dialog-close]="true"><i class="fa-regular fa-xmark"></i></div>
  </div>

  <mat-dialog-content>
    <div class="team-member-list">
      <div>
        <div class="team-member-row row-0 current-user">
          <div class="badge-col">
            <app-tm-badge [bg]="'1'" [teamMember]="currentUser" [size]="'lg'"></app-tm-badge>
            <div class="modal-title mobile">
              <div class="title-name h2">{{currentUser.firstName}} {{currentUser.lastName}}</div>
            </div>
          </div>
          <div class="links-col">
            <div class="current-user__temporary">
              <div class="modal-title desktop">
                <div class="title-name h2">{{currentUser.firstName}} {{currentUser.lastName}}</div>
              </div>
              <app-callout title="Not Onsite" type="default">
                <p>You are currently not scheduled to be onsite this day.</p>
              </app-callout>
            </div>
            <div class="schedule-temp-booking-button">
              <button mat-button (click)="scheduleTemporaryOnsiteVisit()" class="button-row">
                <span class="type icon"><i class="fas fa-calendar-plus"></i></span>
                <span class="text">Schedule Temporary Onsite Visit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
