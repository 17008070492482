import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { combineLatest } from 'rxjs';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import { RoutingService } from 'src/app/services/routing/routing.service';
import { TeamMemberBadge } from '../../models/team-member-badge';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @ViewChild('mobileMenuTrigger', { static: true }) public menuTrigger!: MatMenuTrigger;

    teamMember!: TeamMemberBadge;
    public isScheduleAdminVisible = false;
    public isDashboardAdminVisible = false;
    public isViewingChangeComplexSchedulePage = false;
    public mobileMenuOpened = false;

    constructor(
        @Inject('OnsiteAuthService') private onsiteAuthService: IOnsiteAuthService,
        @Inject('RoutingService') private routingService: RoutingService) {
        this.teamMember = {
            firstName: '',
            lastName: '',
        };
    }

    @Input() opened: boolean | undefined;

    ngOnInit(): void {
        this.onsiteAuthService.getUser$().subscribe((changes: any) => {
            this.teamMember = {
                firstName: changes.firstName,
                lastName: changes.lastName
            };
        });

        this.routingService.isViewingChangeComplexSchedulePage$
            .subscribe(isViewing => this.isViewingChangeComplexSchedulePage = isViewing);

        this.onsiteAuthService.hasScheduleAdminAccess$().subscribe((isAdmin: boolean) => this.isScheduleAdminVisible = isAdmin);

        combineLatest([
            this.onsiteAuthService.hasScheduleAdminAccess$(),
            this.onsiteAuthService.hasParkingAdminAccess$(),
            this.onsiteAuthService.hasWorkspaceAdminAccess$()
        ]).subscribe(([scheduleAdminAccess, parkingAdminAccess, workspaceAdminAccess]) => {
            this.isDashboardAdminVisible = scheduleAdminAccess || parkingAdminAccess || workspaceAdminAccess;
        });

        this.addMobileMenuListeners();
    }

    private addMobileMenuListeners(): void {
        this.menuTrigger.menuOpened.subscribe(() => {
            this.handleMobileMenuOverlay();
            this.handleMobileScrollBlockers();
            this.mobileMenuOpened = true;
        });

        this.menuTrigger.menuClosed.subscribe(() => {
            this.handleMobileMenuOverlay();
            this.handleMobileScrollBlockers();
            this.mobileMenuOpened = false;
        });
    }

    private handleMobileMenuOverlay(): void {
        const overlayElements = document.getElementsByClassName('cdk-overlay-container');

        if (overlayElements && overlayElements.length) {
            const lastOverlayIndex = overlayElements.length - 1;

            !this.mobileMenuOpened ?
                overlayElements[lastOverlayIndex].classList.add('mobile-menu-overlay') :
                overlayElements[lastOverlayIndex].classList.remove('mobile-menu-overlay');
        }
    }

    private handleMobileScrollBlockers(): void {
        if (!this.mobileMenuOpened) {
            document.getElementById('site-container')?.classList.add('mobile-header-scroll-blocker');

            const sidenavContainerElements = document.getElementsByClassName('mat-sidenav-container');

            if (sidenavContainerElements && sidenavContainerElements.length) {
                sidenavContainerElements[0].classList.add('scroll-blocker');
            }
        }

        if (this.mobileMenuOpened) {
            document.getElementById('site-container')?.classList.remove('mobile-header-scroll-blocker');

            const sidenavContainerElements = document.getElementsByClassName('mat-sidenav-container');

            if (sidenavContainerElements && sidenavContainerElements.length) {
                sidenavContainerElements[0].classList.remove('scroll-blocker');
            }
        }
    }

    public get mobileMenuIcon(): string {
        return !this.mobileMenuOpened ? '<i class="fa-solid fa-bars"></i>' : '<i class="fa-regular fa-xmark"></i>';
    }
}
